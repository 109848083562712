<template>
  <!-- 3 -->
  <div class="content-item active" id="page3">
    <div class="wrap">
      <!-- 1 -->
      <div class="content-item__data-item">
        <p class="content-item__data-item__top">{{ translation.login_name }}</p>
        <div class="content-item__data-item__wrap">
          <!-- view -->
          <div class="content-item__data-item__bottom">
            <p class="content-item__data-item__bottom-text">{{name}}</p>
            <button @click="changeName = true" :class="{hidden: changeName}" class="content-item__data-item__bottom-btn transparent small" data-input="#change-name" data-focus="#input-name">{{ translation.account_change }}</button>
          </div>
          <!-- input -->
          <div class="content-item__data-item__change" id="change-name" :class="{active: changeName}">
            <input type="text" v-model="changedName" class="content-item__data-item__change-input" id="input-name">
            <button @click="change({name: changedName})" class="content-item__data-item__bottom-btn small save-btn" id="input-name__save">{{ translation.account_save }}</button>
          </div>
        </div>
      </div>

      <!-- 2 -->
      <div class="content-item__data-item">
        <p class="content-item__data-item__top">{{ translation.login_email }}</p>
        <div class="content-item__data-item__wrap">
          <!-- view -->
          <div class="content-item__data-item__bottom">
            <p class="content-item__data-item__bottom-text">{{email}}</p>
<!--            <button class="content-item__data-item__bottom-btn transparent small" data-input="#change-mail" data-focus="#input-mail">{{ translation.account_change }}</button>-->
          </div>
          <!-- input -->
          <div class="content-item__data-item__change" id="change-mail">
            <input type="text" value="" class="content-item__data-item__change-input" id="input-mail">
            <button class="content-item__data-item__bottom-btn small save-btn" id="input-mail__save">{{ translation.account_save }}</button>
          </div>
        </div>
      </div>

      <!-- 3 -->
      <div class="content-item__data-item">
        <p class="content-item__data-item__top">{{ translation.login_password }}</p>
        <div class="content-item__data-item__wrap">
          <!-- view -->
          <div class="content-item__data-item__bottom">
            <p class="content-item__data-item__bottom-text">*****</p>
            <button @click="changePass = true" :class="{hidden: changePass}" class="content-item__data-item__bottom-btn transparent small" data-input="#change-pass" data-focus="#input-pass">{{ translation.account_change }}</button>
          </div>
          <!-- input -->
          <div class="content-item__data-item__change" id="change-pass" :class="{active: changePass}">
            <div class="input-pass__wrap" style="max-width: 220px; margin-right: 10px">
              <input v-model="password"
                     :type="showPass ? 'text' : 'password'"
                     :placeholder="translation.login_password"
                     class="content-item__data-item__change-input" id="input-pass">
              <span class="input-pass__show active" @click="showPass=!showPass">{{showPass ? translation.login_hide :
                translation.login_show}}</span>
            </div>
            <button @click="change({password: password})" class="content-item__data-item__bottom-btn small save-btn" id="input-pass__save">{{ translation.account_save }}</button>
          </div>
        </div>
      </div>
    </div>
    <img src="" alt="">
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Account',
  data () {
    return {
      showPass: false,
      changedName: '',
      password: '',
      changeName: false,
      changePass: false
    }
  },
  mounted () {
    // if (!this.isAuthorised) {
    //   this.$router.go(-1)
    //   this.$store.commit('SHOW_LOGIN', true)
    // }
  },
  computed: {
    ...mapState({
      name: state => state.user_data.name,
      email: state => state.user_data.email,
      translation: state => state.translation,
      lang: state => state.lang,
      isAuthorised: state => state.isAuthorised
    })
  },
  methods: {
    change (params) {
      this.$store.dispatch('updateCustomer', params).then((response) => {
        console.log('success', response)
        this.changeName = false
        this.changePass = false
      }, (response) => {
        console.log('error', response)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/sass/cabinet.scss";
  @import "../../../assets/sass/review.scss";
</style>
