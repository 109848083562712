<template>
  <div class="popup-wrap active">
    <div class="popup popup-cards active">
        <div class="popup-top">
          <h2>Introduce tus datos</h2>
          <Isvg @click.native="close" name="close"/>
        </div>
        <div class="popup-body">
          <form action="">
            <div v-for="(value, key) in fields" :key="key">
              <input
                     @keypress="resetValidation(key)"
                     v-model="fields[key].val" @change="changeHandler($event, key)"
                     :class="{error: !fields[key].isValid}"
                     :type="fields_info[key].type"
                     :placeholder="fields_info[key].placeholder"
                     :inputmode="key === 'phone' ? 'number' : ''"
                     :id="`popup-cards__${key}`">
              <span v-if="!fields[key].isValid" class="error-text">{{fields_info[key].error}}</span>
            </div>
            <label class="input-checkbox">
              <input type="checkbox" v-model="termsAccepted">
              <span class="input-checkbox__checkbox" :class="{error: termsAccepted === false}"></span>
              <p>Confirmo que he leído y acepto las <router-link to="/terms">condiciones generales</router-link>, <router-link to="/privacy">política de privacidad</router-link> y <router-link to="/cookies" class="">política de cookies</router-link>.</p>
            </label>
            <label class="input-checkbox">
              <input type="checkbox" v-model="termsAcceptedSecond">
              <span class="input-checkbox__checkbox" :class="{error: termsAcceptedSecond === false}"></span>
              <p>Acepto recibir comunicaciones comerciales de Fininity Ltd</p>
            </label>
            <button @click="submitForm($event)">¡CONTINUAR!</button>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'
import Inputmask from 'inputmask'
import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'CardsForm',
  components: {
    Isvg
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      default_lead: state => state.default_lead,
      fields_info: state => state.fields_info,
      additionalParams: state => state.additionalParams,
      cardsLink: state => state.cardsLink,
      countryCode: state => state.countryCode,
      countryCodeName: state => state.countryCodeName,
      provider: state => state.provider,
      phoneMask: state => state.fields_info.phone.placeholder.replace(/_/g, '9')
    })
  },
  data () {
    return {
      fields: {
        name: { val: '', isValid: true },
        email: { val: '', isValid: true },
        phone: { val: '', isValid: true }
      },
      trySubmit: false,
      hardcoreData: {
        is_terms_accepted: 'on',
        loan_purpose: 'Other',
        loan_sum: 300,
        loan_period: 30,
        surname: 'DEBIT',
        surname_second: 'CARDS',
        birthday: '28-06-1992'
      },
      termsAccepted: '',
      termsAcceptedSecond: ''
    }
  },
  mounted () {
    this.initForm()
  },
  methods: {
    close () {
      this.$store.commit('SHOW_CARDS_FORM', false)
    },
    initForm () {
      const im = new Inputmask(this.phoneMask)
      Vue.nextTick(function () { im.mask(document.getElementById('popup-cards__phone')) })
    },
    resetValidation (field) {
      Vue.set(this.fields[field], 'isValid', true)
    },
    validator () {
      console.log(this.fields)
      let isSuccess = true
      for (const field in this.fields) {
        const reg = this.fields_info[field] ? this.fields_info[field].reg : 'true'
        const val = this.fields[field].val
        const isValid = (val || val === 0) ? new RegExp(reg).test(val) : false
        if (!isValid) isSuccess = false
        Vue.set(this.fields[field], 'isValid', isValid)
      }
      if (!this.termsAccepted) {
        this.termsAccepted = false
        isSuccess = false
      }
      if (!this.termsAcceptedSecond) {
        this.termsAcceptedSecond = false
        isSuccess = false
      }
      return isSuccess
    },
    check () {
      for (const i in this.fields) {
        if (!this.fields[i].isValid) this.validator()
      }
    },
    changeHandler (e, key) {
      console.log(e, key)
      Vue.set(this.fields[key], 'val', e.target.value)
    },
    submitForm (e) {
      e.preventDefault()
      this.trySubmit = true
      if (!this.validator()) { return }
      this.$store.commit('SHOW_LOADER', true)
      const temp = new FormData()
      for (const field in this.fields) {
        const val = this.fields[field].val
        if (field === 'phone') {
          temp.set(field, val.split(' ').join(''))
        } else {
          temp.set(field, val)
        }
      }
      for (const param in this.additionalParams) {
        temp.set(param, this.additionalParams[param])
      }
      for (const param in this.hardcoreData) {
        temp.set(param, this.hardcoreData[param])
      }
      let hardcoreProvider = this.provider
      if (this.countryCodeName === 'co') {
        hardcoreProvider = 72
      }
      temp.set('provider', hardcoreProvider)
      temp.set('country', this.countryCode)
      axios({
        method: 'POST',
        url: 'https://crezu.net:8002/form',
        data: temp,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        console.log('success', response)
        const leadId = response.data.data.lead_id
        localStorage.setItem('leadId', leadId)
        this.$store.commit('UPDATE_LEAD', leadId)
        this.close()
        this.$store.dispatch('pushGTMEvent', 'card_reg')
        this.$store.dispatch('productLinking', {
          event_type: 'product',
          external_uuid: leadId,
          product_type_id: 3
        })
        const newLink = this.cardsLink.replace(this.default_lead, leadId)
        console.log(this.cardsLink, newLink)
        window.location.href = newLink
      }, (error) => {
        console.log('error', error.response.data.errors)
        let validationErrors = []
        try { validationErrors = error.response.data.errors[0].validation } catch (e) { console.log(e) }
        for (const i in validationErrors) {
          Vue.set(this.fields[validationErrors[i]], 'isValid', false)
        }
        Vue.$toast.error(this.translation.error, { position: 'top' })
        this.$store.commit('SHOW_LOADER', false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .popup-cards {
    .popup-body {
      input {
        border: 1px solid #D0D0D0;
        margin-bottom: 20px;
      }
    }
  }
  .error {
    border-color: #ffbaba;
  }
  .popup-body form input:focus{
    box-shadow: none;
  }
  .error-text {
    color: #cd4847;
    text-align: left;
    width: 100%;
    display: block;
    margin-top: -20px;
    font-size: 12px;
  }
</style>
