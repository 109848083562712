<template>
  <div id="app">
    <transition name="fade">
      <Loader v-if="showLoader" id="loader"/>
    </transition>
    <transition name="fade">
      <Login v-if="showLogin"/>
    </transition>
    <transition name="fade">
      <Register v-if="showRegister"/>
    </transition>
    <transition name="fade">
      <CardsForm v-if="showCardsForm"/>
    </transition>
    <transition name="fade">
      <LocaleSelector v-if="showLocaleSelector"/>
    </transition>
    <transition name="fade">
      <MobileMenu/>
    </transition>
    <Header/>

    <section class="content" @click="hideMobileMenu" v-if="interfaceReady">
      <transition name="fade">
        <router-view/>
      </transition>
    </section>
    <Footer/>
    <CookieBar/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import sbjs from 'sourcebuster'
import Login from './components/components/Login'
import Register from './components/components/Register'
import MobileMenu from './components/components/MobileMenu'
import Footer from './components/components/Footer'
import Header from './components/components/Header'
import Loader from './components/components/Loader'
import CookieBar from './components/components/CookieBar'
import CardsForm from './components/components/CardsForm'
import LocaleSelector from './components/components/LocaleSelector'

export default {
  name: 'App',
  components: {
    CardsForm,
    Login,
    Register,
    MobileMenu,
    Footer,
    Header,
    Loader,
    CookieBar,
    LocaleSelector
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      showCardsForm: state => state.showCardsForm,
      showMobileMenu: state => state.showMobileMenu,
      translation: state => state.translation,
      showLoader: state => state.showLoader,
      lang: state => state.lang,
      interfaceReady: state => state.interfaceReady,
      provider: state => state.provider,
      countryCode: state => state.countryCode,
      showLocaleSelector: state => state.showLocaleSelector
    })
  },
  mounted () {
    const pre = document.getElementById('prerendered')
    if (pre) pre.remove()
    sbjs.init()
    const auth = localStorage.getItem('authorisation')
    const leadId = this.$store.getters.getParameterByName('sub1') || this.$store.getters.getParameterByName('lead_id') || localStorage.getItem('leadId')
    const reviewLender = localStorage.getItem('review_lender')
    if (auth) this.$store.commit('CHANGE_AUTH_STATE', JSON.parse(auth))
    if (leadId) this.$store.commit('UPDATE_LEAD', leadId)
    if (reviewLender) this.$store.commit('REVIEW_LENDER', JSON.parse(reviewLender))
    const localeFromURL = this.$store.getters.extractLocale()
    if (localeFromURL) {
      console.log('locale by URL:', localeFromURL)
      // this.$store.commit('CHANGE_LOCALE', localeFromURL)
      this.$store.dispatch('setLocale', localeFromURL)
      this.getConfigsInitFCM()
    } else {
      this.$store.dispatch('getLocale').then((localeByIP) => {
        console.log('locale by IP', localeByIP)
        // this.$store.commit('CHANGE_LOCALE', localeByIP)
        this.$store.dispatch('setLocale', localeByIP)
        this.getConfigsInitFCM()
      })
    }
  },
  methods: {
    hideMobileMenu () {
      if (!this.showMobileMenu) return
      this.$store.commit('SHOW_MOBILE_MENU', false)
    },
    getConfigsInitFCM () {
      const params = {
        utm_source: sbjs.get.current.src,
        utm_medium: sbjs.get.current.mdm,
        utm_campaign: sbjs.get.current.cmp,
        utm_content: sbjs.get.current.cnt,
        utm_term: sbjs.get.current.trm,
        gclid: this.$store.getters.getParameterByName('gclid') ? this.$store.getters.getParameterByName('gclid') : undefined,
        device: this.$store.getters.detectDevice(),
        fingerprint: 'default'
      }
      this.$store.commit('CHANGE_ADDITIONAL_PARAMS', params)
      setTimeout(x => {
        this.$store.commit('CHANGE_ADDITIONAL_PARAMS', { ga_client_id: this.$store.getters.getGaClientId() })
      }, 5000)
      this.$store.dispatch('getFingerprint')
      this.$store.dispatch('getOffers')
      this.$store.dispatch('initFCM')
      axios
        .get(`https://cdn.crezu.net/native/feed_lenders/${this.$store.state.feedConfig}.json`)
        .then(response => {
          const info = {}
          for (const key in response.data) {
            if (!response.data[key].offer_id) response.data[key].offer_id = this.$store.getters.getParameterByName('offer_id', response.data[key].link)
            info[response.data[key].offer_id] = response.data[key]
          }
          this.$store.commit('OFFERS_INFO', info)
          this.$store.dispatch('updateOffersInfo')
          console.log('offers ready')
        })
      axios
        .get(`https://cdn.crezu.net/native/configs/default_country_profiles/${this.$store.state.countryCode}.json`)
        .then(response => {
          const fields = {}
          for (const key in response.data.main_fields) {
            fields[response.data.main_fields[key].name] = response.data.main_fields[key]
          }
          this.$store.commit('FIELDS_INFO', fields)
        })
    }
  }
}
</script>

<style lang="scss">
@import "./assets/sass/main-styles.scss";
@import "./assets/sass/index.scss";
@import "./assets/sass/additional.scss";

.feed-ads {
  width: calc(100% - 20px) !important;
  min-height: 90px;
  margin: auto
}

@media (max-width: 500px) {
  .feed-ads {
    width: 100% !important;
    overflow: inherit !important;
  }
}

.feed-ads-ins {
  width: 100% !important;
  min-height: 90px;
  margin: auto
}

@media (max-width: 500px) {
  .feed-ads-ins {
    width: 100% !important;
  }
}
</style>
