import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import routes from './routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

function hasQueryParams (route) {
  if (!route.query) return false
  return !!Object.keys(route.query).length
}

function needOfMerge (from, to) {
  const fromKeys = Object.keys(from)
  const toKeys = Object.keys(to)
  // if keys in to
  for (const i in fromKeys) {
    if (toKeys.indexOf(fromKeys[i]) === -1) return true
  }
  // if all keys from 'from' exists in 'to' - we will check values
  for (const key in fromKeys) {
    if (from[key] !== to[key]) return true
  }
  // all params from 'from' exists in 'to', all values equals
  return false
}

function needAuth (to) {
  let locale = ''
  if (to.params) locale = to.params.locale || ''
  const protectedURLs = ['/cabinet', `/${locale}/cabinet`,
    '/cabinet/review', `/${locale}/cabinet/review`,
    '/cabinet/account', `/${locale}/cabinet/account`,
    '/cabinet/credit', `/${locale}/cabinet/credit`
  ]
  return protectedURLs.indexOf(to.path) > -1
}

function getMergedQuery (to, from) {
  let query = false
  if (!hasQueryParams(to) && hasQueryParams(from)) {
    query = from.query
  } else if (hasQueryParams(to) && hasQueryParams(from) && needOfMerge(from.query, to.query)) {
    query = { ...from.query, ...to.query }
  }
  return query
}

function customNext (to, from, next) {
  const mergedQuery = getMergedQuery(to, from)
  if (mergedQuery) {
    next({ path: to.path, query: mergedQuery })
  } else {
    next()
  }
}

function needToRedirectWithLocale (to, from) {
  const locale = store.state.countryCodeName
  const acceptedLocales = Object.keys(store.state.multiCountry)
  const toLocale = store.getters.extractLocale(to)
  if (toLocale.length === 2 && acceptedLocales.indexOf(toLocale) > -1) {
    // URL already with right locale
    return false
  } else {
    const mergedQuery = getMergedQuery(to, from)
    const nextLocation = { path: `/${locale}${to.path}`, params: { locale: locale } }
    if (mergedQuery) nextLocation.query = mergedQuery
    router.push(nextLocation)
    return true
  }
}

router.beforeEach((to, from, next) => {
  if (store.state.interfaceReady && needToRedirectWithLocale(to, from)) return
  if (!store.state.isAuthorised && needAuth(to)) {
    setTimeout(() => {
      if (!store.state.isAuthorised) {
        store.commit('SHOW_LOGIN', true)
        store.commit('CHANGE_TARGET_PAGE', to.fullPath)
        if (store.state.firstRedirect) router.push({ name: 'Main', query: from.query })
      } else {
        customNext(to, from, next)
      }
    }, 100)
  } else {
    customNext(to, from, next)
  }
  if (store.state.firstRedirect) {
    setTimeout(() => {
      store.commit('FIRST_REDIRECT_CHANGE_STATE', false)
    }, 110)
  }
})

export default router
