<template>
  <div>
    <!-- main-block -->
    <section id="main-block">
      <div class="container">
        <h1 class="tal">{{translation.debt_header}}</h1>
        <p class="subh1 tal" v-html="translation.debt_subheader"></p>
      </div>
    </section>
    <!-- offers-block ends -->
    <!-- offers-block -->
    <section id="offers-block" class="pb0">
      <div class="container">
        <!-- LINK ADS -->
        <div class="feed-ads" style="padding: 0px; overflow: hidden;">
          <!-- Opinomia - offers -->
          <ins class="adsbygoogle feed-ads-ins"
               style="display:block"
               data-ad-client="ca-pub-6373705936907274"
               data-ad-slot="4339222842"
               data-ad-format="link"
               data-full-width-responsive="true">
          </ins>
        </div>
        <!-- 1 -->
        <div class="offers-block__item" v-for="(value, key) in filtered_offers_list" :key="key">
          <!-- left -->
          <div class="offers-block__item-left">
            <img :src="offers_info[value.code] ? offers_info[value.code].img : ''" alt=""
                 class="offers-block__item-logo">
          </div>
          <!-- stats -->
          <div class="offers-block__item-stats">
            <div class="wrap">
              <!-- 1 -->
              <div class="offers-block__item-stats__item">
                <span>Cantidad de deuda</span>
                <span>{{value.min_total_debt.toLocaleString()}} - {{value.max_total_debt.toLocaleString()}} {{currency}}</span>
              </div>
              <!-- 1 -->
              <div class="offers-block__item-stats__item">
                <span>Deber dinero</span>
                <span>{{value.min_debt_count}} o más empresas</span>
              </div>
              <!-- 1 -->
              <div class="offers-block__item-stats__item" style="width: 100%;place-items: flex-start;">
                <span>Empresas de deuda</span>
                <span>{{value.debt_companies}}</span>
              </div>
            </div>
            <div class="offers-block__item-stats__wrap">
              <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
                  :href="offers_info[value.code] ? offers_info[value.code].link : ''"
                  target="_blank" class="button small">Visitar al sitio</a>
            </div>

          </div>
          <!-- right -->
          <div class="offers-block__item-right">
            <div class="offers-block__item-right__rating">
              <Isvg name="star"/>
              <p class="offers-block__item-right__rating-rate">{{value.average_rating ? value.average_rating.toFixed(1) : '5.0'}}</p>
              <p class="offers-block__item-right__rating-reviews" style="display: none">(
                <router-link @click.native="lenderSelected(value)" to="/offer-card"
                         class="offers-block__item-right__reviews-link"
                         style="text-decoration: none">{{value.comments}}</router-link>
                )</p>
            </div>
            <router-link @click.native="lenderSelected(value)" to="/review"
                         class="offers-block__item-right__reviews-link">{{translation.account_write_review}}</router-link>
          </div>
          <!-- AUTO ADS -->
          <div v-if="key === 3 || key === filtered_offers_list.length - 1"
               class="feed-ads"
               style="padding: 0px; overflow: hidden; order: 3; margin-top: 20px">
            <ins class="adsbygoogle feed-ads-ins"
                 style="display:block"
                 data-ad-client="ca-pub-6373705936907274"
                 data-ad-slot="6773814492"
                 data-ad-format="auto"
                 data-full-width-responsive="true">
            </ins>
          </div>
        </div>
      </div>
    </section>
    <!-- offers-block ends -->
    <div class="container">
      <h4 class="subh1 tal" style="text-align: center">Top 6 préstamos rápidos</h4>
      <TopOffers :list-length="6" :show-all="false" :link-to-offers="true"/>
    </div>
    <!-- questions-block -->
    <section id="questions-block">
      <div class="container">
        <!-- 1 -->
        <div v-for="(value, i) in translation.debt_questions" :key="i" class="questions-block__item">
          <div class="questions-block__item-header" :class="{active: questionsExpand[i]}" @click="expand(i)">
            <p>{{value[0]}}</p>
          </div>
          <div class="questions-block__item-body" :class="{active: questionsExpand[i]}">
            <p v-html="value[1]"></p>
          </div>
        </div>
      </div>
    </section>
    <!-- questions-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'
import axios from 'axios'
import Vue from 'vue'
import TopOffers from '../components/TopOffers'

export default {
  name: 'DebtRestructuring',
  components: {
    TopOffers,
    Isvg
  },
  data () {
    return {
      filtered_offers_list: [],
      questionsExpand: [false, false, false, false]
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      JWToken: state => state.JWToken,
      offers_info: state => state.offers_info,
      compare_list: state => state.compare_list,
      compare_codes: state => state.compare_codes,
      countryCodeName: state => state.countryCodeName,
      currency: state => state.currency,
      lead_id: state => state.user_data.lead_id || state.default_lead
    })
  },
  mounted () {
    this.showAds()
    this.getOffers()
  },
  methods: {
    getOffers () {
      this.$store.commit('SHOW_LOADER', true)
      axios({
        method: 'GET',
        url: `/api/offers?filter=product_type_id;eq;2&country_code=${this.countryCodeName}`,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        this.$store.commit('SHOW_LOADER', false)
        // this.filtered_offers_list = response.data.data
        const temp = []
        for (const i in response.data.data) {
          // if (response.data.data[i].code === '254') {
          temp.push(response.data.data[i])
          // }
        }
        this.filtered_offers_list = temp
      }, (response) => {
        console.log('error login', response)
        this.$store.commit('SHOW_LOADER', false)
        this.$router.push('/')
      })
    },
    expand (i) {
      Vue.set(this.questionsExpand, i, !this.questionsExpand[i])
    },
    lenderSelected (val) {
      this.$store.dispatch('reviewLender', val)
    },
    showAds () {
      const pushAds = () => {
        try {
          // eslint-disable-next-line no-undef
          (adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(x => {
        pushAds()
        pushAds()
        pushAds()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/offers.scss";
  #main-block {
    margin-top: 80px;
  }
</style>
