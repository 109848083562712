import Main from '@/components/pages/Main'
import Loans from '@/components/pages/Loans'
import Offers from '@/components/pages/Offers'
import Offers2 from '@/components/pages/Offers2'
import PrestamosComparacion from '@/components/pages/PrestamosComparacion'
import DebtRestructuring from '@/components/pages/DebtRestructuring'
import Cards from '@/components/pages/Cards'
import Cabinet from '@/components/pages/Cabinet'
import Credit from '@/components/pages/Credit'
import Review from '@/components/pages/Review'
import Account from '@/components/pages/cabinet/Account'
import CabinetReview from '@/components/pages/cabinet/Review'
import CabinetCredit from '@/components/pages/cabinet/Credit'
import OfferCard from '@/components/pages/OfferCard'
import Terms from '@/components/pages/Terms'
import Cookies from '@/components/pages/Cookies'
import Privacy from '@/components/pages/Privacy'
import Contacts from '@/components/pages/Contacts'
import Compare from '@/components/pages/Compare'
import Scam from '@/components/pages/Scam'
import Register from '@/components/pages/Register'
import Credy from '@/components/pages/Credy'
import Reg from '@/components/pages/Reg'
import Page404 from '@/components/pages/Page404'
import Landing from '@/components/pages/Landing'
import LandingLoans from '@/components/pages/landing/Loans'

const routes = [
  {
    path: '/:locale',
    name: 'Main',
    component: Main
  },
  {
    path: '/:locale/loans',
    name: 'Loans',
    component: Loans
  },
  {
    path: '/:locale/offers',
    name: 'Offers',
    component: Offers
  },
  {
    path: '/:locale/offers2',
    name: 'Offers2',
    component: Offers2
  },
  {
    path: '/:locale/prestamos-comparacion',
    name: 'PrestamosComparacion',
    component: PrestamosComparacion
  },
  {
    path: '/:locale/debt-restructuring',
    name: 'DebtRestructuring',
    component: DebtRestructuring
  },
  {
    path: '/:locale/cards',
    name: 'Cards',
    component: Cards
  },
  {
    path: '/:locale/cabinet/',
    name: 'Cabinet',
    component: Cabinet,
    children: [
      {
        name: 'Cabinet-Account',
        path: 'account',
        component: Account
      },
      {
        name: 'Cabinet-Credit',
        path: 'credit',
        component: CabinetCredit
      },
      {
        name: 'Cabinet-Review',
        path: 'review',
        component: CabinetReview
      }
    ]
  },
  {
    path: '/:locale/landing/',
    name: 'Landing',
    component: Landing,
    children: [
      {
        name: 'Landing-Loans',
        path: 'loans',
        component: LandingLoans
      },
      {
        name: 'Landing-Loans2',
        path: 'loans2',
        component: LandingLoans
      }
    ]
  },
  {
    path: '/:locale/credit',
    name: 'Credit',
    component: Credit
  },
  {
    path: '/:locale/review',
    name: 'Review',
    component: Review
  },
  {
    path: '/:locale/offer-card',
    name: 'OfferCard',
    component: OfferCard
  },
  {
    path: '/:locale/compare',
    name: 'Compare',
    component: Compare
  },
  {
    path: '/:locale/scam',
    name: 'Scam',
    component: Scam
  },
  {
    path: '/:locale/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/:locale/cookies',
    name: 'Cookies',
    component: Cookies
  },
  {
    path: '/:locale/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/:locale/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/:locale/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/:locale/reg',
    name: 'Reg',
    component: Reg
  },
  {
    path: '/:locale/opinion',
    name: 'Opinion',
    component: Credy,
    children: [
      {
        name: 'Credy',
        path: '/credy',
        component: Credy
      }
    ]
  },
  {
    path: '*',
    name: 'Error page',
    component: Page404
  }
]

export default routes
