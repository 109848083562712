<template>
  <div>
    <!-- main-block -->
    <section id="main-block" class="grey">
      <div class="container">
        <div class="main-block__left">
          <div>
            <h1 class="credit-h1">{{translation.credit_header}}</h1>
            <p class="main-block__p">{{translation.credit_subheader}}</p>
            <router-link to="/cabinet/credit" class="button ttu open-popup">{{translation.credit_button}}</router-link>
          </div>
        </div>
        <img src="../../assets/img/girl-2.png" alt="" class="main-block__img">
      </div>
    </section>
    <!-- main-block ends -->

    <!-- about-block -->
    <section id="about-block">
      <div class="container">
        <div class="wrap">
          <!-- 1 -->
          <div class="about-block__item">
            <h4>{{translation.credit_about_first}}</h4>
            <p>{{translation.credit_about_first_body}}</p>
          </div>
          <!-- 2 -->
          <div class="about-block__item">
            <h4>{{translation.credit_about_second}}</h4>
            <p>{{translation.credit_about_second_body}}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- about-block ends -->

    <!-- how-block -->
    <section id="how-block" class="grey">
      <div class="container">
        <h2>{{translation.credit_hiw_header}}</h2>
        <div class="wrap">
          <!-- 1 -->
          <div class="how-block__item">
            <img src="../../assets/img/i-how-01.svg" alt="">
            <p>{{translation.credit_hiw_1}}</p>
          </div>
          <!-- 2 -->
          <div class="how-block__item">
            <img src="../../assets/img/i-how-02.svg" alt="">
            <p>{{translation.credit_hiw_2}}</p>
          </div>
          <!-- 3 -->
          <div class="how-block__item">
            <img src="../../assets/img/i-how-03.svg" alt="">
            <p>{{translation.credit_hiw_3}}</p>
          </div>
          <!-- 4 -->
          <div class="how-block__item">
            <img src="../../assets/img/i-how-04.svg" alt="">
            <p>{{translation.credit_hiw_4}}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- how-block ends -->

    <!-- cta-block -->
    <section id="cta-block">
      <div class="container">
        <div class="cta-block__right">
          <h2>{{translation.credit_cta_header}}</h2>
          <p class="fszL">{{translation.credit_cta_body}}</p>
          <router-link to="/cabinet/credit" class="button ttu">{{translation.credit_cta_button}}</router-link>
        </div>
        <img src="../../assets/img/phone2_es.png" alt="" class="cta-block__img">
      </div>
    </section>
    <!-- cta-block ends -->

    <section class="separate"></section>

    <!-- security-block -->
    <section id="security-block">
      <div class="container">
        <div class="wrap">
          <!-- 1 -->
          <div class="security-block__item">
            <img src="../../assets/img/i-security-01.svg" alt="">
            <h4>{{translation.credit_security}}</h4>
            <p>{{translation.credit_security_body}}</p>
          </div>
          <!-- 2 -->
          <div class="security-block__item">
            <img src="../../assets/img/i-security-02.svg" alt="">
            <h4>{{translation.credit_privacy}}</h4>
            <p>{{translation.credit_privacy_body}}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- security-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Credit',
  // components: {
  //   Isvg
  // },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation
    })
  },
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/credit.scss";
</style>
