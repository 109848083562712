<template>
  <div style="padding-top: 70px">
    <section id="text-block">
      <div class="container" v-if="countryCodeName === 'ph'">
        <h1 class="breadcrumbs__title">Cookies policy</h1>
        <h3><strong>Cookies and Web Beacons</strong></h3>
        <p>This policy sets out our use of Cookies when you use our sites (as defined in our <strong>Privacy Policy</strong>). Please read it carefully as it explains how we and third parties may make use of your data collected by such Cookies. If you wish to understand how we make use of your data, more generally please read our Privacy Policy for our sites.</p>
        <p>We use a single first party cookie, named ‘PHPSESSID’. This cookie is vital for the operation of the website, as it allows us to track different page loads to yourself, which allows the form and application process to work.</p>
        <p>To find out more about accepting or refusing cookies please visit <a href="https://www.allaboutcookies.org/">www.allaboutcookies.org</a>.</p>
        <p>We also use Google Analytics to collect non-personally identifiable information, in aggregate form, to understand website/page visit volumes in terms of source, geography, browser, etc.</p>
        <p>Google Analytics employs a software technology called web beacons, that help us better manage content on our site by informing us what content is effective. Web beacons are tiny graphics with a unique identifier, similar in function to cookies, and are used to track the online movements of Web users or responses to emails. In contrast to cookies, which are stored on a user’s computer hard drive, web beacons are embedded invisibly on Web pages and are about the size of the period at the end of this sentence. Web beacons cannot be disabled in same manner as cookies may. We do not tie the information gathered by web beacons to our customers’ personally identifiable information.</p>
        <h3><strong>Cookies used by Google Analytics:</strong></h3>
        <p>Google Analytics is a simple, easy-to-use tool that helps website owners measure how users interact with website content. As a user navigates between web pages, Google Analytics provides website owners JavaScript tags (libraries) to record information about the page a user has seen, for example the URL of the page. The Google Analytics JavaScript libraries use <a href="https://en.wikipedia.org/wiki/HTTP_cookie">HTTP Cookies</a> to “remember” what a user has done on previous pages / interactions with the website.</p>
        <p><strong>Important</strong>: Read the <a href="https://www.google.com/analytics/learn/privacy.html">Google Analytics privacy</a> document for more details about the data collected by Google Analytics.</p>
        <p>Google Analytics supports three JavaScript libraries (tags) for measuring website usage: <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/">gtag.js</a>, <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/">analytics.js</a>, and <a href="https://developers.google.com/analytics/devguides/collection/gajs/">ga.js</a>. The following sections describe how each use cookies.</p>
        <h3><strong>Cookie settings</strong></h3>
        <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. Please note that by disallowing cookies some functionality on the site may be restricted.</p>
        <h3><strong>Contact Us</strong></h3>
        <p>If you have any questions or concerns about the use of your personal information as described above, please contact us at Fininity Ltd, Tartu road 84a – M302, 10112 Tallinn, Estonia. Registered by the Center Commercial Register of Estonia. Company number 14523902 | You can contact us at compliance@opinomia.com</p>
      </div>
      <div class="container" v-if="countryCodeName === 'vn'">
        <h1>Chính sách Cookie</h1>
        <p>
          Để
          cho phép bạn xem trang web và giúp chúng tôi giao tiếp tốt
          hơn với bạn, chúng tôi sử dụng cookie với các công
          nghệ theo dõi. Chúng tôi sử dụng cả cookie của mình và
          cookie của bên thứ ba.
        </p>
        <h3><strong>
          Dưới
          đây là một số lý do khác tại sao chúng tôi có thể sử
          dụng cookie:</strong></h3>
        <ol>
          <li>Theo dõi chức năng. Cookie cho phép chúng tôi theo dõi hiệu quả hoạt động nội dung và cách người dùng tương tác với nội dung đó. Chúng tôi sử dụng thông tin đó để giúp nội dung được điều hướng và truy cập dễ dàng hơn.</li>
          <li>Cá nhân hóa trang web. Cookie cho phép chúng tôi đánh giá sở thích của bạn và tùy chỉnh nội dung mà chúng tôi hiển thị cho bạn phù hợp.</li>
          <li>Phân tích xu hướng. Cookie cho phép chúng tôi xem trang web của mình hoạt động ra sao. Chúng tôi có thể phân tích những điều mìn đã làm tốt và chưa tốt. Điều này cho phép chúng tôi cung cấp nội dung tốt nhất và phù hợp nhất cho người dùng.</li>
          <li>Giao tiếp với khách hàng. Cookie (đặc biệt là cookie của bên thứ ba) cho phép chúng tôi nhanh chóng liên lạc với bạn khi cần thiết.</li>
        </ol>
        <h3><strong>
          Cookie
          tồn tại trong bao lâu:</strong></h3>
        <p>
          Thời
          lượng cookie tồn tại trên thiết bị của bạn phụ
          thuộc vào loại cookie. Có hai loại cookie.
        </p>
        <ol>
          <li>Cookie<strong>
            phiên
            sử dụng</strong>   chỉ tồn tại trên thiết bị của bạn cho đến khi bạn
            thoát khỏi trình duyệt.
          </li>
          <li>Cookie<strong>
            vĩnh
            viễn</strong>   vẫn còn trên thiết bị của bạn cho đến khi hết hạn
            thiết lập cookie hoặc cho đến khi bạn xóa chúng (bằng
            cách xóa lịch sử trình duyệt hoặc xóa cookie ở phần
            cài đặt trình duyệt của bạn).
          </li>
        </ol>
        <p>
          Cookie
          vĩnh viễn trên trang web của chúng tôi sẽ vẫn còn trên
          thiết bị của bạn nhiều nhất là sáu tháng kể từ lần
          truy nhập cuối của bạn.
        </p>
        <p>Cookie của bên thứ ba có thể lưu trên thiết bị của bạn trong tối đa 10 năm. Truy cập các liên kết dưới đây để tìm hiểu về tuổi thọ cookie của bên thứ ba:</p>
        <h3><strong>
          Từ
          bỏ quyền lợi:</strong></h3>
        <p>
          Mặc
          dù hầu hết các trình duyệt web chấp nhận cookie một
          cách tự động, chúng cũng cho phép bạn chặn hoặc xóa
          cookie bằng cách thay đổi tùy chọn theo ý bạn. Trong phần
          cài đặt trình duyệt, bạn cũng có thể chỉ cho phép một
          số cookie nhất định hoặc nhận thông báo bất cứ khi
          nào bạn nhận được một cookie mới. Bạn cũng có thể
          chọn hạn chế hoặc xóa các đối tượng khác tương tự
          như cookie. Vui lòng tham khảo trường Trợ giúp của trình
          duyệt để biết thêm thông tin. Chúng tôi liệt kê các
          liên kết hỗ trợ cookie cho các trình duyệt phổ biến
          dưới đây:
        </p>
        <ul>
          <li><a href="https://support.google.com/chrome/answer/95647?hl=en&amp;hlrm=en">
            Google
            Chrome</a></li>
          <li><a href="https://support.apple.com/kb/PH17191?viewlocale=en_EN&amp;locale=en_US">Safari</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Firefox</a></li>
          <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11">
            Microsoft
            Internet Explorer</a></li>
          <li><a href="https://support.google.com/nexus/answer/54068?visit_id=1-636585517588941681-639659396&amp;hl=en&amp;rd=1">
            Android
            Browser</a></li>
        </ul>
        <p>
          Khi
          sử dụng trang web của chúng tôi, chúng tôi khuyên bạn
          nên cho phép cookie hoạt động. Cookie cho phép bạn truy cập
          một số tính năng nhất định trên trang web của chúng
          tôi. Nếu bạn chọn sử dụng trang web của chúng tôi mà
          không có cookie, bạn có thể bị hạn chế ở một vài
          khía cạnh.
        </p>
        <h3><strong>
          Cookie
          được thu thập thông qua giao diện trang web:</strong></h3>
        <h3>
          Cookies
          thiết yếu
        </h3>
        <p>
          Cookie
          thiết yếu được chúng tôi sử dụng để cấp quyền
          truy cập vào trang web và các tính năng của trang web cho
          bạn. Nếu bạn từ chối các cookie này, trang web của
          chúng tôi có thể không hoạt động chính xác. Tuy nhiên,
          bạn có thể chặn hoặc xóa chúng bằng cách thay đổi
          cài đặt trình duyệt của bạn như mô tả ở trên.
        </p>
        <h3>
          Cookie
          phân tích
        </h3>
        <p>
          Cookie
          phân tích cho phép chúng tôi thu thập thông tin có giá trị.
          Và giúp chúng tôi biết trang web của mình đang được sử
          dụng ra sao, làm thế nào để cung cấp cho bạn trải
          nghiệm cá nhân hơn và kết quả cho các nỗ lực tiếp
          thị của chúng tôi.
        </p>
        <h3>
          Cookie
          quảng cáo
        </h3>
        <p>
          Cookie
          quảng cáo cho phép chúng tôi hiển thị các quảng cáo phù
          hợp hơn cho bạn. Chúng cũng đảm bảo rằng quảng cáo
          được hiển thị một cách thích hợp, không xuất hiện
          liên tục và được điều chỉnh theo sở thích của bạn.
        </p>
        <p>
          Khi
          các trang web của bên thứ ba phát triển các dịch vụ
          cookie độc lập với chúng tôi, chúng tôi không và không
          thể biết chính xác công nghệ đằng sau cookie của họ.
          Ngoài các cookie nói trên, chúng tôi thừa nhận rằng các
          bên thứ ba được liệt kê có thể sử dụng các cookie
          tương tự khác. Chúng tôi khuyến khích bạn truy cập các
          trang web dưới đây nếu bạn muốn tìm hiểu thêm về
          việc sử dụng cookie của họ.
        </p>
        <ol>
          <li><a href="https://policies.google.com/technologies/cookies">Google</a></li>
          <li><a href="https://www.facebook.com/about/privacy/">Facebook</a></li>
          <li><a href="https://about.ads.microsoft.com/en-us/resources/policies/remarketing-in-paid-search-policies">Bing</a></li>
          <li><a href="https://developers.google.com/analytics/devguides/collection/gajs/cookie-usage?hl=en">
            Google
            Analytics</a></li>
          <li><a href="https://yandex.com/support/metrica/general/cookie-usage.html">
            Yandex
            Metrica</a></li>
          <li><a href="http://sbjs.rocks/">
            Sourcebuster
            JS</a></li>
        </ol>
        <h3><strong>
          Chọn
          không tham gia Cookie của bên thứ ba</strong></h3>
        <p>
          Nếu
          bạn không muốn tham gia các cookie sau đây, vui lòng truy
          cập các liên kết bên dưới.
        </p>
        <p><a href="https://tools.google.com/dlpage/gaoptout?hl=en">
          Hủy
          kích hoạt cookie Google Analytics</a></p>
        <p><a href="https://www.facebook.com/help/568137493302217">
          Hủy
          kích hoạt cookie trên Facebook</a></p>
      </div>
      <div class="container" v-else>
        <h1>Política de cookies</h1>
        <p>Deseamos informarte que nuestra página web utiliza cookies para analizar la navegación de usuarios.</p>
        <h3>¿Qué son las cookies de tu navegador y para qué sirven?</h3>
        <p>Las cookies son unos archivos que se instalan en el equipo desde el que accedes a nuestra web con las finalidades que se describen en esta página.<br></p>
        <p>La aplicación que utilizamos para obtener y analizar la información de la navegación es Google Analytics y Yandex Metrica.</p>
        <p>Esta aplicación nos presta el servicio de análisis de la audiencia de nuestra página.<br>Estas herramientas no obtienen datos de los nombres o apellidos de los usuarios ni de la dirección postal desde donde se conectan. La información que obtiene está relacionada por ejemplo con el número de páginas visitas, el idioma, red social en la que se publican nuestras noticias, la ciudad a la que está asignada la dirección IP desde la que acceden los usuarios, el número de usuarios que nos visitan, la frecuencia y reincidencia de las visitas, el tiempo de visita, el navegador que usan, el operador o tipo de terminal desde el que se realiza la visita.</p>
        <h3>¿Para qué utilizamos cookies?</h3>
        <p>Esta información la utilizamos para mejorar nuestra página con la finalidad de prestar un mejor servicio a los usuarios que nos visitan. Utilizamos los cookies con los siguientes fines: autenticación, seguridad y prevención de fraude, determinación de preferencias y funciones, publicidad, datos analíticos.</p>
        <h3>Desactivación o eliminación de cookies</h3>
        <p>En cualquier momento podrá ejercer su derecho de desactivación o eliminación de cookies de este sitio web. Para permitir, conocer, bloquear o eliminar las cookies instaladas en tu equipo puedes hacerlo mediante la configuración de las opciones del navegador instalado en su ordenador.</p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Cookies',
  // components: {
  //   Isvg
  // },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      countryCodeName: state => state.countryCodeName,
      lang: state => state.lang
    })
  },
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
