<template>
  <div>
    <div class="content-item__offers-wrap">
      <!-- 1 -->
      <div class="content-item__offers-item" v-for="value in offers_list.slice(0, listLength)" :key="value.id">
        <img :src="offers_info[value.code] ? offers_info[value.code].img : ''" alt="">
        <p>{{translation.offers_amount_range}}</p>
        <p class="content-item__offers-item__amount">{{value.min_loan_sum.toLocaleString()}} - {{value.max_loan_sum.toLocaleString()}} {{currency}}</p>
        <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
           :href="offers_info[value.code] ? offers_info[value.code].link : ''"
           target="_blank"><button class="small">
          {{translation.offers_get_loan}}</button></a>
      </div>
    </div>
    <transition v-if="showAll" name="fade">
      <div v-if="!showHiddenOffers" style="text-align: center; margin: 30px">
        <button class="small" style="background: white; color: #0EBA71; border: 1px solid #0EBA71" @click="showHiddenOffers = true">{{translation.credit_score_show_more}}</button>
      </div>
    </transition>
    <!--hidden-->
    <transition name="fade">
      <div class="content-item__offers-wrap" v-if="showAll && showHiddenOffers">
        <div class="content-item__offers-item" v-for="value in offers_list.slice(6, offers_list.length)" :key="value.id">
          <img :src="offers_info[value.code] ? offers_info[value.code].img : ''" alt="">
          <p>{{translation.offers_amount_range}}</p>
          <p class="content-item__offers-item__amount">{{value.min_loan_sum.toLocaleString()}} - {{value.max_loan_sum.toLocaleString()}} {{currency}}</p>
          <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
             :href="offers_info[value.code] ? offers_info[value.code].link : ''"
             target="_blank"><button class="small">
            {{translation.offers_get_loan}}</button></a>
        </div>
      </div>
    </transition>
    <div v-if="linkToOffers" style="text-align: center; margin: 30px">
      <router-link to="/offers">
        <button class="small" style="background: white; color: #0EBA71; border: 1px solid #0EBA71">{{translation.credit_score_show_more}}</button>
      </router-link>
    </div>
 </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TopOffers',
  props: {
    listLength: {
      type: Number,
      required: false,
      default: 6
    },
    showAll: {
      type: Boolean,
      required: false,
      default: true
    },
    linkToOffers: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      score: null,
      showHiddenOffers: false
    }
  },
  computed: {
    ...mapState({
      JWToken: state => state.JWToken,
      translation: state => state.translation,
      name: state => state.user_data.name,
      offers_list: state => state.offers_list.filter(x => x.product_type_id === 1),
      offers_info: state => state.offers_info,
      isAuthorised: state => state.isAuthorised,
      currency: state => state.currency
    })
  }
}
</script>

<style lang="scss" scoped>
  .content-item__offers-wrap {
    margin-top: 30px;
    /*border-top: 1px solid #eee;*/
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .content-item__offers-item {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    img {
      max-width: 180px;
      //height: 50px;
      display: block;
      margin: 0 auto 15px;
      width: auto;
    }
    p {
      font-size: 13px;
      &.content-item__offers-item__amount {
        font-size: 18px;
        font-weight: 800;
        color: #000;
      }
    }
    button {
      margin-top: 15px;
      &.small {
        height: 42px;
        line-height: 42px;
        font-size: 15px;
      }
    }
  }
  @media (min-width: 420px) {
    .content-item__offers-wrap {
      flex-direction: row;
      justify-content: space-between;
    }
    .content-item__offers-item {
      width: 45%;
    }
  }
  @media (min-width: 720px) {
    .content-item__offers-wrap {
      margin-top: 30px;
      padding-top: 30px;
    }
    .content-item__offers-item {
      width: 30%;
      border-bottom: none;
    }
  }
</style>
