// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import Vuex from 'vuex'
import store from './store'
import cookie from 'vue-cookie'
import GoogleAuth from 'vue-google-auth'
// import facebookLogin from 'facebook-login'
import JsonViewer from 'vue-json-viewer'
import Autocomplete from 'v-autocomplete'
import 'v-autocomplete/dist/v-autocomplete.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueGtm from 'vue-gtm'
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-124581414-17',
  router
})

// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)

Vue.use(Vuex)
Vue.use(cookie)
// Vue.prototype.$FB = facebookLogin({ appId: '234625880968366' })
Vue.use(GoogleAuth, { client_id: '669048144672-bbb795vrle2mf882mh66umglnpdlfdkl.apps.googleusercontent.com' })
Vue.googleAuth().load()
Vue.use(Autocomplete)
Vue.use(VueToast)

Vue.use(VueGtm, {
  id: 'GTM-PG9VS2R',
  defer: true, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: window.location.hostname === 'localhost', // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
})

Vue.config.productionTip = true

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
