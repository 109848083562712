<template>
  <div>
    <!-- main-block -->
    <section id="main-block" class="grey">
      <div class="container">
        <div class="main-block__left">
          <h1><i>{{translation.header_1}}</i><br>{{translation.header_2}}</h1>
          <router-link to="/reg" @click.native="nextLocation('/offers2', 1)" class="button ttu open-popup">{{translation.main_cta}}</router-link>
        </div>
        <img src="../../assets/img/man-min.png" alt="" class="main-block__img">
      </div>
    </section>
    <!-- main-block ends -->

    <!-- stats-block -->
    <section id="stats-block">
      <div class="container">
        <h2>{{translation.stats_header}}</h2>
        <div class="wrap">
          <!-- 1 -->
          <div class="stats-block__item">
            <p>{{translation.stats_1[0]}}</p>
            <p>{{translation.stats_1[1]}}</p>
          </div>
          <!-- 2 -->
          <div class="stats-block__item">
            <p>{{translation.stats_2[0]}}</p>
            <p>{{translation.stats_2[1]}}</p>
          </div>
          <!-- 3 -->
          <div class="stats-block__item">
            <p>{{translation.stats_3[0]}}</p>
            <p>{{translation.stats_3[1]}}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- stats-block ends -->

    <!-- trust-block -->
    <section id="trust-block" class="grey">
      <div class="container">
        <h2>{{translation.trust_header}}</h2>
        <div class="wrap">
          <!-- 1 -->
          <div class="trust-block__item">
            <Isvg name="trust_1"/>
            <h4>{{translation.trust_1_header}}</h4>
            <p>{{translation.trust_1_body}}</p>
          </div>
          <!-- 2 -->
          <div class="trust-block__item">
            <Isvg name="trust_2"/>
            <h4>{{translation.trust_2_header}}</h4>
            <p>{{translation.trust_2_body}}</p>
          </div>
          <!-- 3 -->
          <div class="trust-block__item">
            <Isvg name="trust_3"/>
            <h4>{{translation.trust_3_header}}</h4>
            <p>{{translation.trust_3_body}}</p>
          </div>
        </div>
      </div>
    </section>
    <!-- trust-block ends -->

    <!-- cta-block -->
    <section id="cta-block">
      <div class="container">
        <div class="cta-block__right">
          <h2>{{translation.cta_header}}</h2>
          <p class="fszL">{{translation.cta_body}}</p>
          <router-link to="/credit" class="button ttu">{{translation.cta_button}}</router-link>
        </div>
        <div class="cta-block__note">
          <Isvg name="heart"/>
          <p>{{translation.cta_like}}</p>
        </div>
        <img src="../../assets/img/girl-with-phone-min.png" alt="" class="cta-block__img">
      </div>
    </section>
    <!-- cta-block ends -->

    <section class="separate"></section>

    <!-- about-block -->
    <section id="about-block">
      <div class="container">
        <h2>{{translation.about_header}}</h2>
        <div class="wrap">
          <!-- 1 -->
          <div class="about-block__item">
            <h4>{{translation.about_1_header}}</h4>
            <p>{{translation.about_1_body[0]}}</p>
            <p>{{translation.about_1_body[1]}}</p>
            <p>{{translation.about_1_body[2]}}</p>
          </div>
          <!-- 2 -->
          <div class="about-block__item">
            <h4>{{translation.about_2_header}}</h4>
            <p>{{translation.about_2_body}}</p>
            <router-link to="/review" class="button small">{{translation.about_button}}</router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- about-block ends -->
  </div>
</template>

<script>
// import Vue from 'vue'
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'
// import axios from 'axios'

export default {
  name: 'Loans',
  components: {
    Isvg
  },
  data () {
    return {
      isCollapse: false,
      loading: false,
      is_superadmin: this.$cookie.get('is_superadmin') / 1
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      toRegWithUTM: state => `/reg?utm_source=${state.additionalParams.utm_source}&utm_medium=${state.additionalParams.utm_medium}&utm_campaign=${state.additionalParams.utm_campaign}&utm_term=${state.additionalParams.utm_term}&utm_content=${state.additionalParams.utm_content}`
    })
  },
  mounted () {
  },
  methods: {
    toOffers () {
      this.$router.push('/offers')
    },
    nextLocation (to, product) {
      this.$store.dispatch('pushGTMEvent', 'productClick')
      this.$store.commit('UPDATE_PRODUCT_ID', product)
      this.$store.commit('UPDATE_FEED_URL_AFTER_REG', to)
      localStorage.setItem('hideLoanPurposeField', '1')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
