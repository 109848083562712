<template>
  <router-view/>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Landing',
  // components: {
  //   Isvg
  // },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      name: state => state.user_data.name,
      isAuthorised: state => state.isAuthorised,
      translation: state => state.translation
    })
  },
  mounted () {
    if (this.$router.currentRoute.name === 'Landing') this.$router.push('/landing/loans')
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
