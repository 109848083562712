<template>
  <div>
    <!-- main-block -->
    <section id="main-block" class="main-block-2">
      <div class="container">
        <h1 v-if="countryCodeName === 'es'">Compara y solicita tu préstamo personal</h1>
        <h1 v-if="countryCodeName === 'vn'">So sánh và yêu cầu khoản vay cá nhân của bạn</h1>
        <h1 v-if="countryCodeName === 'co' && !isLoans2">¿Para qué necesitas tu préstamo?</h1>
        <h1 v-if="countryCodeName === 'co' && isLoans2">¿Qué tipo de prestamo necesitas?</h1>
        <h1 v-if="countryCodeName === 'ph'">What is your loan purpose?</h1>
        <p v-if="countryCodeName === 'es'" class="main-block__subtitle">¿Cuál es la finalidad de tu préstamo?</p>
        <p v-if="countryCodeName === 'vn'" class="main-block__subtitle">Mục đích khoản vay của bạn là gì?</p>
        <div v-if="!isLoans2" class="wrap">

          <!-- 1 -->
          <div class="main-block__item_landing">
            <router-link to="/reg" @click.native="nextLocation('/offers2', 1)">
              <img src="@/assets/img/i-landing-1.svg" alt="">
              <p>{{translation.landing_loans_purpose[0]}}</p>
            </router-link>
          </div>
          <!-- 2 -->
          <div class="main-block__item_landing">
            <router-link to="/reg" @click.native="nextLocation('/offers2', 1)">
              <img src="@/assets/img/i-landing-2.svg" alt="">
              <p>{{translation.landing_loans_purpose[1]}}</p>
            </router-link>
          </div>
          <!-- 3 -->
          <div class="main-block__item_landing">
            <router-link to="/reg" @click.native="nextLocation('/offers2', 1)">
              <img src="@/assets/img/i-landing-3.svg" alt="">
              <p>{{translation.landing_loans_purpose[2]}}</p>
            </router-link>
          </div>
          <!-- 4 -->
          <div class="main-block__item_landing">
            <router-link to="/reg" @click.native="nextLocation('/offers2', 1)">
              <img src="@/assets/img/i-landing-4.svg" alt="">
              <p>{{translation.landing_loans_purpose[3]}}</p>
            </router-link>
          </div>
          <!-- 5 -->
          <div class="main-block__item_landing">
            <router-link to="/reg" @click.native="nextLocation('/offers2', 1)">
              <img src="@/assets/img/i-landing-5.svg" alt="">
              <p>{{translation.landing_loans_purpose[4]}}</p>
            </router-link>
          </div>
          <!-- 6 -->
          <div class="main-block__item_landing">
            <router-link to="/reg" @click.native="nextLocation('/offers2', 1)">
              <img src="@/assets/img/i-landing-6.svg" alt="">
              <p>{{translation.landing_loans_purpose[5]}}</p>
            </router-link>
          </div>

        </div>
        <div v-if="isLoans2" class="wrap">
          <!-- 1 -->
          <div class="main-block__item_landing three_in_line">
            <router-link to="/reg" class="big-button" @click.native="nextLocation('/offers2', 1)">
<!--              <img src="@/assets/img/i-landing-1.svg" alt="">-->
              <p>Préstamos a reportados</p>
            </router-link>
          </div>
          <!-- 2 -->
          <div class="main-block__item_landing three_in_line">
            <router-link to="/reg" class="big-button" @click.native="nextLocation('/offers2', 1)">
<!--              <img src="@/assets/img/i-landing-2.svg" alt="">-->
              <p>Préstamos personales</p>
            </router-link>
          </div>
          <!-- 3 -->
          <div class="main-block__item_landing three_in_line">
            <router-link to="/reg" class="big-button" @click.native="nextLocation('/offers2', 1)">
<!--              <img src="@/assets/img/i-landing-3.svg" alt="">-->
              <p>Linea de crédito</p>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- main-block ends -->
    <div v-if="countryCodeName === 'es'">
      <!-- logos-block -->
      <section id="logos-block">
        <div class="container">
          <h2>Comparamos más de 40 entidades:</h2>
          <div class="wrap">
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-1.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-2.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-3.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-4.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-5.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-6.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-7.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-8.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-9.png" alt=""></div>
            <div class="logos-block__item"><img src="@/assets/img/banks/bank-10.png" alt=""></div>
          </div>
        </div>
      </section>
      <!-- logos-block ends -->
    </div>
    <div v-else>
      <!-- stats-block -->
      <section id="stats-block">
        <div class="container">
          <h2>{{translation.stats_header}}</h2>
          <div class="wrap">
            <!-- 1 -->
            <div class="stats-block__item">
              <p>{{translation.stats_1[0]}}</p>
              <p>{{translation.stats_1[1]}}</p>
            </div>
            <!-- 2 -->
            <div class="stats-block__item">
              <p>{{translation.stats_2[0]}}</p>
              <p>{{translation.stats_2[1]}}</p>
            </div>
            <!-- 3 -->
            <div class="stats-block__item">
              <p>{{translation.stats_3[0]}}</p>
              <p>{{translation.stats_3[1]}}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- stats-block ends -->

      <!-- trust-block -->
      <section id="trust-block" class="grey">
        <div class="container">
          <h2>{{translation.trust_header}}</h2>
          <div class="wrap">
            <!-- 1 -->
            <div class="trust-block__item">
              <Isvg name="trust_1"/>
              <h4>{{translation.trust_1_header}}</h4>
              <p>{{translation.trust_1_body}}</p>
            </div>
            <!-- 2 -->
            <div class="trust-block__item">
              <Isvg name="trust_2"/>
              <h4>{{translation.trust_2_header}}</h4>
              <p>{{translation.trust_2_body}}</p>
            </div>
            <!-- 3 -->
            <div class="trust-block__item">
              <Isvg name="trust_3"/>
              <h4>{{translation.trust_3_header}}</h4>
              <p>{{translation.trust_3_body}}</p>
            </div>
          </div>
        </div>
      </section>
      <!-- trust-block ends -->

      <section class="separate"></section>

      <!-- about-block -->
      <section id="about-block">
        <div class="container">
          <h2>{{translation.about_header}}</h2>
          <div class="wrap">
            <!-- 1 -->
            <div class="about-block__item">
              <h4>{{translation.about_1_header}}</h4>
              <p>{{translation.about_1_body[0]}}</p>
              <p>{{translation.about_1_body[1]}}</p>
              <p>{{translation.about_1_body[2]}}</p>
            </div>
            <!-- 2 -->
            <div class="about-block__item" v-if="countryCodeName !== 'ph'">
              <h4>{{translation.about_2_header}}</h4>
              <p>{{translation.about_2_body}}</p>
              <router-link to="/review" class="button small">{{translation.about_button}}</router-link>
            </div>
          </div>
        </div>
      </section>
      <!-- about-block ends -->
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
import { mapState } from 'vuex'
import Isvg from '@/components/assetComponents/Isvg'
// import axios from 'axios'

export default {
  name: 'Loans',
  components: {
    Isvg
  },
  data () {
    return {
      isCollapse: false,
      loading: false,
      is_superadmin: this.$cookie.get('is_superadmin') / 1
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      countryCodeName: state => state.countryCodeName
    }),
    isLoans2: function () {
      return this.$route.path === '/co/landing/loans2'
    }
  },
  mounted () {
    console.log(this.$route)
  },
  methods: {
    toOffers () {
      this.$router.push('/offers')
    },
    nextLocation (to, product) {
      console.log('next location', to)
      this.$store.dispatch('pushGTMEvent', 'productClick')
      this.$store.commit('UPDATE_PRODUCT_ID', product)
      this.$store.commit('UPDATE_FEED_URL_AFTER_REG', to)
      localStorage.setItem('hideLoanPurposeField', '1')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/assets/sass/landing.scss";
@media (min-width: 560px) {
  .three_in_line {
    width: 33%;
  }
  .big-button {
    height: 84px;
    & p{
      margin:auto;
    }
  }
}
.big-button {
  padding: 20px !important;
}
</style>
