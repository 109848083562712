<template>
  <section id="feeds-block" class="mobile-2-in-row">
    <Loader v-if="showLoader" id="loader"/>
    <div class="container">
      <h1>{{nameFromStore}}{{translation.offers2_header}}</h1>
      <div v-for="(value, key) in filtered_offers_list"
           :key="key"
           :class="{
             'feeds-block__item-wrap': value.name,
             'col-5': key > 2 && value.name,
             'feed-ads': value.ads
           }"
      >
        <!-- offers block -->
        <div v-if="value.name" class="feeds-block__item">
          <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
             :href="value.link"
             target="_blank" class="feeds-block__item-link">
            <!-- img -->
            <div class="feeds-block__item-img">
              <img :src="value.img" alt="">
            </div>
            <!-- tag -->
            <div v-if="value.first_loan_free" class="feeds-block__item-tag">
              <p class="blue-tag">{{translation.offers_free}}</p>
            </div>
            <div class="feeds-block__item-content">
              <p class="feeds-block__item-content__title">{{translation.offers_amount_range}}</p>
              <p class="feeds-block__item-content__amount">{{value.amout_body}}</p>
            </div>
            <!-- button -->
            <div class="feeds-block__item-button">
              <button>
                <span>{{translation.offers_get_loan}}</span>
                <!-- <img src="img/coins.gif" alt=""> -->
              </button>
            </div>
          </a>
        </div>
        <!-- LINK ADS -->
        <!-- Opinomia - offers -->
        <ins v-if="value.ads === 1" class="adsbygoogle feed-ads-ins"
             style="display:block"
             data-ad-client="ca-pub-6373705936907274"
             data-ad-slot="4339222842"
             data-ad-format="link"
             data-full-width-responsive="true">
        </ins>
        <ins v-if="value.ads === 2" class="adsbygoogle feed-ads-ins"
             style="display:block"
             data-ad-client="ca-pub-6373705936907274"
             data-ad-slot="6773814492"
             data-ad-format="auto"
             data-full-width-responsive="true">
        </ins>
      </div>
      <!-- AUTO ADS -->
      <div
           class="feed-ads"
           style="padding: 0px; overflow: hidden; order: 3; margin-top: 20px">
        <ins class="adsbygoogle feed-ads-ins"
             style="display:block"
             data-ad-client="ca-pub-6373705936907274"
             data-ad-slot="6773814492"
             data-ad-format="auto"
             data-full-width-responsive="true">
        </ins>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '../components/Loader'
import axios from 'axios'

export default {
  name: 'Offers',
  components: {
    Loader
  },
  data () {
    return {
      filtered_offers_list: [],
      adsPos: [2, 12],
      showLoader: true
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      JWToken: state => state.JWToken,
      offers_info: state => state.offers_info,
      compare_list: state => state.compare_list,
      compare_codes: state => state.compare_codes,
      lead_id: state => state.user_data.lead_id || state.default_lead,
      nameFromStore: state => state.user_data.name || localStorage.getItem('firstName'),
      countryCodeName: state => state.countryCodeName
    })
  },
  mounted () {
    this.calculateAdsPos()
    const offersInfoWaiter = setInterval(() => {
      if (Object.keys(this.offers_info).length) {
        this.getOffers()
        clearInterval(offersInfoWaiter)
      }
    }, 100)
    setTimeout(() => {
      if (!Object.keys(this.offers_info).length) {
        // we wait 3 secs, but offers not loaded, so show only adsense
        this.getOffers()
        clearInterval(offersInfoWaiter)
      }
    }, 3000)
  },
  methods: {
    getOffers () {
      console.log('start loading')
      axios({
        method: 'GET',
        url: `https://sl.crezu.net/sl-feed?lead_id=${localStorage.getItem('leadId') || this.lead_id}`,
        headers: { 'Content-Type': 'application/json' }
      }).then((response) => {
        const temp = []
        console.log('end loading')
        for (const i in response.data) {
          for (const l in this.offers_info) {
            if (response.data[i] === this.offers_info[l].name.toLowerCase()) {
              const tempOffer = this.offers_info[l]
              tempOffer.link = tempOffer.link.split('&sub2')[0] + '&sub2=feed'
              temp.push(tempOffer)
            }
          }
        }
        if (this.countryCodeName === 'co' && temp.length) temp.push(temp[0])
        const tempWithAds = []
        for (const i in temp) {
          tempWithAds.push(temp[i])
          if (+i === this.adsPos[0]) tempWithAds.push({ ads: 1 })
          if (+i === this.adsPos[1]) tempWithAds.push({ ads: 2 })
        }
        if (temp.length < this.adsPos[1] + 1) tempWithAds.push({ ads: 2 })
        this.filtered_offers_list = tempWithAds
        this.showAds()
        this.showLoader = false
      }, (response) => {
        console.log('error login', response)
        this.showLoader = false
        this.$router.push('/')
      })
    },
    calculateAdsPos () {
      if (window.innerWidth < 980) {
        this.adsPos = [2, 8]
      }
      if (window.innerWidth < 820) {
        this.adsPos = [1, 9]
      }
    },
    showAds () {
      const pushAds = () => {
        console.log('push ads')
        try {
          // eslint-disable-next-line no-undef
          (adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(x => {
        pushAds()
        setTimeout(x => {
          pushAds()
          setTimeout(x => {
            pushAds()
          }, 100)
        }, 100)
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/offers2.scss";
  .container {
    margin-top: 60px;
  }
  @media screen and (min-width: 800px){
    .container {
      margin-top: 100px;
    }
  }
  #feeds-block {
    padding-top: 0px !important;
  }
</style>
