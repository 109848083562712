/**
 * Created by Stanislav Gulevich on 12.01.2020.
 */
import Vue from 'vue'
import Vuex from 'vuex'
import * as mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

// main Store
export default new Vuex.Store({
  state: {
    multiCountry: {
      es: {
        lang: 'ES',
        provider: 55,
        country: 1,
        default_lead: 'dac2734a-473f-4cff-944f-e90b1f2ffaf4',
        default_sub: 'opinomia_es',
        feedConfig: 'ES-new',
        countryName: 'España',
        currency: '€'
      },
      co: {
        lang: 'ES',
        provider: 68,
        country: 6,
        default_lead: '4ce47a3bff4543e4a326acf241b77601',
        default_sub: 'opinomia_co',
        feedConfig: 'CO-new',
        countryName: 'Colombia',
        currency: '$'
      },
      // mx: {
      //   lang: 'ES',
      //   provider: 69,
      //   country: 2,
      //   default_lead: '10c46b96149949849d5acfcb76a30a84',
      //   default_sub: 'opinomia_mx',
      //   feedConfig: 'MX-new',
      //   countryName: 'México',
      //   currency: '$'
      // },
      ph: {
        lang: 'EN',
        provider: 71,
        country: 3,
        default_lead: 'f97e2fde0ab444018db25fcbee12ecea',
        default_sub: 'opinomia_ph',
        feedConfig: 'PH-new',
        countryName: 'Philippines',
        currency: '$'
      },
      // ar: {
      //   lang: 'ES',
      //   provider: 70,
      //   country: 4,
      //   default_lead: 'cb59a7a16ec244e4addb832a09328aac',
      //   default_sub: 'opinomia_ar',
      //   feedConfig: 'AR-new',
      //   countryName: 'Argentina',
      //   currency: '$'
      // }
      vn: {
        lang: 'VN',
        provider: 49,
        country: 9,
        default_lead: '84a3073e-3e08-44f4-9ff3-41b955ad7569',
        default_sub: 'opinomia_vn',
        feedConfig: 'VN-new',
        countryName: 'Việt nam',
        currency: '₫'
      }
    },
    interfaceReady: false,
    default_lead: 'dac2734a-473f-4cff-944f-e90b1f2ffaf4',
    feedConfig: 'ES-new',
    countryCode: '1',
    countryCodeName: 'es',
    countryName: 'España',
    provider: 55,
    currency: '€',
    leadId: '',
    showMobileMenu: false,
    showLogin: false,
    showRegister: false,
    showCardsForm: false,
    showLocaleSelector: false,
    showLoader: true,
    lang: 'ES',
    translation: {},
    isAuthorised: false,
    JWToken: null,
    loginProvider: null,
    user_data: {
      asnef: null,
      city: null,
      education: null,
      email: null,
      gender: null,
      id_number: null,
      image: null,
      income_amount: null,
      income_source: null,
      lead_id: null,
      living_status: null,
      loan_purpose: null,
      marital_status: null,
      name: null
    },
    offers_list: [],
    offers_info: {},
    fields_info: {},
    compare_list: [],
    compare_codes: [],
    review_lender: {
      aggregator: null,
      asnef: null,
      average_rating: null,
      code: null,
      comments: null,
      description: null,
      difficulty: null,
      first_loan_free: null,
      guarantee: null,
      id: null,
      interest_rate: null,
      lender_id: null,
      max_age: null,
      max_interest_rate: null,
      max_loan_period: null,
      max_loan_sum: null,
      min_age: null,
      min_interest_rate: null,
      min_loan_period: null,
      min_loan_sum: null,
      name: null,
      satisfaction: null,
      speed: null,
      support: null
    },
    reviews_list: [],
    additionalParams: {
      domain: window.location.hostname,
      utm_source: '',
      utm_medium: '',
      utm_campaign: '',
      utm_content: '',
      utm_term: '',
      ga_client_id: '',
      gclid: '',
      fingerprint: 'default',
      device: ''
    },
    firstRedirect: true,
    targetPage: null,
    wasOnReg: false,
    cardsLink: '',
    productId: +localStorage.getItem('productId') || 1,
    feedURLAfterReg: '/offers'
  },
  getters: {
    detectDevice () {
      return () => {
        if (navigator.userAgent.match(/Android/i)) {
          return 'Android'
        } else if (navigator.userAgent.match(/BlackBerry/i)) {
          return 'BlackBerry'
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
          return 'Apple'
        } else if (navigator.userAgent.match(/IEMobile/i)) {
          return 'WindowsPhone'
        } else {
          return 'Desktop'
        }
      }
    },
    getAge (dateString) {
      return (dateString) => {
        dateString = dateString.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1')
        var today = new Date()
        var birthDate = new Date(dateString)
        var age = today.getFullYear() - birthDate.getFullYear()
        var m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--
        }
        console.log(`Customer age: ${age}`)
        return age
      }
    },
    getParameterByName (name, url) {
      return (name, url) => {
        if (!url) url = window.location.href
        // eslint-disable-next-line no-useless-escape
        name = name.replace(/[\[\]]/g, '\\$&')
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        const results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
      }
    },
    getGaClientId () {
      return () => {
        if (typeof (ga) !== 'undefined') {
          try {
            // eslint-disable-next-line no-undef
            return ga.getAll()[0].get('clientId')
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    extractLocale (to) {
      return (to) => {
        if (!to) to = { params: {}, path: window.location.pathname }
        let locale = to.params.locale
        if (!locale) {
          locale = 'not passed'
          const splittedPath = to.path.split('/')
          if (splittedPath.length > 1) locale = splittedPath[1]
        }
        return locale
      }
    }
  },
  // mutations.js
  mutations,
  // actions.js
  actions
})
