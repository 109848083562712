<template>
  <div :class="{fix: (filter !== '' && isMobile)}">
    <!-- compare-panel -->
    <router-link to="/compare">
      <transition name="fade">
        <section id="compare-panel" class="active" v-if="!(filter !== '' && isMobile) && compare_list.length">
          <transition name="fade">
            <div v-if="showPreview" class="compare-panel__wrap">
              <div class="compare-panel__preview clearfix">
                <!-- 1 -->
                <div v-for="(value, key) in compare_list" :key="key" class="compare-panel__preview-item">
                  <img :src="offers_info[value.code].img" alt="">
                </div>
              </div>
            </div>
          </transition>
          <a @mouseover="comparePreview(true)" @mouseleave="comparePreview(false)" class="compare-panel__a">
            <img src="../../assets/img/i-compare.svg" alt="">
            <span>{{translation.offers_compare}}</span>
            <button>{{compare_list.length}}</button>
          </a>
          <div class="compare-panel__clear" @click="clearCompare($event)">
            <span>{{translation.clear}}</span>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11.7px" height="11.7px" viewBox="0 0 11.7 11.7" style="enable-background:new 0 0 11.7 11.7;" xml:space="preserve">
              <g>
                <line class="st0" x1="0.4" y1="0.4" x2="11.4" y2="11.4"></line>
                <line class="st0" x1="11.4" y1="0.4" x2="0.4" y2="11.4"></line>
              </g>
            </svg>
          </div>
        </section>
      </transition>
    </router-link>
    <!-- compare-panel ends -->
    <!-- filters -->
    <section :class="{ active: filter}" id="filters-popup">
      <div class="filters-popup__header">
        <a @click="showFilter('')"><Isvg name="close-menu"/></a>
      </div>
      <div class="filters-popup__body">
        <!-- Amount -->
        <div :class="{ active: filter === 'period'}" id="filters-popup__period" class="filters-popup__body-item">
          <!-- 1 -->
          <div class="filters-popup__filters-item">
<!--            <p>Period</p>-->
            <opi-select style="background: white;text-align: left" v-model="filters.period.picker_value"
                      :options="translation.offers_period_dropdown"
                      :text-large="false"
                      :placeholder="translation.offers_period_dropdown_placeholder"></opi-select>
          </div>
        </div>
        <div :class="{ active: filter === 'loan_sum'}" id="filters-popup__amount" class="filters-popup__body-item">
<!--          <p>{{translation.offers_rating_descr}}</p>-->
          <div class="filters-popup__body-wrap">
            <input type="number"
                   :min="filters.loan_sum.min"
                   :max="filters.loan_sum.max"
                   :step="filters.loan_sum.step"
                   v-model="filters.loan_sum.picker_value"
                   id="filters-popup__amount-input">
            <span>{{currency}}</span>
          </div>
          <input type="range"
                 :min="filters.loan_sum.min"
                 :max="filters.loan_sum.max"
                 :step="filters.loan_sum.step"
                 v-model="filters.loan_sum.picker_value"
                 v-bind:style="{ background: 'linear-gradient(90deg, #5BDAA4 ' + bulletPos('loan_sum') + '%, #f0f0f0 ' +
                 bulletPos('loan_sum') + '%)'}"
                 id="filters-popup__amount-range">
        </div>
        <!-- More filters -->
        <div :class="{ active: filter === 'filters'}" id="filters-popup__filters" class="filters-popup__body-item">
          <div class="filters-popup__filters-item two-in-row">
            <p>{{translation.offers_filter_first_loan_free}}</p>
            <label class="switch">
              <input v-model="filters.first_loan_free.picker_value" type="checkbox" id="filters-popup__interest-checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <div class="filters-popup__filters-item two-in-row">
            <p>{{translation.offers_filter_guarantee}}</p>
            <label class="switch">
              <input v-model="filters.guarantee.picker_value" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <!-- 2 -->
          <div class="filters-popup__filters-item two-in-row">
            <p>{{translation.offers_filter_aggregator}}</p>
            <label class="switch">
              <input v-model="filters.aggregator.picker_value" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <!-- 3 -->
          <div class="filters-popup__filters-item two-in-row">
            <p>{{translation.offers_filter_asnef}}</p>
            <label class="switch">
              <input v-model="filters.asnef.picker_value" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>
          <!-- 4 -->
          <div class="filters-popup__filters-item">
            <p>{{translation.offers_filter_age}}</p>
            <input v-model="filters.age.picker_value" type="number" min="18" max="110" value="18" id="filters-popup__filters-item__input">
          </div>
          <!-- Rating -->
          <div class="filters-popup__filters-item">
            <p>{{translation.offers_filter_average_rating}}</p>
            <div class="filters-popup__filters-item__rate-wrap">
              <Isvg v-for="star in 5" :key="star"
                @click.native="filterPicked('average_rating', star)"
                :class="{active: star <= filters.average_rating.picker_value}"
                name="empty-star"/>
            </div>
          </div>
        </div>
        <!-- bottom -->
        <div class="filters-popup__body-bottom">
          <button @click="acceptFilter(filter)">{{translation.offers_show_results}}</button>
        </div>
      </div>
    </section>
    <!-- filters-ends -->
    <div @click="showFilter('')">
      <!-- main-block -->
      <section id="main-block">
        <div class="container">
          <h1 class="tal">Préstamos online en {{countryName}}</h1>
        </div>
      </section>
      <!-- offers-block ends -->
      <!-- filters-block -->
      <section id="filters-block">
        <div class="container">
          <div class="wrap">
            <div class="filter-block__item-wrap" v-if="!isMobile">
              <!-- Rating -->
              <div @click="showFilter('average_rating')" class="filter-block__item" id="open-popup__rating"
                   data-open="#filters-popup__rating"
                    data-open-desktop="#filters-popup-desktop__rating">{{translation.offers_filter_average_rating}}</div>
              <!-- popup -->
              <div @click="showFilter('average_rating')" :class="{ active: filter === 'average_rating'}" class="filter-block__item-popup"
                   id="filters-popup-desktop__rating">
                <div class="filter-block__item-popup__top">
                  <p>{{translation.offers_rating_descr}}</p>
                  <div v-for="key in 5"
                       :key="key"
                       @click="filterPicked('average_rating', 6 - key)"
                       :class="{active: filters.average_rating.picker_value === 6 - key}"
                       class="filters-block__rating-var">
                    <div class="filters-block__rating-var__stars" :id="'stars_s' + (6 - key)"></div>
                  </div>
                </div>
                <div class="filter-block__item-popup__bottom">
                  <button @click="acceptFilter(filter)">{{translation.offers_save}}</button>
                </div>
              </div>
            </div>
            <div class="filter-block__item-wrap">
              <!-- Amount -->
              <div @click="showFilter('loan_sum')" class="filter-block__item" id="open-popup__amount"
                   data-open="#filters-popup__amount"
                    data-open-desktop="#filters-popup-desktop__amount">{{translation.offers_filter_loan_sum}}</div>
              <!-- popup -->
              <div @click="showFilter('loan_sum')" :class="{ active: filter === 'loan_sum'}" class="filter-block__item-popup"
                   id="filters-popup-desktop__amount">
                <div class="filter-block__item-popup__top">
<!--                  <p>{{translation.offers_rating_descr}}</p>-->
                  <div class="filter-block__item-popup__body-wrap">
                    <input type="number"
                           :min="filters.loan_sum.min"
                           :max="filters.loan_sum.max"
                           :step="filters.loan_sum.step"
                           v-model="filters.loan_sum.picker_value"
                           id="filter-block__item-popup__amount-input">
                    <span>{{currency}}</span>
                  </div>
                  <input type="range"
                         :min="filters.loan_sum.min"
                         :max="filters.loan_sum.max"
                         :step="filters.loan_sum.step"
                         v-model="filters.loan_sum.picker_value"
                         v-bind:style="{ background: 'linear-gradient(90deg, #5BDAA4 ' + bulletPos('loan_sum') + '%, #f0f0f0 ' +
                         bulletPos('loan_sum') + '%)'}"
                         id="filter-block__item-popup__amount-range">
                </div>
                <div class="filter-block__item-popup__bottom">
                  <button @click="acceptFilter(filter)">{{translation.offers_save}}</button>
                </div>
              </div>
            </div>
            <div class="filter-block__item-wrap">
              <!-- Period -->
              <div @click="showFilter('period')" class="filter-block__item" id="open-popup__period"
                   data-open="#filters-popup__period"
                    data-open-desktop="#filters-popup-desktop__period">{{translation.offers_filter_period}}</div>
              <!-- popup -->
              <div @click="showFilter('period')" :class="{ active: filter === 'period'}" class="filter-block__item-popup"
                   id="filters-popup-desktop__period">
                <div class="filter-block__item-popup__top">
<!--                  <p>{{translation.offers_rating_descr}}</p>-->
                  <opi-select style="background: white;text-align: left" v-model="filters.period.picker_value"
                      :options="translation.offers_period_dropdown"
                      :text-large="false"
                      :placeholder="translation.offers_period_dropdown_placeholder"></opi-select>
                </div>
                <div class="filter-block__item-popup__bottom">
                  <button @click="acceptFilter(filter)">{{translation.offers_save}}</button>
                </div>
              </div>
            </div>
            <div class="filter-block__item-wrap" v-if="!isMobile">
              <!-- Interest -->
              <div @click="showFilter('first_loan_free')" class="filter-block__item" id="open-popup__interest"
                   data-open="#filters-popup__interest"
                    data-open-desktop="#filters-popup-desktop__interest">{{translation.offers_filter_first_loan_free}}</div>
              <!-- popup -->
              <div @click="showFilter('first_loan_free')" :class="{ active: filter === 'first_loan_free'}" class="filter-block__item-popup"
                   id="filters-popup-desktop__interest">
                <div class="filter-block__item-popup__top">
<!--                  <p>{{translation.offers_rating_descr}}</p>-->
                  <label class="switch small">
                    <input type="checkbox" v-model="filters.first_loan_free.picker_value">
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="filter-block__item-popup__bottom">
                  <button @click="acceptFilter(filter)">{{translation.offers_save}}</button>
                </div>
              </div>
            </div>
            <div class="filter-block__item-wrap">
              <!-- Filters -->
              <div @click="showFilter('filters')" class="filter-block__item" id="open-popup__filters"
                   data-open="#filters-popup__filters"
                    data-open-desktop="#filters-popup-desktop__filters">{{translation.offers_filters}}</div>
              <!-- popup -->
              <div @click="showFilter('filters')" :class="{ active: filter === 'filters'}" class="filter-block__item-popup"
                   id="filters-popup-desktop__filters">
                <div class="filter-block__item-popup__top">
                  <!-- 1 -->
                  <div class="filter-block__item-popup__filters-item">
                    <p>{{translation.offers_filter_guarantee}}</p>
                    <label class="switch small">
                      <input v-model="filters.guarantee.picker_value" type="checkbox">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- 2 -->
                  <div class="filter-block__item-popup__filters-item">
                    <p>{{translation.offers_filter_aggregator}}</p>
                    <label class="switch small">
                      <input v-model="filters.aggregator.picker_value" type="checkbox">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- 3 -->
                  <div class="filter-block__item-popup__filters-item">
                    <p>{{translation.offers_filter_asnef}}</p>
                    <label class="switch small">
                      <input v-model="filters.asnef.picker_value" type="checkbox">
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <!-- 4 -->
                  <div class="filter-block__item-popup__filters-item">
                    <p>{{translation.offers_filter_age}}</p>
                    <input v-model="filters.age.picker_value" type="number" min="18" max="110" value="18"
                           id="filters-popup__filters-item__input">
                  </div>
                </div>
                <div class="filter-block__item-popup__bottom">
                  <button @click="acceptFilter(filter)">{{translation.offers_save}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- filters-block ends -->
      <section id="filters-active-block">
        <div class="container">
          <!-- 1 -->
          <div class="filters-active-block__item" v-for="(value, key) in activeFilters" :key="key">
            <span>{{translation['offers_filter_' + key]}}:
              <span v-if="value.conf_value === true">{{translation.yes}}</span>
              <span v-else-if="value.conf_value === false">{{translation.no}}</span>
              <span v-else-if="key === 'period'">{{selectedPeriod}}</span>
              <span v-else>{{value.conf_value}}</span>
            </span>
            <div @click="clearFilter(key)" class="filters-active-block__item-close">
              <img src="../../assets/img/filter-active-cross.svg" alt="">
            </div>
          </div>
        </div>
      </section>
      <!-- offers-block -->
      <section id="offers-block">
        <div class="container">
          <!-- LINK ADS -->
          <div class="feed-ads" style="padding: 0px; overflow: hidden;">
            <!-- Opinomia - offers -->
            <ins class="adsbygoogle feed-ads-ins"
                 style="display:block"
                 data-ad-client="ca-pub-6373705936907274"
                 data-ad-slot="4339222842"
                 data-ad-format="link"
                 data-full-width-responsive="true">
            </ins>
          </div>
          <!-- 1 -->
          <div class="offers-block__item" v-for="(value, key) in filtered_offers_list" :key="key">
            <!-- left -->
            <div class="offers-block__item-left">
              <img :src="offers_info[value.code] ? offers_info[value.code].img : ''" alt=""
                   class="offers-block__item-logo">
<!--              <p>{{value.name}}</p>-->
              <div v-if="value.first_loan_free"
                   class="offers-block__item-left__tag"><p>{{translation.offers_free}}</p></div>
              <div v-if="value.aggregator"
                   class="offers-block__item-left__tag"><p>{{translation.offers_aggregator}}</p></div>
<!--              <div v-if="value.aggregator" class="offers-block__item-left__aggregator">-->
<!--                <p>{{translation.offers_aggregator}}</p>-->
<!--                <div class="offers-block__item-left__aggregator-note">?</div>-->
<!--              </div>-->
            </div>
            <!-- stats -->
            <div class="offers-block__item-stats">
              <div class="wrap">
                <!-- 1 -->
                <div class="offers-block__item-stats__item">
                  <span>{{translation.offers_amount_range}}</span>
                  <span>{{value.min_loan_sum.toLocaleString()}} - {{value.max_loan_sum.toLocaleString()}} {{currency}}</span>
                </div>
                <!-- 1 -->
                <div class="offers-block__item-stats__item">
                  <span>{{translation.offers_timing_range}}</span>
                  <span>{{beautifyDays(value.min_loan_period)}} - {{beautifyDays(value.max_loan_period)}}</span>
                </div>
                <!-- 1 -->
                <div class="offers-block__item-stats__item">
                  <span>{{translation.offers_min_interest}}</span>
                  <span v-if="value.min_interest_rate === value.max_interest_rate">{{value.min_interest_rate}} {{translation.offers_percent}}</span>
                  <span v-else>{{value.min_interest_rate}} - {{value.max_interest_rate}} {{translation.offers_percent}}</span>
                </div>
                <!-- 1 -->
                <div class="offers-block__item-stats__item">
                  <span>{{translation.offers_age_range}}</span>
                  <span v-if="value.max_age !== 0">{{value.min_age}} - {{value.max_age}}
                    {{translation.offers_age}}</span>
                  <span v-else>-</span>
                </div>
                <!-- 1 -->
                <div class="offers-block__item-stats__item">
                  <span>{{translation.offers_guarantee}}</span>
                  <span>{{value.guarantee ? translation.yes : translation.no}}</span>
                </div>
                <!-- 1 -->
                <div class="offers-block__item-stats__item">
                  <span>{{translation.asnef}}</span>
                  <span>{{value.asnef ? translation.yes : translation.no}}</span>
                </div>
              </div>
              <div class="offers-block__item-stats__wrap">
                <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
                  :href="offers_info[value.code] ? offers_info[value.code].link : ''"
                  target="_blank"
                  class="button small">{{translation.offers_get_loan}}</a>
                <div class="offers-block__item-stats__add">
                  <router-link @click.native="lenderSelected(value)" to="/offer-card"
                               class="offers-block__item-stats__details">{{translation.offers_show}}</router-link>
                  <div class="offers-block__item-stats__compare" @click="addForCompare(value)"
                       :class="{active: compare_codes.indexOf(value.code) > -1}">
                    <img src="../../assets/img/i-compare.svg" alt="" class="offers-block__item-stats__compare-i">
                    <span>{{translation.offers_compare}}</span>
                    <img src="../../assets/img/i-compare-check.svg" alt="" class="offers-block__item-stats__compare-check">
                  </div>
                </div>
              </div>
            </div>
            <!-- right -->
            <div class="offers-block__item-right">
              <div class="offers-block__item-right__rating">
                <Isvg name="star"/>
                <p class="offers-block__item-right__rating-rate">{{value.average_rating ? value.average_rating.toFixed(1) : '5.0'}}</p>
                <p class="offers-block__item-right__rating-reviews">(
                  <router-link @click.native="lenderSelected(value)" to="/offer-card"
                           class="offers-block__item-right__reviews-link" style="text-decoration: none">{{value.comments}}</router-link>
                  )</p>
              </div>
              <router-link @click.native="lenderSelected(value)" to="/review"
                           class="offers-block__item-right__reviews-link">{{translation.account_write_review}}</router-link>
            </div>
            <!-- AUTO ADS -->
            <div v-if="key === 3 || key === filtered_offers_list.length - 1"
                 class="feed-ads"
                 style="padding: 0px; overflow: hidden; order: 3; margin-top: 20px">
              <ins class="adsbygoogle feed-ads-ins"
                   style="display:block"
                   data-ad-client="ca-pub-6373705936907274"
                   data-ad-slot="6773814492"
                   data-ad-format="auto"
                   data-full-width-responsive="true">
              </ins>
            </div>
          </div>
        </div>
      </section>
      <!-- offers-block ends -->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'
import OpiSelect from '../assetComponents/OpiSelect'
import axios from 'axios'
import Vue from 'vue'

export default {
  name: 'Offers',
  components: {
    Isvg,
    OpiSelect
  },
  data () {
    return {
      filter: '',
      locker: false,
      filters: {
        average_rating: {
          picker_value: 0,
          conf_value: 0,
          reset_value: 0,
          condition: 'ge'
        },
        loan_sum: {
          picker_value: 0,
          conf_value: 0,
          reset_value: 0,
          min: 0,
          max: 5000,
          step: 100,
          condition: 'ge'
        },
        period: {
          picker_value: 10,
          conf_value: 0,
          reset_value: 0,
          condition: 'ge'
        },
        first_loan_free: {
          picker_value: false,
          conf_value: null,
          reset_value: null,
          condition: 'eq'
        },
        guarantee: {
          picker_value: false,
          conf_value: null,
          reset_value: null,
          condition: 'eq'
        },
        aggregator: {
          picker_value: false,
          conf_value: null,
          reset_value: null,
          condition: 'eq'
        },
        asnef: {
          picker_value: false,
          conf_value: null,
          reset_value: null,
          condition: 'eq'
        },
        age: {
          picker_value: 0,
          conf_value: 0,
          reset_value: 0,
          condition: 'ge'
        }
      },
      loan_sum: 0,
      isMobile: window.innerWidth < 720,
      showPreview: false,
      filtered_offers_list: []
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      JWToken: state => state.JWToken,
      offers_info: state => state.offers_info,
      compare_list: state => state.compare_list,
      compare_codes: state => state.compare_codes,
      countryCodeName: state => state.countryCodeName,
      countryName: state => state.countryName,
      currency: state => state.currency,
      lead_id: state => state.user_data.lead_id || state.default_lead
    }),
    activeFilters () {
      const filtered = {}
      for (const key in this.filters) {
        if (this.filters[key].conf_value !== this.filters[key].reset_value && this.filters[key].conf_value) {
          filtered[key] = this.filters[key]
        }
      }
      return filtered
    },
    selectedPeriod () {
      let label = ''
      for (const key in this.translation.offers_period_dropdown) {
        if (this.translation.offers_period_dropdown[key] === this.filters.period.conf_value) label = key
      }
      return label
    }
  },
  created () {
    window.addEventListener('resize', this.windowResized)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowResized)
  },
  mounted () {
    this.showAds()
    this.getOffers()
  },
  methods: {
    getOffers () {
      this.$store.commit('SHOW_LOADER', true)
      let filter = ''
      for (const key in this.filters) {
        if (this.filters[key].conf_value) {
          if (['loan_sum', 'period', 'age'].indexOf(key) > -1) {
            const label = (key !== 'period') ? key : 'loan_' + key
            filter +=
            `&filter=min_${label};le;${this.filters[key].conf_value}&filter=max_${label};ge;${this.filters[key].conf_value}`
          } else {
            filter +=
            `&filter=${key};${this.filters[key].condition};${this.filters[key].conf_value}`
          }
        }
      }
      axios({
        method: 'GET',
        url: `/api/offers?filter=product_type_id;eq;1${filter}&country_code=${this.countryCodeName}`,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        this.$store.commit('SHOW_LOADER', false)
        let lenders = response.data.data
        // offers page opened after /reg form - hide our sites
        if (this.$store.getters.getParameterByName('sub2') === 'feed') lenders = lenders.filter(x => ['crezu', 'pezetita'].indexOf(x.name) === -1)
        this.filtered_offers_list = lenders
      }, (response) => {
        console.log('error login', response)
        this.$store.commit('SHOW_LOADER', false)
        this.$router.push('/')
      })
    },
    bulletPos (filter) {
      const res = (this.filters[filter].picker_value - this.filters[filter].min) /
      (this.filters[filter].max - this.filters[filter].min) * 100
      return res
    },
    showFilter (filter) {
      if (!this.locker) this.filter = filter
      this.locker = true
      setTimeout(x => { this.locker = false }, 100)
    },
    filterPicked (name, value) {
      Vue.set(this.filters[name], 'picker_value', value)
    },
    acceptFilter (name) {
      if (name === 'filters') {
        const fl = this.isMobile ? ['average_rating', 'first_loan_free', 'guarantee', 'aggregator', 'asnef', 'age'] : ['guarantee', 'aggregator', 'asnef', 'age']
        for (const i in fl) {
          Vue.set(this.filters[fl[i]], 'conf_value', this.filters[fl[i]].picker_value)
        }
      } else {
        Vue.set(this.filters[name], 'conf_value', this.filters[name].picker_value)
      }
      this.showFilter('')
      this.getOffers()
      this.$store.dispatch('pushGTMEvent', 'filterActive')
    },
    clearFilter (name) {
      if (name === 'filters') {
        const fl = ['guarantee', 'aggregator', 'asnef', 'age']
        for (const i in fl) {
          Vue.set(this.filters[fl[i]], 'picker_value', this.filters[fl[i]].reset_value)
          Vue.set(this.filters[fl[i]], 'conf_value', this.filters[fl[i]].reset_value)
        }
      } else {
        Vue.set(this.filters[name], 'picker_value', this.filters[name].reset_value)
        Vue.set(this.filters[name], 'conf_value', this.filters[name].reset_value)
      }
      this.getOffers()
    },
    addForCompare (lender) {
      const index = this.compare_list.indexOf(lender)
      const temp = this.compare_list
      if (index > -1) {
        temp.splice(index, 1)
      } else {
        temp.push(lender)
      }
      this.$store.commit('COMPARE_LIST', temp)
    },
    windowResized () {
      this.isMobile = window.innerWidth < 720
    },
    comparePreview (val) {
      this.showPreview = val
    },
    lenderSelected (val) {
      this.$store.dispatch('reviewLender', val)
    },
    clearCompare (e) {
      e.preventDefault()
      this.$store.commit('COMPARE_LIST', [])
    },
    beautifyDays (days) {
      const months = Math.floor(days / 30)
      const years = Math.floor(months / 12)

      let result = days + ' ' + this.translation.offers_days
      if (months > 0) result = months + ' ' + this.translation.offers_months
      if (years > 0) result = years + ' ' + this.translation.offers_years
      return result
    },
    showAds () {
      const pushAds = () => {
        try {
          // eslint-disable-next-line no-undef
          (adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(x => {
        pushAds()
        pushAds()
        pushAds()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/offers.scss";
  #main-block {
    margin-top: 80px;
  }
  .fix {
    overflow: hidden;
  }
  #filters-popup__period-range {
    &:before {
      content: '10';
    }
    &:after {
      content: '180';
    }
  }
  .compare-panel__clear {
    margin-left: 10px;
  }
  .compare-panel__clear span {
    margin-right: 3px;
  }
</style>
