<template>
  <div style="min-height: calc(100vh - 75px)">
    <!-- main-block -->
    <section id="main-block" class="first-section">
      <div class="container">
        <h1>{{translation.account_header + name}}</h1>
      </div>
    </section>
    <!-- main-block ends -->
    <!-- menu-block -->
    <section id="menu-block">
      <div class="container">
        <ul class="menu-block__ul">
          <router-link class="menu-block__item" to="credit"
                       active-class="active"><li><span>{{translation.account_credit_score}}</span></li></router-link>
          <router-link class="menu-block__item" to="review"
                       active-class="active"><li><span>{{translation.account_write_review}}</span></li></router-link>
          <router-link class="menu-block__item" to="account"
                       active-class="active"><li><span>{{translation.account}}</span></li></router-link>
        </ul>
      </div>
    </section>
    <!-- menu-block ends -->
    <!-- content-block -->
    <section id="content-block">
      <div class="container">
        <router-view/>
      </div>
    </section>
    <!-- content-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Cabinet',
  // components: {
  //   Isvg
  // },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      name: state => state.user_data.name,
      isAuthorised: state => state.isAuthorised,
      translation: state => state.translation
    })
  },
  mounted () {
    // if (!this.isAuthorised) {
    //   this.$router.go(-1)
    //   this.$store.commit('SHOW_LOGIN', true)
    // }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
$color: #5BDAA4;
$link-color: #0EBA71;

#main-block {
  text-align: left;
  padding-bottom: 30px;
  h1 {
    margin-bottom: 0;
  }
}
@media (min-width: 720px) {
  #main-block {
    padding-bottom: 35px;
  }
}

#menu-block {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 0;
}
.menu-block__ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.menu-block__item {
  text-decoration: none;
  width: 33.333%;
  padding: 0;
  height: 50px;
  line-height: 1.1;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  span {font-weight: 300;}
  &.active {
    border-bottom: 2px solid $color;
    span {
      font-weight: 600;
      position: relative;
      top: 1px;
    }
    }
}
@media (min-width: 560px) {
  .menu-block__ul {
    justify-content: flex-start;
  }
  .menu-block__item {
    width: auto;
    padding: 20px;
    height: auto;
    font-size: 14px;
  }
}
@media (min-width: 720px) {
  .menu-block__item {
    font-size: 15px;
  }
}
</style>
