<template>
  <div class="reg-wrapper" reg-scoped>
    <Loader v-if="showLoader" id="loader"/>
    <link rel="stylesheet" href="https://cdn.crezu.net/native/css/bootstrap.min.css">
    <link rel="stylesheet" href="https://cdn.crezu.net/wp_styles/dist/main-styles.css">
    <link rel="stylesheet" href="https://cdn.crezu.net/wp_styles/dist/page-reg.css">
    <link rel="stylesheet" href="https://cdn.crezu.net/wp_styles/dist/page-reg-v2.css">
    <div class="box-wrapper">
      <div class="container">
        <div class="order-stepper">
          <div class="order-stepper-logo">
            <span class="order-stepper-logo__logo">
<!--              <Isvg name="logo" style="max-height: 25px"/>-->
            </span>
          </div>
        </div>
      </div>
      <div class="modalbg"></div>
      <div class="container" style="margin: auto">
        <div class="main-content main-content--reverse main-content--asideFirst">
          <div class="main-content__cont">
            <form action="#" class="main_container_form form ui-form-container__container_separator_JGJ1p">
              <input type="hidden" :value="provider" name="provider">
              <input type="hidden" :value="countryCode" name="country">
              <input type="hidden" value="opinomia.com" name="domain">
              <input type="hidden" value="" name="utm_source" id="utm_source">
              <input type="hidden" value="" name="utm_medium" id="utm_medium">
              <input type="hidden" value="" name="utm_campaign" id="utm_campaign">
              <input type="hidden" value="" name="utm_content" id="utm_content">
              <input type="hidden" value="" name="utm_term" id="utm_term">
              <input type="hidden" value="" name="ga_client_id" id="ga_client_id">
              <input type="hidden" value="" name="gclid" id="gclid">
              <input type="hidden" value="" name="lead_id" id="lead_id_input">
              <h1 class="topbar text-center" id="h1" name="h1">
                {{translation.reg_header}}
              </h1>
              <div class="progress_bar_holder">
                <div class="progress_bar_wrapper">
                  <div class="progress_bar ui-form-progress-bar__container">
                    <div class="ui-form-progress-bar">
                      <div class="ui-form-progress-bar__progress ui-form-progress-bar__progress_zebra"
                        style="width: 10%;"></div>
                      <div class="ui-form-progress-bar__description">
                      </div>
                      <div class="text">
                        <!--1% probabilidad de aprobación de tu préstamo de 300€-->
                      </div>
                    </div>
                  </div>
                  <div class="text-center ui-form-progress-bar__hint">
                    <div class="cont">
                      <p>+ <strong class="percent">7</strong>% si rellenas <strong class="field_name">Nombre</strong></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="formcontent ui-form__wrapper form-step active">
              </div>
              <hr class="devider" id="devider">
              <div class="ui-form__row app-form-action-buttons">
                <div class="ui-form__fieldset ui-form__fieldset_inline app-form-action-buttons">
                  <div class="ui-form__row ui-form__row_checkbox app-form-action-agreement">
                    <div class="ui-form__field vali-1">
                      <div class="ui-checkbox-directive">
                        <span class="ui-blackhole">
                          <input type="checkbox" name="is_terms_accepted" id="agreement" readonly="" data-required>
                        </span>
                        <label for="agreement" class="ui-checkbox app-form-action-agreement">
                          <div class="ui-checkbox__check">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 13" class="ui-icon-checkbox ui-checkbox__icon"><path stroke="#fff" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M1 7s2.9 2.7 4.4 4c.4.3 1.1 1 1.1 1L16 1"></path></svg>
                          </div>
                          <div v-html="translation.agreements_html"></div>
                        </label>
                      </div>
                      <div class="ui-form-field-error-message ui-form-field-error-message_ui-form hidden" style="margin-left: 40px">{{translation.agreements_error}}</div>
                    </div>
                    <div v-if="countryCodeName === 'es'" class="ui-form__field vali-2">
                      <div class="ui-checkbox-directive">
                        <span class="ui-blackhole">
                          <input type="checkbox" name="is_terms_accepted" id="agreement_2" readonly="" data-required="">
                        </span>
                        <label for="agreement_2" class="ui-checkbox app-form-action-agreement">
                          <div class="ui-checkbox__check">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 13" class="ui-icon-checkbox ui-checkbox__icon"><path stroke="#fff" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" d="M1 7s2.9 2.7 4.4 4c.4.3 1.1 1 1.1 1L16 1"></path></svg>
                          </div>
                          <span class="ui-checkbox__text-wrapper collapsible-agreement_2">
                            <div>
                              <div class="header-agreement_2" style="width: calc(100% - 40px)">
                                <div class="headerContent--HzJYY">Consiento estar al día de las últimas noticias y recibir ofertas especiales y descuentos por cualquier medio, incluidas las comunicaciones electrónicas o equivalentes de Fininity Ltd.
                                  <span class="leer-mass"><span style="color: #337ab7;cursor: pointer">Leer más</span>.</span>
                                </div>
                              </div>
                                <div>
                                    <div class="arrow-bottom agreement-arrow-2"></div>
                                </div>
                            </div>
                          </span>
                        </label>
                      </div>
                      <div class="body-agreement_2 hidden collapsible-agreement_2" style="margin-bottom: 30px">
                        <div>Consiento recibir comunicaciones comerciales incluso tras la terminación de la relación contractual, por cualquier medio, incluidas comunicaciones electrónicas o equivalentes de Fininity Ltd,
                          tal como información sobre servicios financieros o similares ofertados por Fininity Ltd y/o los siguientes prestamistas, personalizadas a través de la creación de un perfil de mis hábitos de consumo a través de información interna y externa,
                          e.g. de ficheros de solvencia o fuentes accesibles al público (censos promocionales, listas de sujetos que pertenecen a colectivos profesionales o redes sociales abiertas, entre otras).<br>
                          Por favor tenga en cuenta que tiene derecho a retirar su consentimiento en cualquier momento. Para obtener más información sobre como retirar su consentimiento y sobre nuestras actividades de tratamiento de datos personales, por favor consulte nuestra
                          <a href="/privacy" target="_blank">Política de Privacidad</a>.
                        </div>
                      </div>
                      <div class="ui-form-field-error-message ui-form-field-error-message_ui-form hidden" style="margin-left: 40px">Por favor, acepta los términos</div>
                    </div>
                    </div>
                    <div class="ui-form__row ui-form__row_component">
                      <div class="ui-form__field ui-form__field_component">
                        <div class="ui-form__component">
                          <div class="app-form-forward-wrap">
                            <button class="ui-button ui-button_size_xxl app-form-forward-button" type="submit" target=""><span class="ui-button__text"><img style="vertical-align: top;margin-right: 10px;height: 24px" src="../../assets/img/lock.png"></span>{{translation.login_continue}}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="countryCodeName === 'es'" class="ui-form__row ui-form__row_component" id="additional-text-collapsible" style="line-height: 1.1;font-weight: 400;color: #565656;margin-top: 80px">
                      <div class="ui-checkbox-directive" style="cursor: pointer">
                        <div class="collapsible-agreement_3">
                          <div>
                            <div class="header-agreement_3" style="">
                              <div class="headerContent--HzJYY">* Antes de pinchar en “continuar” y enviarnos su solicitud de préstamo, debe entender que:
                                <span class="sigue-leyendo">(<span style="color: #337ab7;">Sigue leyendo</span>)</span>
                              </div>
                            </div>
                            <div>
                              <div class="arrow-bottom agreement-arrow-3" style="top: 15px"></div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="body-agreement_3 hidden collapsible-agreement_3"><div><ul><li style="font-size: 12px;">Se le creará automáticamente una cuenta de usuario. Sin estar en posesión de una cuenta no podrá hacer uso de nuestros servicios. Para más información por favor consulte nuestras <a href="/terms" class="">Condiciones Generales</a>.</li><li style="font-size: 12px;">Sus datos personales se compartirán con todos o con alguno de los siguientes prestamistas con el fin de proporcionarle posibles ofertas de préstamos. Sin compartir sus datos personales con todos o alguno de los prestamistas de la lista (lo cual es necesario para hacerle ofertas de préstamos) no seremos capaces de prestarles nuestros servicios. Hemos llegado a acuerdos con todos los prestamistas en relación con el tratamiento de los datos de carácter personal de nuestros clientes, para cerciorarnos de que solo van a usar sus datos personales con los fines previstos (i.e. proporcionarles distintas ofertas de préstamos). Tenga en cuenta que los prestamistas actúan como responsables del tratamiento de datos personales independientes y que Fininity no puede ser responsable por sus actividades de tratamiento. Para más información sobre este punto, por favor, consulte nuestras <a href="/terms" class="">Condiciones Generales</a> y <a href="/privacy" class="">Política de Privacidad</a>.</li></ul></div></div>
                  </div>
                </div>
              </div>
            </form>
        </div>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

import { mapState } from 'vuex'
import Loader from '../components/Loader'
let leadCreatingListener
let formLoadedListener
let feedRedirectListener

export default {
  name: 'Register',
  components: {
    Loader
  },
  data () {
    return {
      showLoader: true
    }
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      translation: state => state.translation,
      review_lender: state => state.review_lender,
      fields_info: state => state.fields_info,
      JWToken: state => state.JWToken,
      isAuthorised: state => state.isAuthorised,
      additionalParams: state => state.additionalParams,
      productId: state => state.productId,
      countryCodeName: state => state.countryCodeName,
      countryCode: state => state.countryCode,
      provider: state => (state.productId === 2 && state.countryCodeName === 'es') ? 63 : state.provider,
      feedURLAfterReg: state => state.feedURLAfterReg
    })
  },
  beforeCreate () {
    this.$store.commit('SHOW_LOADER', true)
    if (+localStorage.getItem('productId') === 2) localStorage.removeItem('auth_token')
  },
  mounted () {
    const dataFromFirstStep = { amount: 1500 }
    if (this.countryCodeName === 'co') dataFromFirstStep.amount = 675000
    if (this.countryCodeName === 'ph') dataFromFirstStep.amount = 10000
    if (this.countryCodeName === 'vn') dataFromFirstStep.amount = 5250000
    localStorage.setItem('dataFromFirstStep', JSON.stringify(dataFromFirstStep))
    if (!window.opinomiaFeedUrl) window.opinomiaFeedUrl = localStorage.getItem('opinomiaFeedUrl') || '/offers2'
    this.loadFromCDN(`https://cdn.crezu.net/wp_js/dist/countries/${this.countryCodeName}.js`, () => {
      this.loadFromCDN('https://cdn.crezu.net/wp_js/dist/common.js', () => {
        this.loadFromCDN('https://cdn.crezu.net/wp_js/dist/page-reg.js')
      })
    })
    leadCreatingListener = (e) => {
      console.log('new LEAD', e.detail.leadId)
      this.$store.commit('UPDATE_LEAD', e.detail.leadId)
      this.$store.dispatch('productLinking', {
        event_type: 'product',
        external_uuid: e.detail.leadId,
        product_type_id: this.productId
      })
      this.$store.dispatch('updateOffersInfo')
    }
    formLoadedListener = (e) => {
      if (this.provider === 63) {
        console.log('update debt form')
        setTimeout(() => {
          $('[name=loan_purpose]').val('Otro')
        }, 1000)
        $('.fixed-progress, .main-content__cyrcle').find('p').each(function () {
          $(this).replaceWith('<div style="font-weight: 300;max-width: 300px;padding-left:10px">Probabilidad de aprobación de tu solicitud</div>')
        })
      } else if (localStorage.getItem('hideLoanPurposeField') === '1') {
        console.log('hide loan purpose field')
        $('[name=loan_purpose]').closest('.ui-form__row').addClass('hidden')
        setTimeout(() => {
          $('[name=loan_purpose]').val('Otro')
          $('[name=loan_sum]').blur()
          $('[name=loan_period]').blur()
        }, 1000)
      } else {
        console.log('standart form loaded')
      }
      setTimeout(() => { this.showLoader = false }, 1000)
    }
    feedRedirectListener = (e) => {
      const feedURL = `/${this.countryCodeName}${this.feedURLAfterReg}?sub1=${e.detail.leadId}&sub2=feed${e.detail.suffix}`
      console.log('redirect to feed:', feedURL)
      window.location.href = feedURL // call native redirect for page reloading
      // this.$router.push(feedURL)
      // this.$router.push(feedURL)
      // setTimeout(() => {
      //   console.log('start updating offers')
      //   this.$store.dispatch('updateOffersInfo')
      // }, 500)
    }
    window.addEventListener('leadCreated', leadCreatingListener)
    window.addEventListener('formLoaded', formLoadedListener)
    window.addEventListener('opinomiaFeedRedirect', feedRedirectListener)
  },
  beforeDestroy () {
    $('head').find('link[href$="https://cdn.crezu.net/wp_styles/dist/page-reg-v2.css"]').remove()
    $('.fixed-progress').remove()
    window.removeEventListener('leadCreated', leadCreatingListener)
    window.removeEventListener('formLoaded', formLoadedListener)
    window.removeEventListener('opinomiaFeedRedirect', feedRedirectListener)
  },
  methods: {
    loadFromCDN: (url, callback) => {
      const script = document.createElement('script')
      script.src = url
      document.body.appendChild(script)
      script.onload = () => {
        if (callback) callback()
      }
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
  html {
    height: 100vh;
  }
  body {
    height: auto !important;
  }
#opi-header {
  display: flex !important;
}
  .header-right a {
    color: black;
    font-family: Roboto,Arial,sans-serif;
  }
  .main-content__cyrcle.fixed {
    padding-top: 70px;
  }
  .el-loading-mask {
    background: white;
  }
  @media (max-width: 560px) {
    .apl-account-navbar {
      margin-top: 20px;
    }
  }
</style>
