export const VN_TRANSLATION = {
  main_header: 'Bạn đang tìm kiếm sản phẩm nào?',
  main_categories: ['Các khoản vay nhanh', 'Tái hợp nợ', 'Thẻ và tài khoản', 'Có điểm tín dụng xấu'],
  prestamos_link: 'Khoản vay',
  credit_score_link: 'Điểm tín dụng của tôi',
  header_1: 'Tôi có thể nhận khoản vay đầu tiên miễn phí ở đâu?',
  header_2: 'Lựa chọn khoản vay thật dễ dàng với Opinomia',
  main_cta: 'Tìm kiếm khoản vay',
  stats_header: 'Nhận ưu đãi tốt nhất',
  stats_1: ['50 +', 'ngân hàng và công ty cho vay '],
  stats_2: ['5,000+', 'các khoản vay đã phát hành'],
  stats_3: ['50,000', 'người dùng mỗi tháng'],
  trust_header: 'Tại sao mọi người tin tưởng Opinomia?',
  trust_1_header: 'Thông tin cập nhật',
  trust_1_body: 'Nhóm của chúng tôi nhận được thông tin cập nhật mới nhất từ ​​các công ty cho vay về lãi suất và các khoản hoa hồng khác. Chúng tôi hợp tác với tất cả các nhà cung cấp khoản vay đáng tin cậy trên thị trường và không cộng tác với các công ty lừa đảo. ',
  trust_2_header: 'Tiết kiệm tiền',
  trust_2_body: 'Opinomia giúp bạn tránh các đề nghị lừa đảo và giúp bạn nhận được đề nghị với tỷ lệ hoa hồng tốt nhất. Chúng tôi tiết kiệm thời gian và tiền bạc của bạn! Dịch vụ của chúng tôi là hoàn toàn miễn phí cho bạn. Chúng tôi hoàn toàn không tính phí bạn. Hãy nhớ: Tránh trả tiền trước khi nhận khoản vay của bạn. ',
  trust_3_header: 'Bảo mật',
  trust_3_body: 'Thuốc giảm bạch cầu tuân thủ GDPR. Dữ liệu của bạn được SSL bảo vệ khỏi truy cập không được phép và được lưu trữ trong Google Cloud. ',
  cta_header: 'Điểm tín dụng',
  cta_body: 'Khám phá miễn phí điểm tín dụng của bạn và nhận các đề xuất miễn phí được cá nhân hóa về cách cải thiện điểm số này',
  cta_button: 'Khám phá miễn phí',
  cta_like: 'Xếp hạng của bạn tốt hơn 70% người dùng',
  about_header: 'Giới thiệu về Opinomia',
  about_1_header: 'Opinomia là gì?',
  about_1_body: ['Opinomia là dịch vụ miễn phí giúp bạn đưa ra quyết định khi chọn một sản phẩm tài chính. "," Chúng tôi thu thập thông tin về tất cả các ưu đãi hiện có trên thị trường và cập nhật thông tin đó mỗi khi các điều khoản của họ thay đổi. Các đánh giá đã được xác minh từ khách hàng thực là để giúp người dùng tránh các giao dịch lừa đảo và chỉ chọn các nhà cung cấp khoản vay an toàn. ', ' Chúng tôi cũng cung cấp điểm tín dụng miễn phí và các đề xuất miễn phí được cá nhân hóa về cách cải thiện nó. '],
  about_2_header: 'Làm thế nào để đóng góp?',
  about_2_body: 'Nếu bạn đã từng đăng ký một khoản vay, hãy gửi đánh giá và chia sẻ kinh nghiệm của bạn để giúp hàng trăm người chọn một nhà cung cấp khoản vay trung thực.',
  about_button: 'Gửi đánh giá',
  rights_disclamer: 'Mọi quyền được bảo lưu.',
  terms_footer_link: 'Điều khoản & Điều kiện',
  privacy_footer_link: 'Chính sách Bảo mật',
  cookie_footer_link: 'Chính sách cookie',
  contact_footer_link: 'Liên hệ',
  offers_reviews: '54 đánh giá ',
  offers_conditions: 'Điều kiện cho vay',
  offers_amount_range: 'Phạm vi số lượng',
  offers_min_interest: 'Lãi suất tối thiểu',
  offers_timing_range: 'Phạm vi thời gian',
  offers_guarantee: 'Yêu cầu bảo hành',
  offers_age_range: 'Độ tuổi',
  offers_get_loan: 'Nhận khoản vay',
  offers_compare: 'So sánh',
  offers_currency: '€',
  offers_days: 'ngày',
  offers_months: 'months',
  offers_years: 'years',
  offers_percent: '%',
  offers_age: 'years',
  offers_delete: 'Xóa',
  offers_difficulty: 'Khó áp dụng',
  offers_speed: 'Tốc độ nhận tiền',
  offers_hidden_commissions: 'Hoa hồng ẩn',
  offers_service: 'Dịch vụ',
  offers_satisfaction: 'Mức độ hài lòng tổng thể về tín dụng',
  offers_interest_rate: 'Lãi suất',
  offers_show: 'Xem chi tiết',
  offers_all_reviews: 'Tất cả các đánh giá',
  offers_show_all_reviews: 'Hiển thị tất cả các đánh giá',
  offers_header: 'Các khoản vay trực tuyến ở Tây Ban Nha',
  offers_filter_average_rating: 'Xếp hạng',
  offers_filter_loan_sum: 'Số lượng',
  offers_filter_period: 'Giai đoạn',
  offers_filter_first_loan_free: '0% lãi suất',
  offers_filters: 'Thêm bộ lọc',
  offers_free: 'khoản vay đầu tiên miễn phí',
  offers_aggregator: 'Công cụ tổng hợp khoản vay',
  offers_filter_guarantee: 'Yêu cầu đảm bảo',
  offers_filter_aggregator: 'Người trung gian',
  offers_filter_asnef: 'Chấp nhận ASNEF',
  offers_filter_age: 'Tuổi',
  offers_save: 'Lưu',
  offers_clear: 'Rõ ràng',
  offers_rating_descr: 'Chọn từ xếp hạng để lọc các đề nghị cho vay:',
  offers_show_results: 'Hiển thị kết quả',
  offers2_header: ', chúng tôi đã lựa chọn cho bạn những đề xuất cá nhân!',
  login_header: 'Đăng nhập',
  logout_header: 'Đăng xuất',
  login_register: 'Đăng ký',
  login_subheader: 'Đăng nhập để chúng tôi có thể ngăn việc nhận các đánh giá sai từ các tổ chức tín dụng',
  login_email: 'Email',
  login_name: 'Tên',
  login_surname: 'Họ',
  login_password: 'Mật khẩu',
  login_hide: 'Ẩn',
  login_show: 'Hiển thị',
  login_continue: 'Continue',
  login_or: 'hoặc',
  login_fb: 'Tiếp tục với Facebook',
  login_google: 'Tiếp tục với Google',
  login_not_reg: 'Không phải là thành viên của Opinomia?',
  login_reg: 'Đăng ký',
  login_terms: ['Tôi đồng ý với việc xử lý', 'dữ liệu cá nhân của tôi', 'và nhận quảng cáo và các tin nhắn thông tin khác'],
  credit_header: 'Điểm tín dụng cá nhân',
  credit_subheader: 'Khám phá điểm tín dụng của bạn miễn phí và nhận đề xuất miễn phí được cá nhân hóa về cách cải thiện nó',
  credit_button: 'Khám phá miễn phí',
  credit_about_first: 'Điểm tín dụng là gì',
  credit_about_first_body: 'Điểm tín dụng là một xếp hạng cho biết cơ hội vay vốn của bạn là bao nhiêu.',
  credit_about_second: 'Cách sử dụng nó',
  credit_about_second_body: 'Dựa trên điểm tín dụng của bạn, Opinomia cung cấp cho bạn ước tính về cơ hội nhận được khoản vay của bạn cao như thế nào và công ty cho vay nào phù hợp nhất với bạn. Opinomia cũng cung cấp cho bạn các đề xuất miễn phí được cá nhân hóa về cách cải thiện điểm tín dụng của bạn. ',
  credit_hiw_header: 'Cách hoạt động',
  credit_hiw_1: 'Điền vào một bảng câu hỏi đơn giản',
  credit_hiw_2: 'Thuật toán của chúng tôi tính toán điểm tín dụng của bạn và hiển thị cách bạn so sánh với mức trung bình của thị trường',
  credit_hiw_3: 'Bạn nhận được danh sách các công ty cho vay có khả năng cấp cho bạn một khoản vay dựa trên điểm tín dụng của bạn',
  credit_hiw_4: 'Chúng tôi đưa ra các đề xuất về cách bạn có thể cải thiện điểm số của mình',
  credit_cta_header: 'Khám phá cách điểm số của bạn so với mức trung bình của thị trường chỉ trong giây lát!',
  credit_cta_body: 'Các nhà cung cấp khoản vay có các yêu cầu khác nhau đối với người nộp đơn và chúng tôi sẽ cho bạn thấy những công ty có khả năng phê duyệt hồ sơ tín dụng chính xác mà bạn có.',
  credit_cta_button: 'Khám phá miễn phí',
  credit_security: 'Bảo mật',
  credit_security_body: 'Kết nối dữ liệu được bảo vệ bằng SSL, được lưu trữ trong Google và an toàn trước sự truy cập trái phép',
  credit_privacy: 'Quyền riêng tư',
  credit_privacy_body: 'Opinomia là một dịch vụ tuân thủ GDPR. Bạn có thể xóa dữ liệu của mình khỏi hệ thống của chúng tôi bất cứ khi nào bạn muốn ',
  yes: 'vâng',
  no: 'không',
  clear: 'Thông thoáng',
  review_header: 'Viết đánh giá',
  review_company: 'Tên công ty',
  review_q1: 'Khoản vay ngày càng phức tạp',
  review_q2: 'Lãi suất',
  review_q3: 'Tốc độ nhận tiền',
  review_q4: 'Hỗ trợ khách hàng',
  review_q5: 'Mức độ hài lòng tổng thể về tín dụng',
  review_q6: 'Hoa hồng ẩn',
  review_graduate: ['Rất tệ', 'Xấu', 'Bình thường', 'Tốt', 'Rất tốt'],
  review_text: 'Đánh giá của bạn',
  review_submit: 'Gửi',
  account_change: 'Thay đổi',
  account_save: 'Lưu',
  compare_header: 'So sánh các ưu đãi',
  success: 'Thành công',
  error: 'Lỗi',
  account_header: 'Chào mừng bạn trở lại,',
  account_credit_score: 'Điểm tín dụng',
  account_write_review: 'Gửi đánh giá',
  account: 'tài khoản',
  description: 'Mô tả',
  condition: 'Điều kiện cho vay',
  scam_offers: 'Đề nghị lừa đảo',
  asnef: 'Chấp nhận ASNEF',
  credit_score_header: 'Tín dụng của tôi',
  credit_score_subheader: 'Khám phá điểm tín dụng của bạn miễn phí và nhận đề xuất miễn phí được cá nhân hóa về cách cải thiện điểm tín dụng!',
  credit_score_cta: 'Khám phá',
  credit_score_header_2: 'Cảm ơn',
  credit_score_subheader_2: ['Điểm của bạn là', 'và tốt hơn', '% người dùng của chúng tôi. * '],
  credit_score_subheader_3: 'Các công ty cho vay sau phát hành khoản vay cho khách hàng có số điểm tương tự như của bạn:',
  credit_score_show_more: 'Hiển thị thêm ưu đãi',
  credit_score_hint: '* phần chấm điểm do công ty Fininity OÜ soạn và chỉ phục vụ vì lý do thông tin.',
  scam_header: ['Nhận biết ưu đãi lừa đảo', 'Và bảo vệ bạn khỏi gian lận'],
  scam_subheader: 'Mục tiêu của chúng tôi tại Opinomia là làm cho quá trình chọn khoản vay an toàn nhất có thể. Về vấn đề đó, chúng tôi muốn cảnh báo người tiêu dùng về các đề nghị lừa đảo và khả năng gian lận liên quan đến các khoản vay. ',
  scam_about_header: 'Pedir dinero por adelantado',
  scam_about_subheader: ['Es la estafa más común cuando se buscan préstamos online.', 'Sus víctimas más frecuentes son personas con perfiles crediticios complicados que han tenido dificultades para encontrar financiación y que acuden a empresas desconocidas.', 'Una empresa fiable sabe que pides dinero porque no lo tienes, así que evidentemente facilitará las condiciones para que lo obtengas rápido, pero NUNCA te cobrará NADA antes de darte el préstamo.'],
  scam_problem_header: 'Hãy cho chúng tôi biết về trải nghiệm của bạn với các đề nghị gian lận',
  scam_company_name: 'Tên công ty gian lận',
  scam_problem_type: ['Phải trả tiền cho đơn đăng ký', 'Phải gọi vào số điện thoại trả phí', 'Khác'],
  scam_problem_placeholder: 'Loại sự cố',
  scam_problem: 'Mô tả vấn đề của bạn',
  scam_button: 'Gửi',
  registration_form_header: 'Registration Form',
  registration_form_button: 'Submit',
  privacy_checkbox: 'Confirmo que he leído y acepto las <a href="/terms" target="_blank">condiciones generales</a>, <a href="/privacy" target="_blank">política de privacidad</a> y <a href="/cookies" target="_blank">política de cookies</a>.',
  marketing_checkbox: 'Consiento estar al día de las últimas noticias y recibir ofertas especiales y descuentos por cualquier medio, incluidas las comunicaciones electrónicas o equivalentes de Fininity Ltd.',
  offers_period_dropdown: {
    '30 days': 30,
    '6 month': 180,
    '1 year': 365,
    '2 years': 730,
    '3 years': 1095,
    '4 years': 1460,
    '5 years': 1825
  },
  offer_period_dropdown_placeholder: 'Thời gian cho vay',
  error_translate: {
    'Server got itself in trouble': 'Có gì đó không ổn, hãy thử bước cuối cùng sau',
    'User exist': 'Người dùng đã được đăng ký',
    'Permission denied': 'Tên người dùng và mật khẩu không khớp',
    'email: Not a valid email address.': 'Email không hợp lệ',
    'password: Length must be between 1 and 255.': 'Mật khẩu phải từ 1 đến 255 ký tự'
  },
  register_success: 'Bạn đã đăng ký thành công!',
  debt_header: 'Tái hợp các khoản nợ',
  debt_subheader: '<b> tái hợp nợ </b> có nghĩa là nhóm tất cả các khoản vay và thế chấp thành một khoản vay. Bằng cách này, bạn sẽ phải trả một khoản phí hàng tháng duy nhất, số tiền này sẽ ít hơn tổng các khoản phí khác nhau mà bạn đã phải trả trước đó. ',
  debt_questions: [
    ['Hợp nhất nợ là gì?', '<b> tái hợp nợ </b> có nghĩa là nhóm tất cả các khoản vay và thế chấp thành một khoản vay. Bằng cách này, bạn sẽ phải trả một khoản phí hàng tháng duy nhất mà số tiền sẽ nhỏ hơn tổng các khoản phí khác nhau mà bạn đã phải trả trước đó. '],
    ['Các yêu cầu để hợp nhất các khoản nợ là gì?', 'Để tham gia chương trình và thương lượng khoản nợ của bạn, thông thường bạn cần phải nợ các ngân hàng, ngân hàng tiết kiệm, siêu thị, cửa hàng điện và tín dụng vi mô và phải trả sau các khoản thanh toán của bạn. '],
    ['Nợ đoàn tụ dành cho ai?', 'Cho tất cả những người mà khoản nợ của họ đã biến thành một quả cầu tuyết.'],
    ['Nó hoạt động bình thường như thế nào?', 'Công ty phân tích trường hợp của bạn và trình bày cho bạn một kế hoạch được cá nhân hóa, trong đó nó chỉ định số tiền gần đúng mà bạn có thể thanh toán các khoản nợ của mình và thời gian bạn phải làm như vậy. ']
  ],
  card_header: 'Thẻ tín dụng',
  card_subheader: 'Mua hàng, nhận tiền mà không cần hoa hồng, tài trợ cho các chuyến đi ... Tìm thẻ hoàn hảo của bạn cho mọi dịp. Tại Opinomia, chúng tôi chỉ xuất bản những thẻ ngân hàng tốt nhất. So sánh miễn phí! ',
  card_questions: [
    ['Có những loại thẻ nào?', 'Phổ biến nhất là <b> thẻ ghi nợ </b>. Chúng cho phép bạn truy cập vào số dư bạn có sẵn trong tài khoản ngân hàng của mình. Chúng rất lý tưởng để mua hàng và rút tiền mặt tại các máy ATM. <p> Các thẻ có thể hoạt động như một khoản vay là <b> thẻ tín dụng </b>. Các thẻ này có giới hạn số dư tín dụng khả dụng. Có các phương thức thanh toán khác nhau: </p> <ul style = "list-style-type: circle; list-style-position: inside;"> <li> thanh toán trả chậm </li> <li> thanh toán vào cuối tháng </li> <li> những người khác; </li> </ul> Chúng không nhất thiết phải được liên kết với tài khoản séc của bạn. Các thẻ hoạt động với số dư giới hạn là <b> thẻ trả trước </b>. Bạn quyết định số tiền cần bỏ vào thẻ và đó sẽ là số dư mà chúng tôi sẽ có. Ví dụ, chúng rất lý tưởng để mua hàng trên Internet. '],
    ['Chi phí đặt hàng một thẻ ngân hàng mới là bao nhiêu?', 'Điều đầu tiên phải trả khi đặt mua thẻ là <b> phí phát hành </b>. Tùy thuộc vào ngân hàng và thẻ, chi phí có thể thay đổi từ 0 đến 80 euro. Một khoản thanh toán quan trọng khác là <b> phí bảo trì, thường là hàng năm. Chi phí có thể thay đổi từ 0 đến 150 euro mỗi năm. <b> Để rút tiền mặt tại các máy ATM </b>, đôi khi bạn cũng phải trả một khoản hoa hồng. Thông thường, nó phụ thuộc vào việc máy ATM là của cùng một tổ chức hay của một ngân hàng khác. ']
  ],
  fcm_request: 'Cho phép thông báo kiểm tra trạng thái yêu cầu của bạn',
  landing_loans_purpose: [
    'Mua một chiếc xe hơi',
    'Mua một chiếc xe máy',
    'Đi du lịch',
    'Sửa chữa',
    'Cho vay hợp nhất',
    'Khác'
  ],
  agreements_html: 'Tôi xác nhận rằng mình đã đọc và đồng ý với <a href="/terms" class="">quy định dịch vụ</a>, <a href="/privacy" class="">chính sách bảo mật</a> và <a href="/cookies" class="">chính sách sử dụng cookie.</a>',
  agreement_error: 'Bạn phải đồng ý với các điều khoản để tiếp tục.',
  reg_header: 'Nhập thông tin liên hệ của bạn',
  country_popup_header: '¡Smile! Bạn đang ở Opinomia 😊 ',
  country_popup_subheader: ['Trang này dành cho khách hàng từ', '. Chọn quốc gia của bạn để xem nội dung có liên quan!'],
  cookiesText: 'Nếu bạn tiếp tục xem website, chúng tôi sẽ coi như bạn chấp nhận chính sách sử dụng cookie.',
  cookiesLink: 'politica-de-cookie',
  cookiesAcceptText: 'Chấp nhận',
  cookiesMoreText: 'Đọc thêm'
}
