<template>
  <section id="menu" :class="showMobileMenu">
    <!-- <img src="img/i-menu.png" alt="" class="menu-close"> -->
    <Isvg class="menu-logo" name="logo"/>
    <Isvg @click.native="close" name="close"/>
    <!-- pages -->
    <ul class="menu-pages">
      <li class="menu-pages__ul" @click="expandLocale=!expandLocale" :class="{active: expandLocale}">
        <a class="menu-pages__ul-a">
          <span>Selecciona tu país</span>
          <img src="../../assets/img/arrow-menu.svg" alt="">
        </a>
        <ul @click="close">
          <li><a v-for="(value, key, index) in multiCountry"
                 :key="index"
                 @click="$store.dispatch('setLocale', key)"
                 :class="{active: key === countryCodeName}">
            <img :src="require(`@/assets/img/lang-${key}.png`)" alt="">{{value.countryName}}
          </a>
          </li>
        </ul>
      </li>
      <li class="menu-pages__separate" style="padding: 5px"></li>
      <li class="menu-pages__ul" @click="expandOffers=!expandOffers" :class="{active: expandOffers}">
        <a class="menu-pages__ul-a">
          <span>{{translation.prestamos_link}}</span>
          <img src="../../assets/img/arrow-menu.svg" alt="">
        </a>
        <ul @click="close">
          <li><router-link to="/offers">Préstamos Rápidos</router-link></li>
          <li><router-link to="/debt-restructuring">Reunificación de deudas</router-link></li>
          <li><router-link to="/offers">Préstamos con ASNEF</router-link></li>
        </ul>
      </li>
      <li @click="close" class="menu-pages__separate menu-pages__ul"
          style="border-bottom: 1px solid #e1e1e1;margin-bottom: 10px;"><router-link to="/cards">Tarjetas</router-link></li>
      <li class="menu-pages__ul" @click="expandScam=!expandScam" :class="{active: expandScam}">
        <a class="menu-pages__ul-a">
          <span>Opiniones</span>
          <img src="../../assets/img/arrow-menu.svg" alt="">
        </a>
        <ul @click="close">
          <li><router-link to="/scam">Ofertas de estafa</router-link></li>
<!--          <li><router-link to="/opinion/credy">Opiniones</router-link></li>-->
        </ul>
      </li>
      <div @click="close">
        <li class="menu-pages__separate"><router-link to="/credit">{{translation.credit_score_link}}</router-link></li>
        <li v-if="isAuthorised" class="menu-pages__separate"><router-link to="/cabinet/review">{{translation.review_header}}</router-link></li>
        <li v-if="isAuthorised" class="menu-pages__separate"><router-link to="/cabinet/credit">{{translation.account}}</router-link></li>
        <li v-if="isAuthorised" class="menu-pages__separate"><a @click="logout" class="open-popup open-reg">{{translation.logout_header}}</a></li>
      </div>
    </ul>
    <a v-if="!isAuthorised" @click="showLogin" class="menu-signin button">{{translation.login_header}}</a>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'

export default {
  name: 'MobileMenu',
  components: {
    Isvg
  },
  computed: {
    ...mapState({
      showMobileMenu: state => state.showMobileMenu ? 'active' : '',
      translation: state => state.translation,
      lang: state => state.lang,
      isAuthorised: state => state.isAuthorised,
      multiCountry: state => state.multiCountry,
      countryCodeName: state => state.countryCodeName
    })
  },
  data () {
    return {
      expandOffers: false,
      expandScam: false,
      expandLocale: false
    }
  },
  methods: {
    showLogin () {
      this.$store.commit('SHOW_LOGIN', true)
    },
    showRegister () {
      this.$store.commit('SHOW_REGISTER', true)
    },
    close () {
      this.$store.commit('SHOW_MOBILE_MENU', false)
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>

</style>
