<template>
  <div class="popup-wrap active">
    <div class="popup popup-login active">
        <!-- top -->
        <div class="popup-top">
          <h2>{{translation.login_header}}</h2>
          <p>{{translation.login_subheader}}</p>
          <Isvg @click.native="close" name="close"/>
        </div>
        <!-- body -->
        <div class="popup-body">
          <button @click="facebookAuth" class="popup-body__social fb">{{translation.login_fb}}</button>
          <button @click="googleAuth" class="popup-body__social gl">{{translation.login_google}}</button>
          <div class="popup-body__separate"><p>{{translation.login_or}}</p></div>
          <form>
            <input v-model="email"
                   type="email"
                   :placeholder="translation.login_email"
                   id="login-mail">
            <div class="input-wrap">
              <input v-model="password"
                     :type="showPass ? 'text' : 'password'"
                     :placeholder="translation.login_password"
                     class="input-pass"
                     id="login-pass">
              <span class="input-pass__show active" @click="showPass=!showPass">{{showPass ? translation.login_hide :
                translation.login_show}}</span>
            </div>
            <button @click="emailAuth($event)">{{translation.login_continue}}</button>
          </form>
          <p class="popup-body__p-bottom">{{translation.login_not_reg}}
            <a @click="toRegistration">{{translation.login_reg}}</a></p>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Login',
  components: {
    Isvg
  },
  computed: {
    ...mapState({
      translation: state => state.translation
    })
  },
  data () {
    return {
      showPass: false,
      email: '',
      password: '',
      isConnected: false,
      name: '',
      personalID: '',
      picture: '',
      FB: undefined
    }
  },
  mounted () {
    this.prepareFB()
  },
  methods: {
    googleAuth () {
      this.$store.dispatch('googleAuth')
    },
    facebookAuth () {
      this.$store.dispatch('facebookAuth')
    },
    emailAuth (e) {
      e.preventDefault()
      this.$store.dispatch('emailAuth', {
        email: this.email,
        password: this.password
      }).then((response) => {
        console.log('success login', response)
        this.close()
      }, (response) => {
        console.log('error login', response)
      })
    },
    toRegistration () {
      this.$store.commit('SHOW_LOGIN', false)
      this.$store.commit('SHOW_REGISTER', true)
    },
    close () {
      this.$store.commit('SHOW_LOGIN', false)
      this.$store.commit('SHOW_REGISTER', false)
    },
    async prepareFB () {
      await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
      await this.initFacebook()
      return false
    },
    async initFacebook () {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '234625880968366', // You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: 'v13.0'
        })
      }
    },
    async loadFacebookSDK (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      const js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }
  }
}
</script>

<style scoped>

</style>
