/**
 * Created by Stanislav Gulevich on 12.01.2020.
 */
import { EN_TRANSLATION } from '@/translations/en'
import { ES_TRANSLATION } from '@/translations/es'
import { VN_TRANSLATION } from '@/translations/vn'
import Vue from 'vue'

export const SHOW_MOBILE_MENU = function (state, val) {
  state.showMobileMenu = val
}
export const SHOW_LOADER = function (state, val) {
  state.showLoader = val
}
export const SHOW_LOGIN = function (state, val) {
  state.showLogin = val
}
export const SHOW_REGISTER = function (state, val) {
  state.showRegister = val
}
export const SHOW_CARDS_FORM = function (state, val) {
  state.showCardsForm = val
}
export const SHOW_LOCALE_SELECTOR = function (state, val) {
  state.showLocaleSelector = val
}
export const SET_LOCALE = function (state, locale) {
  state.lang = state.multiCountry[locale].lang
  state.default_lead = state.multiCountry[locale].default_lead
  state.feedConfig = state.multiCountry[locale].feedConfig
  state.countryCode = state.multiCountry[locale].country
  state.countryCodeName = locale
  state.countryName = state.multiCountry[locale].countryName
  state.currency = state.multiCountry[locale].currency
  state.provider = state.multiCountry[locale].provider
  state.interfaceReady = true
  state.showLoader = false
}

export const SET_TRANSLATION = function (state) {
  switch (state.lang) {
    case 'ES':
      state.translation = ES_TRANSLATION
      break
    case 'EN':
      state.translation = EN_TRANSLATION
      break
    case 'VN':
      state.translation = VN_TRANSLATION
      break
    default:
      state.translation = ES_TRANSLATION
      break
  }
}

export const CHANGE_ADDITIONAL_PARAMS = function (state, val) {
  const temp = state.additionalParams
  for (const i in val) {
    Vue.set(temp, i, val[i])
  }
  Vue.set(state, 'additionalParams', temp)
}

export const CHANGE_AUTH_STATE = function (state, params) {
  state.isAuthorised = params.isAuthorised
  state.loginProvider = params.loginProvider
  if (params.data.token) state.JWToken = params.data.token
  for (const i in params.data.user_data) {
    Vue.set(state.user_data, i, params.data.user_data[i])
  }
  localStorage.setItem('authorisation', JSON.stringify(params))
  if (state.user_data.lead_id) {
    state.leadId = state.user_data.lead_id
    localStorage.setItem('leadId', state.user_data.lead_id)
  }
  if (localStorage.getItem('leadId') === 'null') localStorage.removeItem('leadId')
}

export const OFFERS_LIST = function (state, val) {
  state.offers_list = val
}

export const OFFERS_INFO = function (state, val) {
  state.offers_info = val
}

export const UPDATE_OFFERS_INFO = function (state, val) {
  state.offers_info = val
}

export const FIELDS_INFO = function (state, val) {
  state.fields_info = val
}

export const COMPARE_LIST = function (state, val) {
  state.compare_list = val
  const indexes = []
  val.map(x => indexes.push(x.code))
  state.compare_codes = indexes
}

export const REVIEW_LENDER = function (state, val) {
  state.review_lender = val
  localStorage.setItem('review_lender', JSON.stringify(val))
}

export const REVIEWS_LIST = function (state, val) {
  state.reviews_list = val
}
export const FIRST_REDIRECT_CHANGE_STATE = function (state, val) {
  state.firstRedirect = val
}

export const CHANGE_TARGET_PAGE = function (state, val) {
  state.targetPage = val
}

export const CHANGE_WAS_ON_REG = function (state, val) {
  state.wasOnReg = val
}

export const UPDATE_LEAD = function (state, val) {
  state.leadId = val
}

export const UPDATE_CARDS_LINK = function (state, val) {
  state.cardsLink = val
}

export const UPDATE_PRODUCT_ID = function (state, val) {
  state.productId = val
  localStorage.setItem('productId', val)
}

export const UPDATE_FEED_URL_AFTER_REG = function (state, val) {
  state.feedURLAfterReg = val
  window.opinomiaFeedUrl = val
  localStorage.setItem('opinomiaFeedUrl', val)
}
