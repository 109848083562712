<template>
  <svg v-if="name === 'menu'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="18px"
       viewBox="0 0 24 18"
       style="enable-background:new 0 0 24 18;" xml:space="preserve" class="header-menu">
    <g>
      <rect class="header-menu__svg" width="24" height="4"/>
      <rect class="header-menu__svg" y="7" width="24" height="4"/>
      <rect class="header-menu__svg" y="14" width="24" height="4"/>
      </g>
  </svg>
  <svg v-else-if="name === 'user'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" width="20px" height="19.5px" viewBox="0 0 20 19.5"
                 style="enable-background:new 0 0 20 19.5;" xml:space="preserve">
    <g>
      <path class="header-right__person__svg"
        d="M16.5,18.5H3.5C2.1,18.5,1,17.4,1,16v0c0-2.7,2.2-5,5-5H14c2.7,0,5,2.2,5,5v0C19,17.4,17.9,18.5,16.5,18.5z"/>
      <circle class="header-right__person__svg" cx="10" cy="6" r="5"/>
    </g>
  </svg>
  <svg v-else-if="name === 'logo'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 271 59" style="enable-background:new 0 0 271 59;" xml:space="preserve">
          <g>
<path d="M76.1,29.7c0,5.2-1.2,9.4-3.6,12.5c-2.4,3.1-5.6,4.7-9.6,4.7c-3.7,0-6.7-1.2-8.9-3.7v16.1h-7.6V12.4h7l0.3,3.4
c2.2-2.7,5.3-4.1,9.1-4.1c4.1,0,7.4,1.5,9.7,4.6c2.3,3.1,3.5,7.3,3.5,12.8V29.7z M68.5,29.1c0-3.4-0.7-6.1-2-8c-1.3-2-3.3-3-5.8-3
c-3.1,0-5.3,1.3-6.7,3.8v15c1.4,2.6,3.6,3.9,6.8,3.9c2.4,0,4.3-1,5.7-2.9C67.8,36,68.5,33,68.5,29.1z"/>
            <path d="M82.5,3.7c0-1.2,0.4-2.1,1.1-2.9c0.7-0.8,1.8-1.2,3.2-1.2S89.3,0,90,0.7c0.8,0.8,1.1,1.7,1.1,2.9c0,1.1-0.4,2.1-1.1,2.9
c-0.8,0.8-1.8,1.1-3.2,1.1s-2.4-0.4-3.2-1.1C82.9,5.8,82.5,4.8,82.5,3.7z M90.6,46.3H83V12.4h7.6V46.3z"/>
            <path d="M106,12.4l0.2,3.9c2.5-3,5.8-4.5,9.9-4.5c7.1,0,10.6,4,10.8,12.1v22.4h-7.6V24.4c0-2.1-0.5-3.7-1.4-4.8
c-0.9-1-2.4-1.5-4.6-1.5c-3.1,0-5.4,1.4-6.9,4.2v24.1h-7.6V12.4H106z"/>
            <path d="M133.1,29.1c0-3.3,0.7-6.3,2-9s3.2-4.7,5.5-6.1c2.4-1.4,5.1-2.1,8.2-2.1c4.6,0,8.3,1.5,11.1,4.4c2.8,2.9,4.4,6.8,4.6,11.7
l0,1.8c0,3.3-0.6,6.3-1.9,9c-1.3,2.6-3.1,4.7-5.5,6.1c-2.4,1.4-5.1,2.2-8.3,2.2c-4.8,0-8.6-1.6-11.5-4.8c-2.9-3.2-4.3-7.4-4.3-12.7
V29.1z M140.7,29.7c0,3.5,0.7,6.2,2.2,8.2s3.4,3,6,3s4.6-1,6-3c1.4-2,2.1-4.9,2.1-8.8c0-3.4-0.7-6.1-2.2-8.1s-3.5-3-6-3
c-2.5,0-4.5,1-5.9,3S140.7,25.8,140.7,29.7z"/>
            <path d="M178.2,12.4l0.2,3.5c2.4-2.8,5.6-4.2,9.8-4.2c4.5,0,7.6,1.7,9.3,5.2c2.5-3.5,5.9-5.2,10.4-5.2c3.7,0,6.5,1,8.3,3.1
C218,17,219,20,219,24.1v22.3h-7.6v-22c0-2.1-0.5-3.7-1.4-4.7c-0.9-1-2.5-1.5-4.7-1.5c-1.7,0-3.1,0.5-4.2,1.4
c-1.1,0.9-1.9,2.1-2.3,3.6l0,23.2h-7.6V24c-0.1-4-2.1-6-6.1-6c-3,0-5.2,1.2-6.5,3.7v24.5H171V12.4H178.2z"/>
            <path d="M226.8,3.7c0-1.2,0.4-2.1,1.1-2.9c0.7-0.8,1.8-1.2,3.2-1.2s2.4,0.4,3.2,1.2c0.8,0.8,1.1,1.7,1.1,2.9c0,1.1-0.4,2.1-1.1,2.9
c-0.8,0.8-1.8,1.1-3.2,1.1s-2.4-0.4-3.2-1.1C227.2,5.8,226.8,4.8,226.8,3.7z M234.9,46.3h-7.6V12.4h7.6V46.3z"/>
            <path d="M263.4,46.3c-0.3-0.6-0.6-1.7-0.9-3.2c-2.4,2.5-5.4,3.8-8.9,3.8c-3.4,0-6.2-1-8.3-2.9c-2.1-1.9-3.2-4.3-3.2-7.2
c0-3.6,1.3-6.4,4-8.3c2.7-1.9,6.5-2.9,11.5-2.9h4.7v-2.2c0-1.8-0.5-3.2-1.5-4.2c-1-1.1-2.5-1.6-4.5-1.6c-1.7,0-3.2,0.4-4.3,1.3
c-1.1,0.9-1.7,2-1.7,3.3h-7.6c0-1.9,0.6-3.6,1.8-5.2c1.2-1.6,2.9-2.9,5-3.8c2.1-0.9,4.5-1.4,7.1-1.4c4,0,7.1,1,9.5,3
c2.4,2,3.6,4.8,3.6,8.4v15.3c0,3,0.4,5.5,1.3,7.3v0.5H263.4z M255,40.8c1.5,0,2.9-0.4,4.2-1.1c1.3-0.7,2.3-1.7,3-2.9v-6.4h-4.1
c-2.8,0-4.9,0.5-6.4,1.5c-1.4,1-2.1,2.4-2.1,4.2c0,1.5,0.5,2.6,1.5,3.5C252.1,40.4,253.4,40.8,255,40.8z"/>
</g>
          <path class="header-logo__svg-1" d="M38.4,24.6c0,4.5-0.8,8.4-2.4,11.8c-1.6,3.4-3.9,6-6.8,7.8c-3,1.8-6.3,2.8-10.2,2.8c-3.8,0-7.1-0.9-10.1-2.7
c-3-1.8-5.3-4.4-6.9-7.8c-1.6-3.4-2.5-7.2-2.5-11.6v-2.3c0-4.5,0.8-8.4,2.4-11.8c1.6-3.4,3.9-6,6.9-7.9c3-1.8,6.3-2.7,10.1-2.7
c3.8,0,7.2,0.9,10.1,2.7c3,1.8,5.2,4.4,6.9,7.9c1.6,3.4,2.4,7.3,2.4,11.8V24.6z"/>
          <polygon class="header-logo__svg-2"
                   points="19,5.7 24.5,16.8 36.7,18.6 27.9,27.2 29.9,39.4 19,33.6 8.1,39.4 10.2,27.2 1.3,18.6 13.5,16.8 "/>
</svg>
  <svg v-else-if="name === 'white-logo'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 271.6 59.7" style="enable-background:new 0 0 271.6 59.7;" xml:space="preserve">
      <path class="st0-logo" d="M76.5,30.1c0,5.2-1.2,9.4-3.6,12.5s-5.6,4.7-9.6,4.7c-3.7,0-6.7-1.2-8.9-3.7v16.1h-7.6V12.9h7l0.3,3.4
    c2.2-2.7,5.3-4.1,9.1-4.1c4.1,0,7.4,1.5,9.7,4.6c2.3,3.1,3.5,7.3,3.5,12.8L76.5,30.1L76.5,30.1z M68.9,29.5c0-3.4-0.7-6.1-2-8
    c-1.3-2-3.3-3-5.8-3c-3.1,0-5.3,1.3-6.7,3.8v15c1.4,2.6,3.6,3.9,6.8,3.9c2.4,0,4.3-1,5.7-2.9C68.2,36.4,68.9,33.4,68.9,29.5z"/>
      <path class="st0-logo" d="M82.9,4.1c0-1.2,0.4-2.1,1.1-2.9C84.8,0.4,85.9,0,87.2,0s2.4,0.4,3.2,1.2c0.8,0.8,1.1,1.7,1.1,2.9
    c0,1.1-0.4,2.1-1.1,2.9c-0.8,0.8-1.8,1.1-3.2,1.1S84.8,7.7,84,7C83.3,6.2,82.9,5.2,82.9,4.1z M91,46.7h-7.6V12.9H91V46.7z"/>
      <path class="st0-logo" d="M106.4,12.9l0.2,3.9c2.5-3,5.8-4.5,9.9-4.5c7.1,0,10.6,4,10.8,12.1v22.4h-7.6v-22c0-2.1-0.5-3.7-1.4-4.8
    c-0.9-1-2.4-1.5-4.6-1.5c-3.1,0-5.4,1.4-6.9,4.2v24.1h-7.6V12.9H106.4z"/>
      <path class="st0-logo" d="M133.5,29.5c0-3.3,0.7-6.3,2-9s3.2-4.7,5.5-6.1c2.4-1.4,5.1-2.1,8.2-2.1c4.6,0,8.3,1.5,11.1,4.4
    s4.4,6.8,4.6,11.7v1.8c0,3.3-0.6,6.3-1.9,9c-1.3,2.6-3.1,4.7-5.5,6.1c-2.4,1.4-5.1,2.2-8.3,2.2c-4.8,0-8.6-1.6-11.5-4.8
    s-4.3-7.4-4.3-12.7L133.5,29.5L133.5,29.5z M141.1,30.1c0,3.5,0.7,6.2,2.2,8.2s3.4,3,6,3s4.6-1,6-3s2.1-4.9,2.1-8.8
    c0-3.4-0.7-6.1-2.2-8.1s-3.5-3-6-3s-4.5,1-5.9,3S141.1,26.2,141.1,30.1z"/>
      <path class="st0-logo" d="M178.6,12.9l0.2,3.5c2.4-2.8,5.6-4.2,9.8-4.2c4.5,0,7.6,1.7,9.3,5.2c2.5-3.5,5.9-5.2,10.4-5.2
    c3.7,0,6.5,1,8.3,3.1s2.8,5.1,2.8,9.1v22.3h-7.6v-22c0-2.1-0.5-3.7-1.4-4.7s-2.5-1.5-4.7-1.5c-1.7,0-3.1,0.5-4.2,1.4
    c-1.1,0.9-1.9,2.1-2.3,3.6v23.2h-7.6V24.4c-0.1-4-2.1-6-6.1-6c-3,0-5.2,1.2-6.5,3.7v24.5h-7.6V12.9H178.6z"/>
      <path class="st0-logo" d="M227.2,4.1c0-1.2,0.4-2.1,1.1-2.9c0.7-0.8,1.8-1.2,3.2-1.2s2.4,0.4,3.2,1.2s1.1,1.7,1.1,2.9
    c0,1.1-0.4,2.1-1.1,2.9c-0.8,0.8-1.8,1.1-3.2,1.1s-2.4-0.4-3.2-1.1C227.6,6.2,227.2,5.2,227.2,4.1z M235.3,46.7h-7.6V12.9h7.6V46.7z
    "/>
      <path class="st0-logo" d="M263.8,46.7c-0.3-0.6-0.6-1.7-0.9-3.2c-2.4,2.5-5.4,3.8-8.9,3.8c-3.4,0-6.2-1-8.3-2.9s-3.2-4.3-3.2-7.2
    c0-3.6,1.3-6.4,4-8.3C249.2,27,253,26,258,26h4.7v-2.2c0-1.8-0.5-3.2-1.5-4.2c-1-1.1-2.5-1.6-4.5-1.6c-1.7,0-3.2,0.4-4.3,1.3
    s-1.7,2-1.7,3.3h-7.6c0-1.9,0.6-3.6,1.8-5.2c1.2-1.6,2.9-2.9,5-3.8c2.1-0.9,4.5-1.4,7.1-1.4c4,0,7.1,1,9.5,3s3.6,4.8,3.6,8.4v15.3
    c0,3,0.4,5.5,1.3,7.3v0.5C271.4,46.7,263.8,46.7,263.8,46.7z M255.4,41.2c1.5,0,2.9-0.4,4.2-1.1c1.3-0.7,2.3-1.7,3-2.9v-6.4h-4.1
    c-2.8,0-4.9,0.5-6.4,1.5c-1.4,1-2.1,2.4-2.1,4.2c0,1.5,0.5,2.6,1.5,3.5C252.5,40.8,253.8,41.2,255.4,41.2z"/>
      <path class="st1-logo" d="M36.4,11.2c-1.7-3.5-3.9-6.1-6.9-7.9c-2.9-1.8-6.3-2.7-10.1-2.7c-3.8,0-7.1,0.9-10.1,2.7
    c-3,1.9-5.3,4.5-6.9,7.9C0.8,14.6,0,18.5,0,23v2.2c0,4.4,0.8,8.3,2.4,11.7c1.6,3.4,3.9,6,6.9,7.8s6.3,2.7,10.1,2.7
    c3.9,0,7.2-1,10.2-2.8c2.9-1.8,5.2-4.4,6.8-7.8c1.6-3.4,2.4-7.3,2.4-11.8v-2C38.8,18.5,38,14.6,36.4,11.2z M28.2,27.6l2.1,12.2
    L19.4,34L8.5,39.8l2.1-12.2L1.7,19l12.2-1.8l5.5-11.1l5.5,11.1L37.1,19L28.2,27.6z"/>
  </svg>
  <svg v-else-if="name === 'close'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" width="12.7px" height="12.7px" viewBox="0 0 12.7 12.7"
               style="enable-background:new 0 0 12.7 12.7;" xml:space="preserve" class="popup-top__close">
  <g>
  <line x1="0.4" y1="0.4" x2="12.4" y2="12.4"/>
    <line x1="0.4" y1="12.4" x2="12.4" y2="0.4"/>
  </g>
  </svg>
  <svg v-else-if="name === 'trust_1'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="56px" height="66px"
                   viewBox="0 0 56 66" style="enable-background:new 0 0 56 66;" xml:space="preserve">
                <g>
<path class="st0" d="M1.5,33C1.5,18.4,13.4,6.5,28,6.5c9.1,0,17.2,4.6,22,11.6"/>
                  <polyline class="st0" points="50.1,6.6 50.1,18 38.7,18"/>
                  <path class="st0" d="M54.5,33c0,14.6-11.9,26.5-26.5,26.5c-9.1,0-17.2-4.6-22-11.6"/>
                  <polyline class="st0" points="5.9,59.4 5.9,48 17.3,48"/>
</g>
</svg>
  <svg v-else-if="name === 'trust_2'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="74.8px" height="66px"
                   viewBox="0 0 74.8 66" style="enable-background:new 0 0 74.8 66;" xml:space="preserve">
                <g>
<path class="st0" d="M27.2,15c0,0-17.7,6.1-17.7,20c0,5.7,2.6,10.4,6.6,14.1L23.8,63h7.7v-6.5c2.4,0.5,4.9,0.8,7.3,0.8
c2.3,0,4.8-0.3,7.3-0.8V63h7.7l5.8-10.6c7-2.8,12.6-5.9,13.8-6.7V38c0,0-3.8-2.3-5.4-4.6c0,0-0.8-5.2-5-10.2"/>
                  <path class="st0" d="M55.4,17.3c-1.4-0.7-3.1-1.4-4.8-2"/>
                  <circle class="st0" cx="38.7" cy="9.6" r="6.5"/>
                  <line class="st0" x1="32.2" y1="22.2" x2="45.3" y2="22.2"/>
                  <path class="st0" d="M9.2,35.3c-4.2,0-7.7-3.4-7.7-7.7"/>
                  <path class="st0" d="M55.4,17.3c1.6-1.7,3.9-2.7,6.4-2.7c2.7,0,5.1,1.2,6.7,3.1l-9.7,9.7"/>
                  <line class="st0" x1="59.9" y1="35.3" x2="59.7" y2="35.3"/>
</g>
</svg>
  <svg v-else-if="name === 'trust_3'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg"
                   xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="55px" height="66px"
                   viewBox="0 0 55 66" style="enable-background:new 0 0 55 66;" xml:space="preserve">
                <g>
<path class="st0" d="M27.5,1.7c-6.8,10.2-26,10.2-26,10.2v17.8c0,27.8,26,34.6,26,34.6s26-6.8,26-34.6V11.9
C53.5,11.9,34.3,11.9,27.5,1.7z"/>
                  <circle class="st0" cx="27.5" cy="33.9" r="14.2"/>
                  <polyline class="st0" points="21,35.3 24.5,38.8 33.8,29.5"/>
</g>
</svg>
  <svg v-else-if="name === 'heart'" version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 x="0px" y="0px" width="36.1px" height="32.3px" viewBox="0 0 36.1 32.3"
                 style="enable-background:new 0 0 36.1 32.3;" xml:space="preserve">
              <path class="st0" d="M34.6,10.2C34.6,10.2,34.6,10.2,34.6,10.2L34.6,10.2c-0.1-4.9-4-8.7-8.8-8.7c-3.4,0-6.3,1.9-7.8,4.7
c-1.5-2.8-4.4-4.7-7.8-4.7c-4.8,0-8.7,3.8-8.8,8.6h0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,1.6,0.5,3.2,1.2,4.5
c3.8,7.9,15.3,16,15.3,16s11.5-8.1,15.3-16C34.2,13.5,34.6,11.9,34.6,10.2C34.6,10.3,34.6,10.2,34.6,10.2z"/>
</svg>
  <svg v-else-if="name === 'facebook'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" width="8.3px" height="18px" viewBox="0 0 8.3 18"
               style="enable-background:new 0 0 8.3 18;" xml:space="preserve">
<path d="M8,9H5.3v9H1.8V9H0V5.4h1.6V3.8C1.6,2.3,2.3,0,5.4,0h2.8v3.1h-2C6,3.1,5.5,3.3,5.5,4v1.4h2.8L8,9z"/>
</svg>
  <svg v-else-if="name === 'instagram'" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink"
               x="0px" y="0px" width="18px" height="18px" viewBox="0 0 18 18" style="enable-background:new 0 0 18 18;"
               xml:space="preserve">
<path d="M3.4,0C1.5,0,0,1.5,0,3.4v3.7v7.4C0,16.5,1.5,18,3.4,18h11.1c1.9,0,3.4-1.5,3.4-3.4V7.1h0V3.4C18,1.5,16.5,0,14.6,0H3.4z
M6.4,7.1C7,6.3,7.9,5.8,9,5.8s2,0.5,2.6,1.3C12,7.7,12.2,8.3,12.2,9c0,1.8-1.4,3.2-3.2,3.2S5.8,10.8,5.8,9C5.8,8.3,6,7.7,6.4,7.1z
M16.4,14.6c0,1-0.9,1.8-1.8,1.8H3.4c-1,0-1.8-0.9-1.8-1.8V7.2h2.8C4.2,7.6,4,8.3,4,9c0,2.7,2.2,5,5,5c2.7,0,5-2.2,5-5
c0-0.6-0.1-1.4-0.4-1.8h2.8V14.6z M15.9,2v0.4v2.7h-3V2h2.7L15.9,2z"/>
</svg>
  <svg v-else-if="name === 'star'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
       y="0px" width="18.7px" height="17.8px" viewBox="0 0 18.7 17.8" style="enable-background:new 0 0 18.7 17.8;" xml:space="preserve">
    <path class="star__svg" d="M10,0.5l2,5c0.1,0.3,0.4,0.4,0.6,0.5L18,6.2c0.7,0,0.9,0.9,0.4,1.3L14.3,11c-0.2,0.2-0.3,0.5-0.2,0.7l1.3,5.2
    c0.2,0.6-0.5,1.2-1.1,0.8l-4.5-2.9c-0.2-0.2-0.5-0.2-0.8,0l-4.5,2.9c-0.6,0.4-1.3-0.2-1.1-0.8l1.3-5.2c0.1-0.3,0-0.6-0.2-0.7
    L0.3,7.5C-0.2,7.1,0,6.3,0.7,6.2L6,5.9c0.3,0,0.5-0.2,0.6-0.5l2-5C8.9-0.2,9.8-0.2,10,0.5z"/>
  </svg>
  <svg v-else-if="name === 'close-menu'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="14.5px"
    height="14.5px" viewBox="0 0 14.5 14.5" style="enable-background:new 0 0 14.5 14.5;" xml:space="preserve" class="filters-popup__header-close">
    <g>
      <line class="close-popup-icon" x1="1" y1="1" x2="13.5" y2="13.5"/>
      <line class="close-popup-icon" x1="13.5" y1="1" x2="1" y2="13.5"/>
    </g>
  </svg>
  <svg v-else-if="name === 'empty-star'" class="filters-popup__filters-item__rate-star" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="38px" height="36.2px" viewBox="0 0 38 36.2" xml:space="preserve">
    <polygon class="rate-star__svg-stroke" points="19,1 23.4,13.8 37,14.1 26.2,22.3 30.1,35.2 19,27.5 7.9,35.2 11.8,22.3 1,14.1 14.6,13.8 "/>
  </svg>
  <svg v-else-if="name === 'filled-star'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18.7px" height="17.8px" viewBox="0 0 18.7 17.8" xml:space="preserve">
    <path class="star__svg" d="M10,0.5l2,5c0.1,0.3,0.4,0.4,0.6,0.5L18,6.2c0.7,0,0.9,0.9,0.4,1.3L14.3,11c-0.2,0.2-0.3,0.5-0.2,0.7l1.3,5.2
    c0.2,0.6-0.5,1.2-1.1,0.8l-4.5-2.9c-0.2-0.2-0.5-0.2-0.8,0l-4.5,2.9c-0.6,0.4-1.3-0.2-1.1-0.8l1.3-5.2c0.1-0.3,0-0.6-0.2-0.7
    L0.3,7.5C-0.2,7.1,0,6.3,0.7,6.2L6,5.9c0.3,0,0.5-0.2,0.6-0.5l2-5C8.9-0.2,9.8-0.2,10,0.5z"/>
  </svg>
  <svg v-else-if="name === 'arrow-down'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="6.25px" height="3.4px" viewBox="0 0 6.25 3.4" xml:space="preserve">
    <polyline class="header-arrow" points="0.32,0.38 3.13,2.75 5.93,0.38 "/>
  </svg>
</template>

<script>
export default {
  name: 'Isvg',
  props: ['name']
}
</script>

<style lang="scss">
  .filter-block__item-popup__filters-item__rate-star,
  .filters-popup__filters-item__rate-star {
    height: 26px;
    width: 28px;
    cursor: pointer;
    stroke: #5BDAA4;
      stroke-width: 2;
      margin-right: 5px;
    .rate-star__svg-stroke {
      fill: #fff;
      transition: .2s;
    }
    &.active {
      .rate-star__svg-stroke {
        fill: #5BDAA4;
      }
    }
  }
  .filters-popup__filters-item__rate-star {
    height: 30px;
    width: 32px;
  }
  .review-block__item-stars__item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    width: 20%;
    cursor: pointer;
    &.active {
      svg {
        .rate-star__svg-stroke {
          fill: #5BDAA4;
        }
      }
    }
  }
  .header-arrow{fill:none;stroke-miterlimit:10;}
  .st0-logo{fill:#FFFFFF;}
  .st1-logo{fill:#5BDAA4;}
</style>
