<template>
  <div class="popup-wrap active">
    <div class="popup popup-language active">
      <!-- top -->
      <div class="popup-top">
        <h2>{{translation.country_popup_header}}</h2>
        <p>{{translation.country_popup_subheader[0]}}{{countryName}}{{translation.country_popup_subheader[1]}}</p>
        <Isvg @click.native="close" name="close"/>
      </div>
      <!-- body -->
      <div class="popup-body">
        <button v-for="(value, key, index) in multiCountry"
                :key="index"
                class="popup-body__language"
                @click="countryCodeNameChanged = key"
                :class="{active: key === countryCodeNameChanged}">
          <img :src="require(`@/assets/img/lang-${key}.png`)" alt="">
          <span>{{value.countryName}}</span>
        </button>
        <button @click="setLocale">Continuar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'

export default {
  name: 'LocaleSelector',
  components: {
    Isvg
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      multiCountry: state => state.multiCountry,
      countryName: state => state.countryName,
      countryCodeName: state => state.countryCodeName
    })
  },
  data () {
    return {
      countryCodeNameChanged: 'es'
    }
  },
  methods: {
    close () {
      this.$store.commit('SHOW_LOCALE_SELECTOR', false)
    },
    setLocale () {
      this.$store.dispatch('setLocale', this.countryCodeNameChanged)
      localStorage.setItem('alreadySetLocale', this.countryCodeNameChanged)
      this.$store.commit('SHOW_LOCALE_SELECTOR', false)
    }
  }
}
</script>

<style scoped>

</style>
