/* eslint-disable prefer-promise-reject-errors */
/**
 * Created by Stanislav Gulevich on 12.01.2020.
 */
import Vue from 'vue'
// import moment from 'moment'
import axios from 'axios'
import router from '../router'
import Fingerprint2 from 'fingerprintjs2'

// show preloader
export const googleAuth = function ({ commit, dispatch }) {
  Vue.googleAuth().signIn(function (authorizationCode) {
    dispatch('providerAuth', { provider: 'google', token: authorizationCode })
  }, function (error) {
    dispatch('handleError', error)
  })
}
export const facebookAuth = function ({ commit, dispatch }) {
  window.FB.login(function (response) {
    if (response.authResponse) {
      dispatch('providerAuth', { provider: 'facebook', token: response.authResponse.accessToken })
    } else {
      console.error('User cancelled login or did not fully authorize.')
    }
  }, { scope: 'email' })
}
export const emailAuth = function ({ state, commit, dispatch }, params) {
  commit('SHOW_LOADER', true)
  for (const j in state.additionalParams) {
    params[j] = state.additionalParams[j]
  }
  params.provider_id = state.provider
  params.country_code = state.countryCodeName
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/api/login',
      data: params,
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      commit('SHOW_LOGIN', false)
      commit('SHOW_REGISTER', false)
      commit('CHANGE_AUTH_STATE', {
        isAuthorised: true,
        loginProvider: 'email',
        data: response.data.data
      })
      dispatch('updateOffersInfo')
      if (state.targetPage) router.push(state.targetPage)
      dispatch('pushGTMEvent', 'auth')
      commit('SHOW_LOADER', false)
      Vue.$toast.success(state.translation.success, { position: 'top' })
      resolve(response)
    }, (error) => {
      dispatch('handleError', error)
      reject(error)
    })
  })
}

export const register = function ({ state, commit, dispatch }, params) {
  commit('SHOW_LOADER', true)
  for (const j in state.additionalParams) {
    params[j] = state.additionalParams[j]
  }
  params.provider_id = state.provider
  params.country_code = state.countryCodeName
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      url: '/api/registration',
      data: params,
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      commit('SHOW_LOGIN', false)
      commit('SHOW_REGISTER', false)
      commit('CHANGE_AUTH_STATE', {
        isAuthorised: true,
        loginProvider: 'email',
        data: response.data.data
      })
      dispatch('updateOffersInfo')
      if (state.targetPage) router.push(state.targetPage)
      dispatch('pushGTMEvent', 'register')
      commit('SHOW_LOADER', false)
      Vue.$toast.success(state.translation.register_success, { position: 'top' })
      resolve(response)
    }, (error) => {
      dispatch('handleError', error)
      reject(error)
    })
  })
}
export const providerAuth = function ({ state, commit, dispatch }, params) {
  commit('SHOW_LOADER', true)
  if (!params.token) {
    return
  }
  for (const j in state.additionalParams) {
    params[j] = state.additionalParams[j]
  }
  params.provider_id = state.provider
  params.country_code = state.countryCodeName
  axios({
    method: 'POST',
    url: '/api/provider_login',
    data: params,
    headers: { 'Content-Type': 'application/json' }
  }).then((response) => {
    commit('SHOW_LOGIN', false)
    commit('SHOW_REGISTER', false)
    commit('CHANGE_AUTH_STATE', {
      isAuthorised: true,
      loginProvider: params.provider,
      data: response.data.data
    })
    dispatch('updateOffersInfo')
    if (state.targetPage) router.push(state.targetPage)
    dispatch('pushGTMEvent', response.data.data.user_data.new_user ? 'register' : 'auth')
    Vue.$toast.success(state.translation.success, { position: 'top' })
    commit('SHOW_LOADER', false)
  }, (error) => {
    dispatch('handleError', error)
  })
}
export const logout = function ({ state, commit }) {
  commit('SHOW_LOADER', true)
  commit('CHANGE_AUTH_STATE', {
    isAuthorised: false,
    loginProvider: null,
    data: {
      token: null,
      user_data: {
        email: null,
        image: null,
        lead_id: null,
        name: null
      }
    }
  })
  router.replace('/', success => {}, error => { console.log(error) })
  setTimeout(x => {
    commit('SHOW_LOADER', false)
    Vue.$toast.success(state.translation.success, { position: 'top' })
  }, 1000)
}
export const getOffers = function ({ state, commit }) {
  // commit('SHOW_LOADER', true)
  axios({
    method: 'GET',
    url: `/api/offers?country_code=${state.countryCodeName}`,
    headers: { 'Content-Type': 'application/json' }
  }).then((response) => {
    // commit('SHOW_LOADER', false)
    // const offers = Object.values(response.data.data).sort(function (a, b) {
    //   return b.average_rating - a.average_rating
    // })
    commit('OFFERS_LIST', response.data.data)
  })
}

export const reviewLender = function ({ state, commit }, val) {
  commit('REVIEW_LENDER', val)
  // state.review_lender = { ...state.review_lender }
}

export const updateCustomer = function ({ state, commit, dispatch }, params) {
  commit('SHOW_LOADER', true)
  return new Promise((resolve, reject) => {
    axios({
      method: 'PATCH',
      url: '/api/client',
      data: { user_data: params },
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + state.JWToken }
    }).then((response) => {
      commit('SHOW_LOADER', false)
      commit('CHANGE_AUTH_STATE', {
        isAuthorised: true,
        loginProvider: state.loginProvider,
        data: response.data.data
      })
      dispatch('updateOffersInfo')
      Vue.$toast.success(state.translation.success, { position: 'top' })
      resolve(response)
    }, (error) => {
      dispatch('handleError', error)
      reject(error)
    })
  })
}

export const getReviews = function ({ state, commit }, code) {
  commit('SHOW_LOADER', true)
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: `/api/review/${state.review_lender.code}`,
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      commit('SHOW_LOADER', false)
      commit('REVIEWS_LIST', response.data.data)
      resolve(response)
    }, (response) => {
      commit('SHOW_LOADER', false)
      reject(response)
    })
  })
}

export const getFingerprint = function ({ commit, state, getters, dispatch }) {
  const fingerprintReady = (result) => {
    localStorage.setItem('fingerprint', result)
    commit('CHANGE_ADDITIONAL_PARAMS', { fingerprint: result })
  }
  if (window.requestIdleCallback) {
    requestIdleCallback(function () {
      Fingerprint2.getV18({}, function (result, components) {
        fingerprintReady(result)
      })
    })
  } else {
    setTimeout(function () {
      Fingerprint2.getV18({}, function (result, components) {
        fingerprintReady(result)
      })
    }, 500)
  }
}

export const updateOffersInfo = function ({ commit, state, getters, dispatch }) {
  console.log('updateOffersInfo', state.offers_info)
  const val = state.offers_info
  for (const i in val) {
    let link = val[i].link
    const oldSub1 = getters.getParameterByName('sub1', link)
    const oldSub2 = getters.getParameterByName('sub2', link)
    const newSub1 = state.leadId || state.default_lead
    let newSub2 = state.additionalParams.utm_campaign
    if (!newSub2 || newSub2 === '(none)') newSub2 = getters.getParameterByName('sub2') || 'opinomia'
    link = link.replace(oldSub1, newSub1)
    link = link.replace(oldSub2, newSub2)
    if (val[i].name === 'crezu' || val[i].name === 'pezetita') {
      let domain = ''
      if (val[i].name === 'crezu') domain = 'crezu.es'
      if (val[i].name === 'pezetita') domain = 'pezetita.com'
      link = `https://${domain}/?utm_source=${state.additionalParams.utm_source}&utm_medium=${state.additionalParams.utm_medium}&utm_campaign=${newSub2}&utm_term=${state.additionalParams.utm_term}&utm_content=${state.additionalParams.utm_content}`
    }
    Vue.set(val[i], 'link', link)
  }
  commit('UPDATE_OFFERS_INFO', val)
}

export const scrollTo = function ({ commit, state, getters, dispatch }, el) {
  document.querySelector(el).scrollIntoView({
    behavior: 'smooth'
  })
}

export const handleError = function ({ commit, state, getters, dispatch }, error) {
  let errorForUser
  try {
    const errorData = error.response.data
    if (errorData.errors) {
      errorForUser = errorData.errors[0]
    } else if (errorData.error) {
      if (errorData.error.fields) {
        const flds = errorData.error.fields
        const key = Object.keys(flds)[0]
        errorForUser = key + ': ' + flds[key][0]
      } else {
        errorForUser = errorData.error.message
      }
    } else {
      errorForUser = errorData
    }
  } catch (e) {
    console.log(e, 'error in parsing error')
  }
  if (state.translation.errors_translate) {
    errorForUser = state.translation.errors_translate[errorForUser] || state.translation.error
  }
  commit('SHOW_LOADER', false)
  Vue.$toast.error(errorForUser, { position: 'top' })
}

export const pushGTMEvent = function ({ commit, state, getters, dispatch }, evt) {
  Vue.gtm.trackEvent({
    event: evt
  })
}

export const pushGTMEventWithParams = function ({ commit, state, getters, dispatch }, params) {
  Vue.gtm.trackEvent(params)
}

export const pushFBEvent = function ({ commit, state, getters, dispatch }, evt) {
  // eslint-disable-next-line no-undef
  if (window.fbq) fbq(evt.track, evt.event, evt.data)
}

export const pushGTMWithName = function ({ commit, state, getters, dispatch }, name) {
  if (name === 'crezu') dispatch('pushGTMEvent', 'crezuClick')
  if (name === 'solcredito') dispatch('pushGTMEvent', 'solcClick')
  dispatch('pushGTMEvent', 'offerClick')
}

export const initFCM = function ({ commit, state, getters, dispatch }, name) {
  // firebase register token
  window.back_fcm = 'https://crezu.net:8002'
  window.globalConfig = window.globalConfig || {}
  window.globalConfig.country = state.countryCode
  window.globalConfig.provider = state.provider
  window.globalConfig.customText = state.translation.fcm_request
  const fcm = document.createElement('script')
  fcm.setAttribute('src', `https://cdn.crezu.net/landings/js/get_token_with_custom_prompt.js?rand=${+new Date()}`)
  document.head.appendChild(fcm)
}

export const productLinking = function ({ state, commit }, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'POST',
      data: params,
      url: '/api/event',
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      resolve(response)
    }, (response) => {
      reject(response)
    })
  })
}

export const getLocale = function ({ state, commit }, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url: 'https://workers.crezu.net/geoip/',
      headers: { 'Content-Type': 'application/json' }
    }).then((response) => {
      if (response.data.success) {
        resolve(response.data.iso_code.toLowerCase())
      } else {
        reject(response)
      }
    }, (response) => {
      reject(response)
    })
  })
}

export const setLocale = function ({ commit, state, getters, dispatch }, locale) {
  console.log('SET LOCALE ACTION:', locale)
  if (Object.keys(state.multiCountry).indexOf(locale) === -1) {
    console.log('UNSUPPORTED LOCALE, SHOW POPUP')
    locale = 'es'
    const localeFromLS = localStorage.getItem('alreadySetLocale')
    if (!localeFromLS) {
      commit('SHOW_LOCALE_SELECTOR', true)
    } else {
      locale = localeFromLS
    }
  }
  commit('SET_LOCALE', locale)
  commit('SET_TRANSLATION')
  const oldLocale = getters.extractLocale()
  if (locale !== oldLocale) {
    if (Object.keys(state.multiCountry).indexOf(oldLocale) > -1) {
      // change locale from valid one
      window.location.href = `/${locale}${router.currentRoute.fullPath.slice(3)}`
    } else {
      // without locale or wrong locale
      router.push({ path: `/${locale}${router.currentRoute.path}` })
    }
  }
}
