<template>
  <div v-if="showForm">
    <!-- main-block -->
    <section id="main-block">
      <div class="container">
        <h1 class="">{{translation.registration_form_header}}</h1>
      </div>
    </section>
    <!-- offers-block ends -->
    <!-- register-block -->
    <section id="register-block">
      <div class="container">
        <form @submit="submitForm($event)">
          <!-- Birdthday -->
          <input v-model="fields.birthday.val"
                 type="tel"
                 inputmode="number"
                 :class="{error: !fields.birthday.isValid}"
                 @change="changeHandler"
                 :placeholder="fields_info['birthday'].label"
                 id="birthday-input">
          <!-- Sex -->
          <div class="register-block__radio-wrap">
            <div :class="{'error-round': !fields.gender.isValid}" class="register-block__radio big-ratio" id="gender">
              <div v-for="(value, index) in fields_info['gender'].values"
                   :key="index"
                   :class="{active: fields.gender.val === value.value}"
                   @click="fields.gender.val = value.value"
                   class="register-block__radio-item">
                <span>{{value.label}}</span>
              </div>
            </div>
          </div>
          <!-- Education -->
          <opi-select v-model="fields.education.val"
                      :header_class="{error: !fields.education.isValid}"
                      :options="fields_info['education'].translate"
                      :placeholder="fields_info['education'].placeholder"></opi-select>
          <!-- Marital status -->
          <opi-select v-model="fields.marital_status.val"
                      :header_class="{error: !fields.marital_status.isValid}"
                      :options="fields_info['marital_status'].translate"
                      :placeholder="fields_info['marital_status'].placeholder"></opi-select>
          <!-- Living status -->
          <opi-select v-model="fields.living_status.val"
                      :header_class="{error: !fields.living_status.isValid}"
                      :options="fields_info['living_status'].translate"
                      :placeholder="fields_info['living_status'].placeholder"></opi-select>
          <!-- City -->
          <input v-model="fields.city.val" :class="{error: !fields.city.isValid}" type="text" :placeholder="fields_info['city'].placeholder">
          <!-- Income source -->
          <opi-select v-model="fields.income_source.val"
                      :header_class="{error: !fields.income_source.isValid}"
                      :options="fields_info['income_source'].translate"
                      :placeholder="fields_info['income_source'].placeholder"></opi-select>
          <!-- Income amount -->
          <input v-model="fields.income_amount.val" :class="{error: !fields.income_amount.isValid}" type="text" :placeholder="fields_info['income_amount'].placeholder">
          <!-- ASNEF -->
          <div class="register-block__radio-wrap small-radio">
            <p>{{fields_info['asnef'].label}}</p>
            <div :class="{'error-round': !fields.asnef.isValid}" class="register-block__radio" id="asnef">
              <div v-for="(value, index) in fields_info['asnef'].values"
                   :key="index"
                   :class="{active: fields.asnef.val === value.value}"
                   @click="fields.asnef.val = value.value"
                   class="register-block__radio-item">
                <span>{{value.label}}</span>
              </div>
            </div>
          </div>
          <!-- Banca online -->
          <div class="register-block__radio-wrap small-radio">
            <p>{{fields_info['has_bank_online'].label}}</p>
            <div :class="{'error-round': !fields.has_bank_online.isValid}" class="register-block__radio" id="bank">
              <div v-for="(value, index) in fields_info['has_bank_online'].values"
                   :key="index"
                   :class="{active: fields.has_bank_online.val === value.value}"
                   @click="fields.has_bank_online.val = value.value"
                   class="register-block__radio-item">
                <span>{{value.label}}</span>
              </div>
            </div>
          </div>
          <label class="input-checkbox">
            <input type="checkbox" v-model="fields.is_term_accepted.val">
            <span class="input-checkbox__checkbox" :class="{error: !fields.is_term_accepted.isValid}"></span>
            <p v-html="translation.privacy_checkbox"></p>
          </label>

          <label class="input-checkbox">
            <input type="checkbox" v-model="fields.is_marketing_accepted.val">
            <span class="input-checkbox__checkbox" :class="{error: !fields.is_marketing_accepted.isValid}"></span>
            <p>{{translation.marketing_checkbox}}</p>
          </label>
          <button @click="submitForm($event)">{{translation.registration_form_button}}</button>
        </form>
      </div>
    </section>
    <!-- register-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Inputmask from 'inputmask'
import OpiSelect from '../assetComponents/OpiSelect'
import Vue from 'vue'
import axios from 'axios'

export default {
  name: 'Register',
  components: {
    OpiSelect
  },
  data () {
    return {
      fields: {
        birthday: { val: '', isValid: true },
        gender: { val: '', isValid: true },
        education: { val: '', isValid: true },
        marital_status: { val: '', isValid: true },
        living_status: { val: '', isValid: true },
        city: { val: '', isValid: true },
        income_source: { val: '', isValid: true },
        income_amount: { val: '', isValid: true },
        asnef: { val: '', isValid: true },
        has_bank_online: { val: '', isValid: true },
        is_term_accepted: { val: false, isValid: true },
        is_marketing_accepted: { val: false, isValid: true }
      },
      trySubmit: false,
      showForm: false
    }
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      translation: state => state.translation,
      review_lender: state => state.review_lender,
      fields_info: state => state.fields_info,
      JWToken: state => state.JWToken,
      isAuthorised: state => state.isAuthorised
    })
  },
  beforeCreate () {
    this.$store.commit('SHOW_LOADER', true)
    const initInterval = setInterval(x => {
      if (Object.keys(this.fields_info)) {
        this.$store.commit('SHOW_LOADER', false)
        clearInterval(initInterval)
        this.showForm = true
        this.initRegister()
      }
    }, 500)
  },
  mounted () {
    if (!this.isAuthorised) {
      this.$router.go(-1)
      this.$store.commit('SHOW_LOGIN', true)
    }
  },
  methods: {
    initRegister () {
      const im = new Inputmask('99/99/9999')
      Vue.nextTick(function () { im.mask(document.getElementById('birthday-input')) })
    },
    submitForm (e) {
      e.preventDefault()
      this.trySubmit = true
      if (!this.validator()) { return }
      const temp = {}
      for (const field in this.fields) {
        const val = this.fields[field].val
        if (field === 'has_bank_online') {
          console.log('need to add has_bank_online to backend')
        } else if (field === 'birthday') {
          temp[field] = val.split('/').reverse().join('-')
        } else if (typeof val === 'boolean') {
          temp[field] = +val
        } else {
          temp[field] = val
        }
      }
      axios({
        method: 'PATCH',
        url: '/api/client',
        data: { user_data: temp },
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        console.log('success', response)
        Vue.$toast.success(this.translation.success, { position: 'top' })
        this.$router.push('/cabinet/credit')
      }, (response) => {
        console.log('error', response)
        Vue.$toast.error(this.translation.error, { position: 'top' })
      })
    },
    changeHandler (e) {
      this.fields.birthday.val = e.target.value
    },
    validator () {
      console.log(this.fields)
      let isSuccess = true
      for (const field in this.fields) {
        const reg = this.fields_info[field] ? this.fields_info[field].reg : 'true'
        const val = this.fields[field].val
        let isValid = (val || val === 0) ? new RegExp(reg).test(val) : false
        if (field === 'is_marketing_accepted') isValid = true
        if (!isValid) isSuccess = false
        this.fields[field].isValid = isValid
      }
      return isSuccess
    }
  },
  watch: {
    fields: {
      handler: function () {
        if (!this.trySubmit) return
        this.validator()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/sass/register.scss";
</style>
