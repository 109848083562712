<template>
  <div class="select"
       :tabindex="tabindex"
       @blur="open = false"
       :class="{active: open}"
       @click="open = true">
    <div class="select-header" :class="header_class">
      <span class="select-header__amount" :class="{placeholder: !selected, 'text-large': textLarge}">{{selectedVar ||
        placeholder}}</span>
      <div class="select-header__icon"></div>
    </div>
    <div class="select-body">
      <div v-for="(option, i) of formattedOptions"
        :key="i"
        @click="selectOption(option, i)"
        :class="{active: selected === option, 'text-large': textLarge}"
        class="select-body__item">{{ i }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OpiSelect',
  props: {
    options: {
      type: [Object, Array],
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0
    },
    header_class: {
      type: Object,
      required: false
    },
    textLarge: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      selected: null,
      open: false,
      selectedVar: null
    }
  },
  computed: {
    formattedOptions () {
      if (Object.keys(this.options)[0] !== '0') {
        return this.options
      } else {
        const temp = {}
        this.options.map(x => { temp[x] = x })
        return temp
      }
    }
  },
  mounted () {
    this.$emit('input', this.selected)
  },
  methods: {
    selectOption (option, selectedVar) {
      console.log('select:', option)
      this.selected = option
      this.selectedVar = selectedVar
      setTimeout(x => { this.open = false }, 100)
      this.$emit('input', option)
    }
  }
}
</script>

<style scoped>
  .text-large {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
  }
</style>
