<template>
  <div style="padding-top: 70px">
    <!-- card-block -->
    <section id="review-block" class="first-section">
      <div class="container">
        <div class="breadcrumbs">
          <router-link to="/offers">{{translation.prestamos_link}}</router-link>
          <span> / </span>
          <span>{{review_lender ? review_lender.name : ''}}</span>
        </div>
        <h1>{{translation.review_header}}</h1>
        <ReviewComponent/>
      </div>
    </section>
    <!-- card-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ReviewComponent from './cabinet/Review'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Review',
  components: {
    ReviewComponent
  },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      translation: state => state.translation,
      review_lender: state => state.review_lender
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  #main-block {
    margin-top: 80px;
  }
</style>
