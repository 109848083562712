<template>
  <div class="content-item active" id="page1" v-if="showInterface">
    <div class="wrap" v-if="!score">
      <div class="content-item__left">
        <h2>{{translation.credit_score_header}}</h2>
        <p class="content-item__left__p">{{translation.credit_score_subheader}}</p>
        <router-link to="/register"><button>{{translation.credit_score_cta}}</button></router-link>
      </div>
      <div class="content-item__right">
        <img src="../../../assets/img/score-question.png" alt="" class="content-item__img">
      </div>
    </div>
    <div class="wrap" v-if="score">
      <div class="content-item__left">
        <h2>{{translation.credit_score_header_2}} {{name}}!</h2>
        <p class="content-item__left__p">{{translation.credit_score_subheader_2[0]}}
           {{score}} {{translation.credit_score_subheader_2[1]}} 70 {{translation.credit_score_subheader_2[2]}}</p>
      </div>
      <div class="content-item__right">
        <div class="content-item__rainbow">
          <img src="../../../assets/img/rainbow-dot.svg" alt="" class="content-item__rainbow-dot" :style="{ transform: 'rotate(' + score / 1000 * 180 + 'deg)'}">
          <img src="../../../assets/img/rainbow.svg" alt="" class="content-item__rainbow-img">
          <span class="content-item__rainbox-span min">0</span>
          <span class="content-item__rainbox-span max">1000</span>
          <p class="content-item__rainbox-number">{{score}}</p>
        </div>
      </div>
    </div>
    <div class="wrap" style="min-height: 90px; margin-top: 40px">
    <!-- LINK ADS -->
      <div class="feed-ads" style="padding: 0px; overflow: inherit !important;">
        <!-- Opinomia - offers -->
        <ins class="adsbygoogle feed-ads-ins"
             style="display:block"
             data-ad-client="ca-pub-6373705936907274"
             data-ad-slot="4339222842"
             data-ad-format="link"
             data-full-width-responsive="true">
        </ins>
      </div>
    </div>
    <div class="content-item__offers-wrap" v-if="score">
      <div style="width:100%; text-align: center; margin-top: 70px">
        <p class="content-item__left__p"><b>{{translation.credit_score_subheader_3}}</b></p>
      </div>
      <TopOffers/>
    </div>
    <div class="wrap" style="min-height: 90px">
      <!-- AUTO ADS -->
      <div class="feed-ads"
           style="padding: 0px; overflow: inherit !important;">
        <ins class="adsbygoogle feed-ads-ins"
             style="display:block"
             data-ad-client="ca-pub-6373705936907274"
             data-ad-slot="6773814492"
             data-ad-format="auto"
             data-full-width-responsive="true">
        </ins>
      </div>
    </div>
    <p class="content-item__left__p" data-v-b816f22a="" style="margin-bottom: -60px; margin-top: 50px; font-size: 12px;">{{translation.credit_score_hint}}</p>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import TopOffers from '../../components/TopOffers'
// import Vue from 'vue'

export default {
  name: 'Credit',
  components: {
    TopOffers
  },
  data () {
    return {
      score: null,
      showInterface: false,
      showHiddenOffers: false
    }
  },
  computed: {
    ...mapState({
      JWToken: state => state.JWToken,
      translation: state => state.translation,
      name: state => state.user_data.name,
      offers_list: state => state.offers_list,
      offers_info: state => state.offers_info,
      isAuthorised: state => state.isAuthorised
    })
  },
  mounted () {
    this.showAds()
    // if (!this.isAuthorised) {
    //   this.$router.go(-1)
    //   this.$store.commit('SHOW_LOGIN', true)
    // }
    this.$store.commit('SHOW_LOADER', true)
    axios({
      method: 'GET',
      url: '/api/score',
      headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
    }).then((response) => {
      console.log('success', response)
      // Vue.$toast.success(this.translation.success, { position: 'top' })
      this.score = response.data.data.score
      this.$store.commit('SHOW_LOADER', false)
      this.showInterface = true
    }, (response) => {
      console.log('error', response)
      this.$store.commit('SHOW_LOADER', false)
      this.showInterface = true
      // Vue.$toast.error(this.translation.error, { position: 'top' })
    })
  },
  methods: {
    showAds () {
      const pushAds = () => {
        try {
          // eslint-disable-next-line no-undef
          (adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(x => {
        pushAds()
        pushAds()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/sass/cabinet.scss";
  @import "../../../assets/sass/review.scss";
</style>
