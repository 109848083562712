<template>
  <div class="popup-wrap active">
    <div class="popup popup-reg active">
        <!-- top -->
        <div class="popup-top">
          <h2>{{translation.login_register}}</h2>
          <Isvg @click.native="close" name="close"/>
        </div>
        <!-- body -->
        <div class="popup-body">
          <button class="popup-body__social fb">{{translation.login_fb}}</button>
          <button class="popup-body__social gl">{{translation.login_google}}</button>
          <div class="popup-body__separate"><p>{{translation.login_or}}</p></div>
          <form>
            <input v-model="name"
                   type="text"
                   :placeholder="translation.login_name"
                   name="name"
                   id="reg-name">
            <input v-model="surname"
                   type="text"
                   :placeholder="translation.login_surname"
                   name="surname"
                   id="reg-surname">
            <input v-model="email"
                   type="email"
                   :placeholder="translation.login_email"
                   name="email"
                   id="reg-mail">
            <div class="input-wrap">
              <input v-model="password"
                     :type="showPass ? 'text' : 'password'"
                     :placeholder="translation.login_password"
                     class="input-pass"
                     id="login-pass">
              <span class="input-pass__show active" @click="showPass=!showPass">{{showPass ? translation.login_hide :
                translation.login_show}}</span>
            </div>
            <label class="input-checkbox">
              <input type="checkbox" v-model="termsAccepted">
              <span class="input-checkbox__checkbox" :class="{error: termsAccepted === false}"></span>
              <p>{{translation.login_terms[0]}}<router-link to="/terms">{{translation.login_terms[1]}}</router-link>
                {{translation.login_terms[2]}}</p>
            </label>
            <button @click="register($event)">{{translation.login_continue}}</button>
          </form>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Login',
  components: {
    Isvg
  },
  computed: {
    ...mapState({
      translation: state => state.translation
    })
  },
  data () {
    return {
      showPass: false,
      name: '',
      surname: '',
      email: '',
      password: '',
      termsAccepted: ''
    }
  },
  methods: {
    googleAuth () {
      this.$store.dispatch('googleAuth')
    },
    facebookAuth () {
      this.$store.dispatch('facebookAuth')
    },
    register (e) {
      e.preventDefault()
      if (!this.termsAccepted) {
        this.termsAccepted = false
        return
      }
      this.$store.dispatch('register', {
        name: this.name,
        surname: this.surname,
        email: this.email,
        password: this.password
      }).then((response) => {
        console.log('success register', response)
        this.close()
      }, (response) => {
        console.log('error login', response)
      })
    },
    close () {
      this.$store.commit('SHOW_LOGIN', false)
      this.$store.commit('SHOW_REGISTER', false)
    }
  }
}
</script>

<style scoped>

</style>
