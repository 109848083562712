<template>
  <div style="padding-top: 70px">
    <!-- main-block -->
    <section id="main-block">
      <div class="container">
        <h1 class="">{{translation.contact_footer_link}}</h1>
      </div>
    </section>
    <!-- offers-block ends -->
    <!-- contacts-block -->
    <section id="contacts-block">
      <div class="container">
        <form @submit="sendFeedback($event)">
          <!-- Email -->
          <input v-model="form.email" type="email" :placeholder="translation.login_email" id="email-input">
          <!-- Name -->
          <input v-model="form.name" type="text" :placeholder="translation.login_name" id="name-input">
          <!-- Text -->
          <textarea v-model="form.description" name="" id="" cols="30" rows="10"></textarea>
          <button>{{translation.scam_button}}</button>
        </form>
      </div>
    </section>
    <!-- contacts-block ends -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'Contacts',
  data () {
    return {
      form: {
        name: '',
        email: '',
        feedback: ''
      }
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      lang: state => state.lang,
      nameFromStore: state => state.user_data.name,
      emailFromStore: state => state.user_data.email,
      JWToken: state => state.JWToken
    })
  },
  mounted () {
    this.form.name = this.nameFromStore
    this.form.email = this.emailFromStore
  },
  methods: {
    sendFeedback (e) {
      e.preventDefault()
      axios({
        method: 'POST',
        url: '/api/feedback',
        data: this.form,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        console.log('success', response)
        Vue.$toast.success(this.translation.success, { position: 'top' })
        this.form = {
          name: '',
          email: '',
          feedback: ''
        }
      }, (response) => {
        console.log('error', response)
        Vue.$toast.error(this.translation.error, { position: 'top' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/contacts.scss";
</style>
