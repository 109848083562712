<template>
  <div>
    <!-- main-block -->
    <section id="main-block">
      <div class="container">
        <div class="breadcrumbs">
          <router-link to="/offers">{{translation.prestamos_link}}</router-link>
<!--          <span> / </span>-->
<!--          <span>Vivus</span>-->
        </div>
        <h1 class="tal">{{translation.compare_header}}</h1>
      </div>
    </section>
    <!-- main-block ends -->

    <!-- compare-block -->
    <section id="compare-block">
      <div class="container">
        <div class="compare-block__table">
          <!-- ROW 1 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item"></div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <div class="compare-block__table-item__img">
                <img :src="offers_info[value.code] ? offers_info[value.code].img : ''" alt="">
              </div>
              <div class="compare-block__table-item__rate">
                <div class="compare-block__table-item__rate-green" :style="`width: ${value.average_rating * 20}%`"></div>
              </div>
              <span @click="deleteOffer(value.code)" class="compare-block__table-item__delete">{{translation.offers_delete}}</span>
              <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
                 :href="offers_info[value.code] ? offers_info[value.code].link : ''"
                 target="_blank"
                 class="button compare-block__table-item__submit">{{translation.offers_get_loan}}</a>
            </div>
          </div>

          <!-- ROW 2 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation.offers_amount_range}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation.offers_amount_range}}</span>
              <span>{{value.min_loan_sum.toLocaleString()}} - {{value.max_loan_sum.toLocaleString()}} {{currency}}</span>
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation.offers_timing_range}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation.offers_timing_range}}</span>
              <span>{{beautifyDays(value.min_loan_period)}} - {{beautifyDays(value.max_loan_period)}}</span>
            </div>
          </div>

          <!-- ROW 3 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation.offers_min_interest}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation.offers_min_interest}}</span>
              <span v-if="value.min_interest_rate === value.max_interest_rate">{{value.min_interest_rate}} {{translation.offers_percent}}</span>
              <span v-else>{{value.min_interest_rate}} - {{value.max_interest_rate}} {{translation.offers_percent}}</span>
            </div>
          </div>

          <!-- ROW 4 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation.offers_guarantee}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation.offers_guarantee}}</span>
              <span>{{value.guarantee ? translation.yes : translation.no}}</span>
            </div>
          </div>

          <!-- ROW 5 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation.offers_age_range}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation.offers_age_range}}</span>
              <span v-if="value.max_age !== 0">{{value.min_age}} - {{value.max_age}}
                    {{translation.offers_age}}</span>
              <span v-else>-</span>
            </div>
          </div>

          <!-- ROW 6 -->
          <div class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation.offers_free}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation.offers_free}}</span>
              <span>{{value.first_loan_free ? translation.yes : translation.no}}</span>
            </div>
          </div>
          <div
            v-for="(valueQ, indexQ) in ['difficulty', 'interest_rate', 'speed', 'support', 'satisfaction']"
            :key="indexQ"
            class="compare-block__table-row">
            <!-- 1 -->
            <div class="compare-block__table-item">
              <span>{{translation['review_q' + (indexQ + 1)]}}</span>
            </div>
            <!-- 2 -->
            <div v-for="(value, index) in compare_list" :key="index" class="compare-block__table-item">
              <span class="compare-block__table-item__title">{{translation['review_q' + (indexQ + 1)]}}</span>
              <img src="../../assets/img/star-compare.png" alt="" class="compare-block__table-item__star">
              <span>{{value[valueQ]}}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- compare-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Compare',
  components: {
    // Isvg
  },
  mounted () {
    if (!this.compare_list.length) this.$router.go(-1)
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      JWToken: state => state.JWToken,
      offers_info: state => state.offers_info,
      compare_list: state => state.compare_list,
      compare_codes: state => state.compare_codes,
      currency: state => state.currency
    })
  },
  methods: {
    deleteOffer (code) {
      const temp = this.compare_list
      temp.splice(this.compare_codes.indexOf(code), 1)
      this.$store.commit('COMPARE_LIST', temp)
      if (!temp.length) this.$router.push('/offers')
    },
    beautifyDays (days) {
      const months = Math.floor(days / 30)
      const years = Math.floor(months / 12)

      let result = days + ' ' + this.translation.offers_days
      if (months > 0) result = months + ' ' + this.translation.offers_months
      if (years > 0) result = years + ' ' + this.translation.offers_years
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/compare.scss";
  .breadcrumbs {
    // // position: absolute;
    // top: -30px;
    // left: 30px;
    text-align: left;
    margin-bottom: 10px;
    a, span {
      font-size: 12px;
    }
    span {
      font-weight: 300;
      color: #9e9e9e;
    }
  }
  @media (min-width: 720px) {
    .breadcrumbs {
      a, span {font-size: 13px;}
    }
  }
  @media (min-width: 920px) {
    .breadcrumbs {
      a, span {font-size: 14px;}
    }
  }
  .compare-block__table-item {
    min-width: 120px !important;
  }
</style>
