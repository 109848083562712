<template>
  <header id="opi-header" :class="{'centered-header': hideLinks, 'fixed': isFixed}">
    <router-link class="toindex" to="/"><Isvg :name="isWhite ? 'white-logo' : 'logo'" class="header-logo"/></router-link>
    <div v-if="!hideLinks" class="header-right" :class="{'white-links': isWhite}">
      <div class="header-right__linkdiv" @mouseover="menuShower('locale', true)"
           @mouseleave="menuShower('locale', false)">
        <img :src="require(`@/assets/img/lang-${countryCodeName}.png`)" alt="">
        <span>{{countryName}}</span>
        <Isvg name="arrow-down"/>
        <div @mouseover="menuShower('locale', true)"
             @mouseleave="menuShower('locale', false)"
             v-if="showMenu['locale']"
             class="header-right__links-menu active">
          <a v-for="(value, key, index) in multiCountry"
             :key="index"
             @click="$store.dispatch('setLocale', key)"
          ><img :src="require(`@/assets/img/lang-${key}.png`)" alt="">{{value.countryName}}</a>
        </div>
      </div>
      <div class="header-right__linkdiv" @mouseover="menuShower('offers', true)"
        @mouseleave="menuShower('offers', false)">
        <span>{{translation.prestamos_link}}</span>
        <Isvg name="arrow-down"/>
        <transition name="fade">
          <div @mouseover="menuShower('offers', true)"
               @mouseleave="menuShower('offers', false)"
               v-if="showMenu['offers']"
               class="header-right__links-menu nofix active">
            <router-link to="/offers">Préstamos Rápidos</router-link>
            <router-link to="/debt-restructuring">Reunificación de deudas</router-link>
            <router-link to="/offers">Préstamos con ASNEF</router-link>
          </div>
        </transition>
      </div>
      <router-link class="header-right__linkdiv" to="/cards">Tarjetas</router-link>
      <div class="header-right__linkdiv" @mouseover="menuShower('scam', true)"
        @mouseleave="menuShower('scam', false)">
        <span>Opiniones</span>
        <Isvg name="arrow-down"/>
        <transition name="fade">
          <div @mouseover="menuShower('scam', true)"
               @mouseleave="menuShower('scam', false)"
               v-if="showMenu['scam']"
               class="header-right__links-menu nofix active">
            <router-link to="/scam">Ofertas de estafa</router-link>
<!--            <router-link to="/opinion/credy">Opiniones</router-link>-->
          </div>
        </transition>
      </div>
      <router-link to="/credit">{{translation.credit_score_link}}</router-link>
      <transition name="fade">
        <a v-if="!isAuthorised" @click="showLogin" class="open-popup open-login">{{translation.login_header}}</a>
      </transition>
      <transition name="fade">
        <a v-if="isAuthorised" @mouseover="menuShower('user', true)"
           @mouseleave="menuShower('user', false)"
           class="header-right__person">
        <Isvg name="user"/>
          <transition name="fade">
            <div @mouseover="menuShower('user', true)"
                 @mouseleave="menuShower('user', false)"
                 v-if="showMenu['user']"
                 class="header-right__links-menu person active">
              <router-link to="/cabinet/review">{{translation.review_header}}</router-link>
              <router-link to="/cabinet/credit">{{translation.account}}</router-link>
              <a @click="logout">{{translation.logout_header}}</a>
            </div>
          </transition>
        </a>
      </transition>
    </div>
    <Isvg v-if="!hideLinks" @click.native="triggerMobileMenu" name="menu"/>
  </header>
</template>

<script>
import Isvg from '../assetComponents/Isvg'
import { mapState } from 'vuex'
// import axios from 'axios'

export default {
  name: 'Header',
  components: {
    Isvg
  },
  data () {
    return {
      showMenu: {
        user: false,
        offers: false,
        scam: false,
        locale: false
      },
      isFixed: false,
      hovered: {
        user: false,
        offers: false,
        scam: false,
        locale: false
      },
      lastScrollTop: document.documentElement.scrollTop
    }
  },
  computed: {
    ...mapState({
      showMobileMenu: state => state.showMobileMenu ? 'active' : '',
      translation: state => state.translation,
      lang: state => state.lang,
      isAuthorised: state => state.isAuthorised,
      multiCountry: state => state.multiCountry,
      countryName: state => state.countryName,
      countryCodeName: state => state.countryCodeName
    }),
    isWhite () {
      return !this.isFixed && (this.$route.name === 'Main' || this.$route.name === 'Landing-Loans')
    },
    hideLinks () {
      return this.$route.name === 'Landing-Loans' || this.$route.name === 'Offers2' || this.countryCodeName === 'ph'
    }
  },
  created () {
    this.isFixed = this.lastScrollTop > 400
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    changeLang () {
      if (this.lang === 'ES') {
        this.$store.commit('CHANGE_TRANSLATION', 'EN')
      } else {
        this.$store.commit('CHANGE_TRANSLATION')
      }
    },
    handleScroll (event) {
      const top = +event.target.documentElement.scrollTop
      this.isFixed = top > 400
      this.lastScrollTop = top
    },
    menuShower (menu, flag) {
      this.hovered[menu] = flag
      if (!flag) {
        setTimeout(() => { if (!this.hovered[menu]) this.showMenu[menu] = false }, 100)
      } else {
        this.showMenu[menu] = flag
      }
    },
    triggerMobileMenu () {
      this.$store.commit('SHOW_MOBILE_MENU', !this.showMobileMenu)
    },
    showLogin () {
      this.$store.commit('SHOW_LOGIN', true)
    },
    showRegister () {
      this.$store.commit('SHOW_REGISTER', true)
    },
    logout () {
      this.$store.dispatch('logout')
    }
  }
}
</script>

<style scoped>
  .expandable {
    margin: 0px 25px;
  }
  .hidden {
    display: none !important;
  }
  .centered-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    justify-content: center !important;
  }
</style>
