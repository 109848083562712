export const EN_TRANSLATION = {
  main_header: 'What product are you looking for?',
  main_categories: ['Fast Loans', 'Debt reunification', 'Cards and accounts', 'With bad credit score'],
  prestamos_link: 'Loans',
  credit_score_link: 'My Credit Score',
  header_1: 'Where can I get first loan for free?',
  header_2: 'Choosing a loan is easy with Opinomia',
  main_cta: 'Search loans',
  stats_header: 'Get the best offer',
  stats_1: ['50+', 'banks and lending companies'],
  stats_2: ['5,000+', 'issued loans'],
  stats_3: ['50,000', 'users per month'],
  trust_header: 'Why people trust Opinomia?',
  trust_1_header: 'Up-to-date information',
  trust_1_body: 'Our team gets the latest updates from lending companies regarding interest rates and other commissions. We collaborate with all reliable loan providers on the market and do not collaborate with scam companies.',
  trust_2_header: 'Save money',
  trust_2_body: 'Opinomia helps you to avoid scam offers and helps you get the offer with best commission rates. We save your time and money! Our service is completely free for you. We do not charge you money at no point. Remember: Avoid paying money before getting your loan.',
  trust_3_header: 'Secure',
  trust_3_body: 'Opinomia complies with GDPR. Your data is protected by SSL from un-authorised access and stored in Google Cloud.',
  cta_header: 'Credit Score',
  cta_body: 'Discover your credit score for free and get free personalized recommendations on how it could be improved',
  cta_button: 'Discover for free',
  cta_like: 'Your rating is better than 70% of users',
  about_header: 'About Opinomia',
  about_1_header: 'What is Opinomia?',
  about_1_body: ['Opinomia is a free service that helps you make a decision when choosing a financial product.', 'We collect information about all offers available in the market and update it each time their terms change. Verified reviews from real clients are to help users avoid scam deals and choose only safe loan providers.', 'We also offer free credit score and free personalized recommendations on how to improve it.'],
  about_2_header: 'How to contribute?',
  about_2_body: 'If you ever applied for a loan, submit a review and share your experience to help hundreds of people choose an honest loan provider.',
  about_button: 'Submit review',
  rights_disclamer: 'All rights reserved.',
  terms_footer_link: 'Terms&Conditions',
  privacy_footer_link: 'Privacy Policy',
  cookie_footer_link: 'Cookie policy',
  contact_footer_link: 'Contact',
  offers_reviews: '54 reviews',
  offers_conditions: 'Loan conditions',
  offers_amount_range: 'Amount Range',
  offers_min_interest: 'Minimum interest rate',
  offers_timing_range: 'Timing range',
  offers_guarantee: 'Requires warranty',
  offers_age_range: 'Age range',
  offers_get_loan: 'Get a loan',
  offers_compare: 'Compare',
  offers_currency: '€',
  offers_days: 'days',
  offers_months: 'months',
  offers_years: 'years',
  offers_percent: '%',
  offers_age: 'years',
  offers_delete: 'Delete',
  offers_difficulty: 'Difficulty applying',
  offers_speed: 'The speed of receiving money',
  offers_hidden_commissions: 'Hidden commissions',
  offers_service: 'Service',
  offers_satisfaction: 'Overall credit satisfaction',
  offers_interest_rate: 'Interest rate',
  offers_show: 'See details',
  offers_all_reviews: 'All reviews',
  offers_show_all_reviews: 'Show all reviews',
  offers_header: 'Online loans in Spain',
  offers_filter_average_rating: 'Rating',
  offers_filter_loan_sum: 'Amount',
  offers_filter_period: 'Period',
  offers_filter_first_loan_free: '0% interest',
  offers_filters: 'More filters',
  offers_free: 'first loan for free',
  offers_aggregator: 'Loan aggregator',
  offers_filter_guarantee: 'Requires guarantee',
  offers_filter_aggregator: 'Intermediaries',
  offers_filter_asnef: 'Accept ASNEF',
  offers_filter_age: 'Age',
  offers_save: 'Save',
  offers_clear: 'Clear',
  offers_rating_descr: 'Choose from which rating to filter loan offers:',
  offers_show_results: 'Show results',
  offers2_header: ', these are your personalized offers!',
  login_header: 'Log In',
  logout_header: 'Log Out',
  login_register: 'Registration',
  login_subheader: 'Log in so that we can prevent receiving false reviews from credit organizations',
  login_email: 'Email',
  login_name: 'Name',
  login_surname: 'Surname',
  login_password: 'Password',
  login_hide: 'Hide',
  login_show: 'Show',
  login_continue: 'Continue',
  login_or: 'or',
  login_fb: 'Continue with Facebook',
  login_google: 'Continue with Google',
  login_not_reg: 'Not a member of Opinomia?',
  login_reg: 'Registration',
  login_terms: ['I consent to the processing of ', 'my personal data', ' and to receive advertising and other informational messages'],
  credit_header: 'Personal credit score',
  credit_subheader: 'Discover your credit score for free and get free personalized recommendation on how to improve it',
  credit_button: 'Discover for free',
  credit_about_first: 'What is credit score',
  credit_about_first_body: 'Credit score is a rating that shows what are your chances to get a loan.',
  credit_about_second: 'How to use it',
  credit_about_second_body: 'Based on your credit score Opinomia gives you an estimate on how high are your chances to get a loan and which loan companies are best for you. Opinomia also provides you with free personalized recommendations on how to improve your credit score.',
  credit_hiw_header: 'How it works',
  credit_hiw_1: 'Fill in a simple questionnaire',
  credit_hiw_2: 'Our algorithm calculates your credit score and shows how you compare to market average',
  credit_hiw_3: 'You get a list of loan companies that are likely to issue you a loan based on your credit score',
  credit_hiw_4: 'We make recommendations on how you can improve your score',
  credit_cta_header: 'Discover how your score compares to market average in just a few moments!',
  credit_cta_body: 'Loan providers have different requirements for the applicants and we will show you companies who are likely to approve the exact credit profile you have.',
  credit_cta_button: 'Discover for free',
  credit_security: 'Security',
  credit_security_body: 'Data connection is protected with SSL, stored in Google  and safe from unauthorised access',
  credit_privacy: 'Privacy',
  credit_privacy_body: 'Opinomia is a GDPR compliant service. You can delete your data from our system whenever you want',
  yes: 'yes',
  no: 'no',
  clear: 'Clear',
  review_header: 'Write review',
  review_company: 'Company name',
  review_q1: 'Loan getting complexity',
  review_q2: 'Interest rate',
  review_q3: 'Speed ​​of receiving money',
  review_q4: 'Customer support',
  review_q5: 'Overall credit satisfaction',
  review_q6: 'Hidden comissions',
  review_graduate: ['Very bad', 'Bad', 'Normal', 'Good', 'Very good'],
  review_interest_rate: ['Very bad', 'Bad', 'Normal', 'Good', 'Very good'],
  review_text: 'Your review',
  review_submit: 'Submit',
  account_change: 'Change',
  account_save: 'Save',
  compare_header: 'Compare offers',
  success: 'Success',
  error: 'Error',
  account_header: 'Welcome back, ',
  account_credit_score: 'Credit score',
  account_write_review: 'Submit a review',
  account: 'Account',
  description: 'Description',
  condition: 'Loan conditions',
  scam_offers: 'Scam offers',
  asnef: 'Accept ASNEF',
  credit_score_header: 'My Credit',
  credit_score_subheader: 'Discover your credit score for free and get free personalized recommendation on how to improve it!',
  credit_score_cta: 'Discover',
  credit_score_header_2: 'Thank you',
  credit_score_subheader_2: ['Your score is', 'and is better than', '% of our users. *'],
  credit_score_subheader_3: 'Following loan companies issue loan to clients with score similar to yours:',
  credit_score_show_more: 'Show more offers',
  credit_score_hint: '* the scoring is composed by Fininity OÜ company and serves only for informational reasons.',
  scam_header: ['Recognize scam offers', 'And protect yourself from fraud'],
  scam_subheader: 'Our goal at Opinomia is to make the process of choosing a loan as safe as possible. In that regard, we want to warn consumers about fraud offers and the potential for fraud associated with loans.',
  scam_about_header: 'Pedir dinero por adelantado',
  scam_about_subheader: ['Es la estafa más común cuando se buscan préstamos online.', 'Sus víctimas más frecuentes son personas con perfiles crediticios complicados que han tenido dificultades para encontrar financiación y que acuden a empresas desconocidas.', 'Una empresa fiable sabe que pides dinero porque no lo tienes, así que evidentemente facilitará las condiciones para que lo obtengas rápido, pero NUNCA te cobrará NADA antes de darte el préstamo.'],
  scam_problem_header: 'Let us know about your experience with fraudulent offers',
  scam_company_name: 'Fraudulent company name',
  scam_problem_type: ['Had to pay for application', 'Had to call on paid phone number', 'Other'],
  scam_problem_placeholder: 'Problem type',
  scam_problem: 'Describe your problem',
  scam_button: 'Submit',
  registration_form_header: 'Registration Form',
  registration_form_button: 'Submit',
  privacy_checkbox: 'Confirmo que he leído y acepto las <a href="/terms" target="_blank">condiciones generales</a>, <a href="/privacy" target="_blank">política de privacidad</a> y <a href="/cookies" target="_blank">política de cookies</a>.',
  marketing_checkbox: 'Consiento estar al día de las últimas noticias y recibir ofertas especiales y descuentos por cualquier medio, incluidas las comunicaciones electrónicas o equivalentes de Fininity Ltd.',
  offers_period_dropdown: {
    '30 days': 30,
    '6 month': 180,
    '1 year': 365,
    '2 years': 730,
    '3 years': 1095,
    '4 years': 1460,
    '5 years': 1825
  },
  offers_period_dropdown_placeholder: 'Loan period',
  errors_translate: {
    'Server got itself in trouble': 'There is something wrong, try that last step later',
    'User exist': 'User is already registered',
    'Permission denied': 'Username and password do not match',
    'email: Not a valid email address.': 'Invalid email',
    'password: Length must be between 1 and 255.': 'The password must be between 1 and 255 characters'
  },
  register_success: 'You have successfully registered!',
  debt_header: 'Debt reunification',
  debt_subheader: '<b> debt reunification </b> means grouping all loans and mortgages into one loan. In this way, you will pay a single monthly fee, the amount of which will be less than the sum of the different fees that you previously had to pay. ',
  debt_questions: [
    ['What is debt reunification?', '<b> debt reunification </b> means grouping all loans and mortgages into one loan. In this way, you will pay a single monthly fee whose amount will be less than the sum of the different fees that you previously had to pay. '],
    ['What are the requirements to reunify debts?', 'To enter the program and negotiate your debt, you normally need to owe to banks, savings banks, supermarkets, electrical and microcredit stores, and be behind on your payments.'],
    ['Who is debt reunification for?', 'For all people whose debts have turned into a snowball.'],
    ['How does it normally work?', 'The company analyzes your case and presents you with a personalized plan in which it specifies the approximate amount with which you can settle your debts and the time it will take you to do so.']
  ],
  cards_header: 'Credit cards',
  cards_subheader: 'Make purchases, get money without commissions, finance trips ... Find your perfect card for every occasion. At Opinomia we publish only the best bank cards. Compare for free! ',
  cards_questions: [
    ['What types of cards are there?', 'The most common are <b> debit cards </b>. They allow you to access the balance you have available in your bank account. They are ideal for making purchases and withdrawing cash at ATMs. <p> The cards that can function as a loan are <b> credit cards </b>. These cards have an available credit balance limit. There are different payment methods: </p> <ul style = "list-style-type: circle; list-style-position: inside;"> <li> deferred payment </li> <li> payment at the end of the month </li><li>others;</li> </ul> They are not necessarily associated with your checking account. The cards that work with a limited balance are <b> prepaid cards </b>. You decide how much money to put on the card and that will be the balance that we will have. They are ideal, for example, for purchases on the Internet. '],
    ['How much does it cost to order a new bank card?', 'The first thing to pay when ordering the card is the <b> issuance fee </b>. Depending on the bank and the card, the cost can vary between 0 and 80 euros. Another important payment is the <b> maintenance fee </b>, which is generally annual. The cost can vary between 0 and 150 euros per year. <b> For cash withdrawals at ATMs </b> sometimes you also have to pay a commission. Normally it depends on whether the ATM is from the same entity or from another bank. ']
  ],
  fcm_request: 'Allow notifications to check the status of your request',
  landing_loans_purpose: [
    'Buy a car',
    'Buy a motorbike',
    'Travelling',
    'Repairs',
    'Consolidate loans',
    'Other'
  ],
  agreements_html: 'I confirm that I have read and accept the <a href="/terms" class="">General conditions</a>, <a href="/privacy" class="">Privacy policy</a> and <a href="/cookies" class="">Cookies policy</a>.',
  agreements_error: 'You must agree to the terms to continue.',
  reg_header: 'Enter your contact information',
  country_popup_header: '¡Smile! You are at Opinomia 😊',
  country_popup_subheader: ['This page is for clients from', '. Choose your country to see the relevant content!'],
  cookiesText: 'By using this website, you automatically accept that we use cookies.',
  cookiesLink: 'cookies-policy',
  cookiesAcceptText: 'Accept',
  cookiesMoreText: 'Read more'
}
