<template>
  <!-- 2 -->
  <div class="content-item active" id="page2">
    <input class="review-block__input" v-model="name" type="text" :placeholder="translation.login_name" id="name-input">
    <v-autocomplete
      :items="filtered"
      :min-len="1"
      v-model="item"
      :placeholder="translation.review_company"
      :get-label="getLabel"
      :component-item='template'
      @update-items="update"
      @item-selected="lenderSelected"
      @click.native="update(item)"
    >
    </v-autocomplete>
    <!-- questions -->
    <div class="wrap">
      <!-- 1 -->
      <div v-for="question in 5"
           :key="question" class="review-block__item">
        <h4>{{translation[`review_q${question}`]}}</h4>
        <div class="review-block__item-stars">
          <!-- 1 -->
          <div
            v-for="index in 5"
            :key="index"
            @click="setAnswer(question, index)"
            :class="{active: rating[question-1] === index}"
            class="review-block__item-stars__item"
          >
            <Isvg name="empty-star"/>
            <p v-if="question === 2">{{translation.review_interest_rate[index - 1]}}</p>
            <p v-else>{{translation.review_graduate[index - 1]}}</p>
          </div>
        </div>
      </div>
      <!-- 6 -->
      <div class="review-block__item">
        <h4>Hidden commissions</h4>
        <div class="review-block__item-switch">
          <div @click="setAnswer(6, 1)" :class="{active: rating[5] === 1}"
               class="review-block__item-switch__item">{{translation.yes}}</div>
          <div @click="setAnswer(6, 0)" :class="{active: rating[5] === 0}"
               class="review-block__item-switch__item">{{translation.no}}</div>
        </div>
      </div>
    </div>
    <!-- review -->
    <textarea v-model="reviewText" :placeholder="translation.review_text" name="text" id="" cols="30" rows="7"
              class="review-block__text"></textarea>
    <div class="review-block__button-wrap">
      <button @click="sendReview" id="submit-btn">{{translation.review_submit}}</button>
    </div>
  </div>
</template>

<script>
import AutocompleteItem from '../../components/AutocompleteItem.vue'
import { mapState } from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import Isvg from '../../assetComponents/Isvg'

export default {
  name: 'Review',
  components: {
    Isvg
  },
  data () {
    return {
      item: '',
      filtered: this.items,
      template: AutocompleteItem,
      rating: ['', '', '', '', '', ''],
      reviewText: '',
      name: ''
    }
  },
  mounted () {
    // if (!this.isAuthorised) {
    //   this.$router.go(-1)
    //   this.$store.commit('SHOW_LOGIN', true)
    // }
    this.name = this.nameFromStore
    if (this.review_lender) setTimeout(x => { this.item = this.review_lender }, 100)
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      lang: state => state.lang,
      JWToken: state => state.JWToken,
      isAuthorised: state => state.isAuthorised,
      offers_info: state => state.offers_info,
      offers_list: state => state.offers_list,
      review_lender: state => state.review_lender,
      nameFromStore: state => state.user_data.name
    }),
    items () {
      const temp = []
      for (const i in this.offers_list) {
        temp.push({ ...this.offers_list[i], img: (this.offers_info[this.offers_list[i].code] ? this.offers_info[this.offers_list[i].code].img : '') })
      }
      return temp
    }
  },
  methods: {
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    updateItems (text) {
      console.log(text)
      // yourGetItemsMethod(text).then( (response) => {
      //   this.items = response
      // })
    },
    update (text) {
      if (typeof text === 'object') return
      this.filtered = this.items.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    setAnswer (question, rate) {
      Vue.set(this.rating, question - 1, rate)
    },
    sendReview () {
      if (!this.isAuthorised) {
        this.$store.commit('SHOW_LOGIN', true)
        return
      }
      this.$store.commit('SHOW_LOADER', true)
      const data = {
        difficulty: this.rating[0],
        interest_rate: this.rating[1],
        speed: this.rating[2],
        support: this.rating[3],
        satisfaction: this.rating[4],
        commission: this.rating[5],
        name: name,
        lender_code: this.item.code,
        comment: this.reviewText
      }
      axios({
        method: 'POST',
        url: '/api/review',
        data: data,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        this.$store.commit('SHOW_LOADER', false)
        this.rating = ['', '', '', '', '', '']
        this.reviewText = ''
        Vue.$toast.success(this.translation.success, { position: 'top' })
        this.$router.push('/offer-card').then(x => this.$store.dispatch('scrollTo', '#reviews-start'))
      }, () => {
        Vue.$toast.error(this.translation.error, { position: 'top' })
        this.$store.commit('SHOW_LOADER', false)
      })
    },
    lenderSelected (val) {
      this.$store.dispatch('reviewLender', val)
    }
  }
}
</script>

<style lang="scss">
  @import "../../../assets/sass/review.scss";
  @import "../../../assets/sass/autocomplete.scss";
  .content-item {
    display: none;
    text-align: left;
    h2 {margin-bottom: 15px;}
    &.active {
      display: block;
    }
  }
  @media (min-width: 620px) {
    #page2 .wrap {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
</style>
