<template>
  <div class="el-loading-mask" style=""><div class="el-loading-spinner"><svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg><!----></div></div>
</template>

<script>

export default {
  name: 'Loader'
}
</script>

<style lang="scss">
  @import "../../assets/sass/loader.scss";
</style>
