<template>
  <div style="padding-top: 70px">
    <section id="main-block">
      <div class="container">
        <h1>Préstamos rápidos de Credy</h1>
        <div class="main-block__stars">
          <div class="main-block__stars-hover"></div>
        </div>
        <h4>¿Buscas opiniones de Credy?<br>¿Credy es de fiar?<br>¿Сredy es una estafa?</h4>
      </div>
    </section>
    <!-- main-block ends -->
    <!-- adsense-block -->
    <section class="adsense-block">
      <div class="container">
        <!-- LINK ADS -->
        <div class="feed-ads" style="padding: 0px; overflow: inherit !important;">
          <!-- Opinomia - offers -->
          <ins class="adsbygoogle feed-ads-ins"
               style="display:block"
               data-ad-client="ca-pub-6373705936907274"
               data-ad-slot="4339222842"
               data-ad-format="link"
               data-full-width-responsive="true">
          </ins>
        </div>
      </div>
    </section>
    <!-- adsense-block ends -->

    <!-- content-block -->
    <section id="content-block">
      <div class="container">
        <!-- text -->
        <h2>Opiniones de Credy</h2>
        <img src="../../assets/img/credy_1.png" alt="">
        <p>Credy es un buscador de préstamos. La empresa no otorga créditos, sino redirige a los usuarios a páginas de terceros.</p>
        <p class="p-red">Imformamos que Credy <b>estafa</b> a los usuarios proporcionándoles información falsa.</p>
        <div class="separate"></div>
        <!-- steps -->
        <h3>La estafa es la siguiente:</h3>
        <!-- 1 -->
        <div class="content-block__steps-item">
          <div class="content-block__steps-item__header">
            <div class="content-block__steps-item__header-number">1</div>
            <div class="content-block__steps-item__header-p">
              <p>El usuario rellena el formulario proporcionando todos sus datos.</p>
              <img src="../../assets/img/credy_2.png" alt="">
            </div>
          </div>
        </div>
        <!-- 2 -->
        <div class="content-block__steps-item">
          <div class="content-block__steps-item__header">
            <div class="content-block__steps-item__header-number">2</div>
            <div class="content-block__steps-item__header-p">
              <p>Al finalizar el formulario llega a la página en la que le dicen que su préstamo está aprobado y que solo falta verificar su identidad. Le <b>cobran un euro por la verificación</b> diciendo que esto se deducirá de los pagos de su préstamo (lo cuál es una mentira, porque nadie otorga un préstamo). Un día más tarde <b>cobran 33.99 euros más</b> sin avisar por concepto de una “suscripción a su servicio de comparación de préstamos”.</p>
              <p><b>En ningún momento ofrecen ningún préstamo.</b></p>
              <img src="../../assets/img/credy_3.png" alt="">
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="content-block__steps-item">
          <div class="content-block__steps-item__header">
            <div class="content-block__steps-item__header-number">3</div>
            <div class="content-block__steps-item__header-p">
              <p>Es imposible devolver el dinero.</p>
            </div>
          </div>
        </div>

        <div class="separate"></div>

        <h3>Credy tiene varias páginas web en España que engañan a la gente de la misma manera:</h3>
        <a class="content-block__link">lendora.es</a>
        <a class="content-block__link">microcreditos24.es</a>
        <a class="content-block__link">prestamosonlineya.es</a>
      </div>
    </section>
    <!-- content-block ends -->

    <div class="separate"></div>

    <!-- companies-block -->
    <section id="companies-block">
      <div class="container">
        <h2>¿Estás buscando un préstamo rápido?<br>Opinomia sólo colabora con empresas fiables</h2>
        <div class="content-item__offers-wrap">
          <!-- 1 -->
          <div class="content-item__offers-item" v-for="value in filtered_offers_list" :key="value.id">
            <img :src="offers_info[value.code] ? offers_info[value.code].img : ''" alt="">
            <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
               :href="offers_info[value.code] ? offers_info[value.code].link : ''"
               target="_blank"><button class="small">Solicitar</button></a>
          </div>
        </div>
        <router-link to="/offers"><button>Ver más</button></router-link>
      </div>
    </section>
    <!-- companies-block ends -->

    <!-- adsense-block -->
    <section class="adsense-block">
      <div class="container">
        <!-- AUTO ADS -->
        <div class="feed-ads"
             style="padding: 0px; overflow: inherit !important;">
          <ins class="adsbygoogle feed-ads-ins"
               style="display:block"
               data-ad-client="ca-pub-6373705936907274"
               data-ad-slot="6773814492"
               data-ad-format="auto"
               data-full-width-responsive="true">
          </ins>
        </div>
      </div>
    </section>

    <div class="separate"></div>

    <section style="max-width: 700px;margin: auto;">
      <h3>Les acordamos nunca pagar nada antes de recibir un préstamo aunque sea un euro.</h3>
      <router-link to="/scam"><button>Leer más sobre ofertas de estafa</button></router-link>
    </section>
    <!-- adsense-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Credy',
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      lang: state => state.lang,
      nameFromStore: state => state.user_data.name,
      emailFromStore: state => state.user_data.email,
      JWToken: state => state.JWToken,
      offers_info: state => state.offers_info,
      filtered_offers_list: state => state.offers_list.filter(x => ['crezu', 'pezetita'].indexOf(x.name) > -1)
    })
  },
  mounted () {
    this.$router.push('/')
    this.showAds()
  },
  methods: {
    showAds () {
      const pushAds = () => {
        try {
          // eslint-disable-next-line no-undef
          (adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(x => {
        pushAds()
        pushAds()
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/credy.scss";
  .content-item__offers-wrap {
    max-width: 500px;
    margin: auto;
    margin-top: 30px;
    /*border-top: 1px solid #eee;*/
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .content-item__offers-item {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    img {
      max-width: 180px;
      height: 50px;
      display: block;
      margin: 0 auto 15px;
      width: auto;
    }
    p {
      font-size: 13px;
      &.content-item__offers-item__amount {
        font-size: 18px;
        font-weight: 800;
        color: #000;
      }
    }
    button {
      margin-top: 15px;
      &.small {
        height: 42px;
        line-height: 42px;
        font-size: 15px;
      }
    }
  }
  @media (min-width: 420px) {
    .content-item__offers-wrap {
      flex-direction: row;
      justify-content: space-between;
    }
    .content-item__offers-item {
      width: 45%;
    }
  }
  @media (min-width: 720px) {
    .content-item__offers-wrap {
      margin-top: 30px;
      padding-top: 30px;
    }
    .content-item__offers-item {
      width: 50%;
      border-bottom: none;
    }
  }
</style>
