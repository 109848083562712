<template>
  <div style="padding-top: 70px">
    <section id="text-block">
      <div class="container" v-if="countryCodeName === 'ph'">
        <h1 class="breadcrumbs__title">Opinomia Privacy Policy</h1>
        <h3><strong>Who Are We?</strong></h3>
        <p>opinomia.com is a trading name of Fininity Ltd. Fininity Ltd is registered in Estonia (Company number 14523902), Registered Office: Tartu road 84a – M302, 10112 Tallinn, Estonia.</p>
        <p>opinomia.com are aware how important your privacy is, based on this we have implemented measures which will ensure any personal information that is obtained from you by visiting our website will be processed and maintained in line with accepted principles of good information handling. Contained within this statement and set out below are details of the type of information that we at Fininity may hold about you our customer, also how we obtain and process any information we may have and importantly how we protect your privacy.</p>
        <p>This privacy policy is only applicable to us and personal information which may be collected and obtained by us. opinomia.com may choose to amend the content of this privacy policy on occasion. If this occurs then we will update the policy and the revised policy will be posted on this website.</p>
        <h3><strong>How We May Contact You As Part Of Your Loan</strong></h3>
        <h3><strong>Application</strong></h3>
        <p>We, and our panel of lenders, credit brokers and alternative solution providers, will keep you informed with the progress of your application. Unless you state otherwise you may be contacted via telephone, email, post, SMS and automated message. (see further section on ‘How we keep you informed’)</p>
        <p><strong><em>Lawful basis for collecting your personal data</em></strong></p>
        <p>We rely upon a different lawful basis to process your personal data according to the reason for which you have provided your data to us.</p>
        <p>Our use of your personal data will always have a lawful basis.</p>
        <p><strong>Consent:</strong></p>
        <p>When you submit your loan application with Fininity, we process your personal data on the basis that you have provided your consent to be contacted by Fininity, our panel of Loan Providers (Lenders) /Credit Brokers, Credit Report providers in relation to the loan application and debt solution providers where you have been declined.</p>
        <p>These third parties will contact you by the communication channel or channels which you have agreed to, offering products and services that may be of interest.</p>
        <p>You can withdraw your consent to us processing your personal data for marketing purposes at any time by clicking the “unsubscribe” option in any marketing SMS, email we, or any associated third party, send to you.</p>
        <p>Alternatively, you can withdraw your consent by contacting our Data Protection Officer at compliance@opinomia.com or using the contact details stated in section 15 / 16. If you change your mind about agreeing to us processing your personal data, this will not have any effect on the lawfulness of any processing we have carried out before you changed your mind.</p>
        <p><strong>Contract:</strong></p>
        <p>Your personal data will also be processed on the basis that we need this information to fulfil our obligations under the contract we have entered into with you by providing you with a loan quote. Fininity, our panel of Loan Providers (Lenders) /Credit Brokers and Credit Report providers will contact you regarding your application quote.</p>
        <h3><strong>How We Keep You Informed</strong></h3>
        <p>We may want to make contact with you at some point in order to keep you informed regarding offers on products or services which we think you may find of interest, this contact will be made via telephone, email, SMS, automated messages. Third parties may also wish to contact you from the information we have shared with them, any information that is shared to third parties is completely trusted for the purpose it is specified for, third parties may also use the same means of contact stated above.</p>
        <p>As part of the application process/loan journey, if we are unable to offer you the short term loan you are looking for, we and, or third parties, may pass your details onto another lender, who may need to carry out a further credit check. Whilst the lender or third party will make contact with you to confirm whether your application has been successful they will too have their own privacy policy and recommend you refer to them, upon making your application, you will be requested to agree to their use of your information.</p>
        <p>Should your application be declined you may be contacted by Third party alternative solution providers.</p>
        <h3><strong>Obtaining Your Personal Data/Information</strong></h3>
        <ol>
          <li>You are free to access and browse our website opinomia.com at your convenience and do not have to provide any personal information/data, however I would like to refer you to the ‘Cookies’ section set out within this privacy policy.</li>
          <li>The personal information/data that we would normally collect from you is as follows:
            <ul>
              <li>Name</li>
              <li>Date of birth</li>
              <li>Email address (if applicable)</li>
              <li>Telephone number</li>
              <li>IP Address</li>
              <li>What you do on our website; the results of this will be used to show Opinomia examples of which parts of our website are most and least used (please refer to ‘Cookies’ section)</li>
            </ul>
          </li>
        </ol>
        <p>If you register with us and have a question about our services, or alternatively choose to send an email to compliance@opinomia.com we may also collect the above personal information/data in this instance in order for us to respond to your enquiry.</p>
        <h3><strong>Customer Communication</strong></h3>
        <p>Unless the customer states otherwise we will contact the customer via means of telephone, SMS and email to provide information and alerts relating to our services and our associate companies. We may also receive personal information/data from third parties, which enables us to offer Identified potential customers products, and services who have expressed interest in our products and services. Any information/data obtained from third parties is checked in regards to accuracy and we will endeavour to check your information with you on occasion.</p>
        <p><strong>Ensuring your personal details &amp; or preferences are kept up to date.</strong></p>
        <p>It is your responsibility to ensure that any information we have on record for you is kept up to date and correct. If you do require any of your information to be updated then please contact us as soon as possible so we can ensure it is corrected.</p>
        <p>If you require your preferences to be updated then please contact our Compliance Manager as soon as possible (details set out below within ‘Contact’ page).</p>
        <h3><strong>What Personal Information Do We Collect?</strong></h3>
        <p>We collect personal information about you when you give this to us when applying for a loan product online. This might be, for example, to obtain quotes for the loan and credit reporting services. In the course of providing the Services to you, we may also store information about how you use our Sites, for example, the pages viewed, the website from which you came to visit our Sites, changes you make to information you supply to us, details of the quotes you request and your applications, together with details of your financial information, for example, bank account or employer details.</p>
        <p>In order to provide you with a loan quote we will need to collect personal information. By proceeding with obtaining a quote you give your explicit consent to such information being processed by us and our third-party providers such as Lenders, Credit Brokers and Alternative Solution providers for the purposes stated in this Privacy Policy.</p>
        <p>We will store the information you provide and may use it to pre-populate fields on the Sites and to make it easier for you to use the Sites when making return visits.</p>
        <p>We may monitor or record your Calls, Emails, SMS or other communications but we will do so in accordance with data protection legislation and other applicable law. Monitoring or recording will always be for business purposes, such as for quality control and training (e.g. where you call our compliance or customer service department), to prevent unauthorised use of our telecommunication systems and Sites, to ensure effective systems operation, to meet any legal obligation and/or to prevent or detect crime.</p>
        <p>We will periodically review your personal information to ensure that we do not keep it for longer than is permitted by law.</p>
        <h3><strong>How Do We Use Your Personal Information?</strong></h3>
        <p>We may use your personal information:</p>
        <ol>
          <li>to <strong>enable you to access and use</strong> the Services;</li>
          <li>to <strong>personalise and improve</strong> aspects of our Services;</li>
          <li>for <strong>research</strong>, such as analysing market trends and customer demographics;</li>
          <li>to <strong>communicate</strong> with you, including some or all of the following:
            <ol>
              <li><strong>sending you information about products and services which we think may be of interest to you</strong> – this may include new product launches such as short term loans, car loans / finance, alternative financial solutions such as credit reporting services, newsletters and opportunities to participate in market research;</li>
              <li><strong>sending you loan quotes</strong> in order to provide this service to you, we may also send that information to our partners such as our panel of lenders, credit brokers and credit reporting companies so that they can calculate their quotes. When they do this, our partners may carry out soft credit checks on you (<strong>see section 9 Who do we share your information with?</strong> below for further information on the type of checks that may be undertaken);</li>
              <li><strong>sending you a confirmation email of your quote</strong> – when you submit an application with us, you will automatically be sent confirmation of your application by email or SMS so that you have a record of it;</li>
            </ol>
          </li>
          <li>to <strong>process an application</strong> between you and a third party such as the lender;</li>
          <li>to <strong>track sales</strong>, which may involve us sharing data with your product provider relating to the product(s) you have purchased. Your product provider may also send us information they hold relating to the product(s) you have purchased for this purpose;</li>
          <li>to circulate our <strong>customer survey review</strong> which are available to customers once an application has been completed.</li>
        </ol>
        <p><strong>Keeping you informed about the Services</strong>: You agree that we and, with your consent, other trusted third parties will be entitled to contact you (depending on your contact preferences) via email, post, telephone, SMS, or by other electronic means such as via social and digital media, in relation to products, services and other offers that may interest you.</p>
        <p>Whenever you provide us with personal information, where necessary we will seek your consent and/or we will give you an opportunity to tell us you do not consent to the use and sharing of your information for marketing purposes. Unless you tell us otherwise, you are consenting to such use.</p>
        <p>Where permitted by data protection and privacy law, we may also disclose information about you (including electronic identifiers such as IP addresses) and/or access your account:</p>
        <ol>
          <li>if required or permitted to do so by law;</li>
          <li>if required to do so by any court, or any other applicable regulatory, compliance, Governmental or law enforcement agency;</li>
          <li>if necessary in connection with legal proceedings or potential legal proceedings; and/or</li>
          <li>in connection with the sale or potential sale of all or part of our business.</li>
          <li>Fininity Ltd collect your data and will share it for commercial gain with third parties. These organisations will have a relationship with you or it may be used where they have a lawful reason, which may include these organisation’s own legitimate interest. Use includes tracing of individuals, verification and/or validation of the identity of individuals for the purposes of, but not limited to, anti-money laundering regulations, national security, crime prevention and detection, anti-fraud processes, asset recovery and asset reunification.</li>
        </ol>
        <p>If we reasonably believe false or inaccurate information has been provided and fraud is suspected, details may be passed to fraud prevention agencies to prevent fraud and money laundering.</p>
        <h3><strong>How Secure Is Our Site And What Steps Do We Take To Keep Your Personal Information Safe?</strong></h3>
        <p>Our Sites are SSL secured and keeping information about you secure is very important to us. However, no data transmission over the internet can be guaranteed to be totally secure. Sensitive information, for example, your card details, is encrypted to minimise the risk of interception during transit.</p>
        <p>We do our best to keep the information you disclose to us secure. However, we can’t guarantee or warrant the security of any information which you send to us, and you do so at your own risk. By using our Sites you accept the inherent risks of providing</p>
        <p><strong>How Long Will We Retain Your Personal Information For?</strong></p>
        <p>The amount of time we retain your information for will depend on the reason it was provided:</p>
        <ul>
          <li>If you request to opt out of marketing, we will still need to retain your information in order to evidence your preferences.</li>
          <li>We will need to retain, for a minimum period of 6 years, any information relating to applications you have entered into on our website. This period will commence once your relationship with us has ended. We need to keep your information for this length of time to assist with any potential future complaints.</li>
          <li>Your information/data will not be used for marketing purposes once 12 months has passed following your application.</li>
        </ul>
        <p>If you require further information on our data retention policies please email: compliance@opinomia.com.</p>
        <h3><strong>Your Right To Opt Out of Future Contact From Us</strong></h3>
        <p>You can opt out of future contact from Opinomia at any time. All of our marketing messages provide an opt out link – please allow up to 48 hours for us to process your opt out request. Alternatively you can email <a href="mailto:compliance@opinomia.com">compliance@opinomia.com</a> where your details will be removed from any future marketing campaigns.</p>
        <p><strong>Last updated 6th September 2019</strong></p>
      </div>
      <div class="container" v-if="countryCodeName === 'vn'">
        <h1>Chính sách riêng tư của opinomia.com</h1>
        <h3><strong>
          Về
          công ty</strong></h3>
        <p>opinomia.com
          là
          tên thương mại của công ty Fininity Ltd,
          được
          đăng ký tại Việt nam (Mã
          số công ty.
          14523902),
          Văn
          phòng được đăng ký;
          Tartu mnt 52-166, 10115 Tallinn, Estonia.
        </p>
        <p>
          Tại
          opinomia.com chúng tôi nhận thức được tầm quan trọng của sự
          riêng tư. Để giữ bí mật thông tin của bạn, chúng tôi
          thực hiện các biện pháp để đảm bảo rằng bất kỳ
          thông tin cá nhân nào chúng tôi có được từ bạn được
          xử lý và duy trì theo Luật
          Cộng hòa số 10173, còn được gọi là Đạo luật về
          thông tin cá nhân năm 2012, Quy tắc và Quy định thực hiện
          của Luật, các đạo luật khác và các văn bản liên quan
          do Ủy ban Bảo mật Quốc gia phát hành, bất kỳ đạo
          luật nào khác bảo vệ các quyền và tự do cơ bản của
          cá nhân, và
          thực tiễn xử lý dữ liệu tốt. Trong tuyên bố này,
          chúng tôi trình bày chi tiết loại thông tin của bạn —
          khách hàng của opinomia.com, mà chúng tôi có thể lưu giữ về
          bạn. Tuyên bố cũng sẽ bao gồm chi tiết về cách chúng
          tôi thu thập, xử lý và bảo vệ bất kỳ thông tin nào
          mà chúng tôi có thể có.
        </p>
        <p>
          Chính
          sách bảo mật này chỉ áp dụng cho chúng tôi và tất cả
          thông tin cá nhân mà chúng tôi có thể thu thập và thu
          thập bởi chúng tôi. Chúng tôi có quyền sửa đổi nội
          dung của chính sách bảo mật này theo từng thời kỳ. Nếu
          sửa đổi, chúng tôi sẽ đăng chính sách sửa đổi trên
          trang web này.
        </p>
        <h3><strong>
          Tại
          sao chúng tôi liên lạc với bạn</strong></h3>
        <p>
          Chúng
          tôi, cùng với ban điều hành của bên cho vay và môi giới
          tín dụng, sẽ thường xuyên thông báo cho bạn về quy
          trình đăng ký vay tiền của bạn. Chúng tôi có thể liên
          hệ với bạn qua điện thoại, tin nhắn SMS, thư tín,
          email và/hoặc tin nhắn tự động, trừ trường hợp bạn
          có ghi rõ phương pháp liên lạc khác.
        </p>
        <h3><em><strong>
          Cơ
          sở pháp lý thu thập dữ liệu cá nhân</strong></em></h3>
        <p>
          Chúng
          tôi sử dụng dữ liệu cá nhân của bạn luôn dựa trên
          cơ sở pháp lý. Chúng tôi dựa vào các cơ sở pháp lý
          khác nhau để xử lý dữ liệu cá nhân của bạn.
        </p>
        <h3><strong>
          Chấp
          thuận:</strong></h3>
        <p>
          Một
          khi bạn đã nộp đơn đăng ký vay tiền trên opinomia.com, bạn
          đồng ý để công ty Fininity Ltd và các bên cho vay của
          chúng tôi, hoặc nhà môi giới tín dụng liên quan đến
          đơn xin vay trực tuyến của bạn, liên lạc với bạn.
          Chúng tôi xử lý dữ liệu cá nhân của bạn trên cơ sở
          đó.
        </p>
        <p>
          Bạn
          có thể rút lại sự đồng ý của bạn về việc xử lý
          dữ liệu cá nhân của bạn cho các mục đích tiếp thị
          bất cứ lúc nào. Chỉ cần nhấp vào tùy chọn «Hủy
          đăng ký» trong bất kỳ tin nhắn SMS tiếp thị hoặc
          email nào mà chúng tôi hoặc bất kỳ bên thứ ba liên quan
          gửi cho bạn. Bạn cũng có thể rút lại sự đồng ý của
          mình bằng cách gửi email cho Nhân viên bảo mật dữ liệu
          của chúng tôi theo<a>
          địa
          chỉ support@opinomia.com</a>.
          Việc rút lại sự đồng ý trước đây của bạn đối
          với việc xử lý dữ liệu cá nhân sẽ không ảnh hưởng
          đến tính hợp pháp của việc xử lý chúng tôi đã thực
          hiện trước khi bạn thu hồi sự đồng ý.
        </p>
        <h3><strong>
          Hợp
          đồng:</strong></h3>
        <p>
          Khi
          bạn gửi dữ liệu cá nhân của bạn thông qua đơn đăng
          ký vay tiền, chúng tôi sẽ xử lý dữ liệu của bạn để
          thực hiện nghĩa vụ cung cấp báo giá cho bạn. Công ty
          Fininity Ltd và ban quản lý các bên cung cấp dịch vụ cho
          vay và/hoặc nhà môi giới tín dụng sẽ liên lạc với
          bạn về thông tin liên quan đến báo giá đơn đăng ký
          vay của bạn.
        </p>
        <h3><strong>
          Lãi
          suất hợp pháp:</strong></h3>
        <p>
          Chúng
          tôi duy trì lợi ích hợp pháp trong việc xử lý dữ liệu
          của bạn, đó là chia sẻ đơn đăng ký của bạn với
          ban điều hành các bên cho vay và/hoặc nhà môi giới tín
          dụng của chúng tôi để có được báo giá cho vay mà bạn
          đã đăng ký.
        </p>
        <h3><strong>
          Cách
          chúng tôi liên hệ với bạn</strong></h3>
        <p>Chúng tôi sẽ liên hệ với khách hàng qua điện thoại, tin nhắn SMS, thư tín, email và/hoặc tin nhắn tự động để cung cấp thông tin, cảnh báo đơn xin vay và thông báo từ các công ty liên kết của chúng tôi trừ khi khách hàng có yêu cầu khác. Các bên thứ ba cũng có thể cung cấp cho chúng tôi thông tincá nhân/dữ liệu, cho phép chúng tôi cung cấp sản phẩm cho những khách hàng tiềm năng đã thể hiện sự quan tâm. Chúng tôi kiểm tra bất kỳ thông tin/dữ liệu thu được từ các bên thứ ba để có được thông tin chính xác. Chúng tôi cũng sẽ cố gắng xác nhận thông tin của bạn một cách không thường xuyên.</p>
        <h3><strong>Thông báo</strong></h3>
        <p>
          Nếu
          bạn chọn nhận thông báo, chúng tôi có thể gửi thong
          báo đến điện thoại di động hoặc trình duyệt web của
          bạn. Những thông báo này có thể bao gồm các tin nhắn
          quảng cáo từ chúng tôi hoặc từ các bên thứ ba. Bạn
          có thể thay đổi cài đặt Thông báo bất cứ khi nào tại
          phần Cài đặt trên trình duyệt hoặc điện thoại di
          động của bạn.
        </p>
        <h3><strong>
          Cập
          nhật thông tin cá nhân</strong></h3>
        <p>
          Bạn
          có trách nhiệm đảm bảo rằng bất kỳ thông tin cá nhân
          hay dữ liệu nào mà chúng tôi lưu lại của bạn là chính
          xác và mới nhất. Hãy liên hệ với chúng tôi càng sớm
          càng tốt nếu bạn cần cập nhật hoặc sửa đổi thông
          tin.
        </p>
        <h3><strong>
          Thu
          thập thông tin cá nhân</strong></h3>
        <ol>
          <li>
            Bạn
            có thể tự do truy cập và duyệt web của chúng tôi,
            opinomia.com, bằng cách thuận tiện cho bạn. Bạn không có
            nghĩa vụ cung cấp bất kỳ thông tin/dữ liệu cá nhân
            nào. (Vui lòng xem lại Chính sách Cookie của chúng tôi để
            biết thêm thông tin.)
          </li>
          <li>
            Để
            đảm bảo có đề xuất cho vay cho bạn, chúng tôi thường
            thu thập các thông tin sau từ bạn:
            <ul>
              <li>Tên</li>
              <li>
                Địa
                chỉ
              </li>
              <li>
                Tình
                trạng lưu trú
              </li>
              <li>
                Ngày
                sinh
              </li>
              <li>
                Địa
                chỉ email
              </li>
              <li>
                Số
                điện thoại
              </li>
              <li>
                Thu
                nhập
              </li>
              <li>
                Chi
                tiết tài khoản ngân hàng
              </li>
              <li>
                Địa
                chỉ IP
              </li>
              <li>
                Hoạt
                động duyệt web; kết quả sẽ được sử dụng để
                thông báo cho opinomia.com về các khía cạnh trang web được sử
                dụng thường xuyên nhất và không thường xuyên nhất.
                (Vui lòng xem lại Chính sách Cookie của chúng tôi để
                biết thêm thông tin.)
              </li>
            </ul>
          </li>
        </ol>
        <p>Nếu bạn đặt câu hỏi về dịch vụ của chúng tôi hoặc gửi email đến<a>support@opinomia.com</a>, chúng tôi cũng có thể thu thập một số thông tin cá nhân ở trên từ bạn để đáp ứng tốt nhất yêu cầu của bạn.</p>
        <h3><strong>
          Chúng
          tôi thu thập thông tin gì</strong></h3>
        <p>
          Khi
          bạn đăng ký vay trực tuyến, chúng tôi thu thập thông
          tin cá nhân từ bạn để có được báo giá cho khoản
          vay. Khi cung cấp các dịch vụ cho bạn, chúng tôi cũng có
          thể lưu trữ thông tin về cách bạn sử dụng trang web
          của chúng tôi. Chúng tôi có thể lưu trữ thông tin về
          các trang bạn đã xem, trang web giới thiệu bạn đến
          trang web của chúng tôi, những thay đổi bạn đã thực
          hiện đối với thông tin cá nhân được cung cấp cho
          chúng tôi, chi tiết về báo giá bạn yêu cầu, và nội
          dung ứng dụng của bạn, cùng chi tiết về thông tin tài
          chính của bạn, bao gồm cả tài khoản ngân hàng hoặc
          thông tin hợp đồng lao động.
        </p>
        <p>
          Chúng
          tôi phải thu thập thông tin cá nhân từ bạn để cung cấp
          báo giá cho vay phù hợp với bạn. Khi bạn yêu cầu báo
          giá cho vay, bạn đồng ý rằng các thông tin đó sẽ được
          chúng tôi và các bên cung cấp thứ ba, như Bên cho vay và
          các Công ty tín dụng, xử lý.
        </p>
        <p>
          Chúng
          tôi lưu trữ thông tin bạn cung cấp. Chúng tôi có thể sử
          dụng thông tin đó để điền trước các trường trên
          trang web và giúp bạn sử dụng trang web trong các lần truy
          cập sau dễ dàng hơn.
        </p>
        <p>
          Chúng
          tôi sẽ không giữ thông tin của bạn lâu hơn mức được
          pháp luật cho phép. Chúng tôi sẽ định kỳ xem xét thông
          tin của bạn để đảm bảo điều đó.
        </p>
        <h3><strong>
          Bảo
          mật dữ liệu</strong></h3>
        <p>
          Giữ
          an toàn thông tin của bạn là điều vô cùng quan trọng
          đối với chúng tôi. Mặc dù không có đường truyền dữ
          liệu internet nào hoàn toàn an toàn, chúng tôi cố gắng
          hết sức để bảo vệ thông tin mà bạn đã cung cấp cho
          chúng tôi. Tuy nhiên, chúng tôi không thể và sẽ không đảm
          bảo hoặc đảm bảo tính bảo mật của bất kỳ dữ
          liệu nào bạn cung cấp cho chúng tôi. Bạn phải tự chịu
          rủi ro từ việc này. Bằng việc sử dụng trang web của
          chúng tôi, bạn chấp nhận những rủi ro vốn có và không
          thể tránh khỏi khi cung cấp dữ liệu cá nhân trực
          tuyến. Bạn đồng ý rằng bạn sẽ không bắt chúng tôi
          chịu trách nhiệm về bất kỳ vi phạm an ninh nào.
        </p>
        <h3><strong>
          Sử
          dụng thông tin cá nhân</strong></h3>
        <p>
          Chúng
          tôi có thể sử dụng thông tin cá nhân của bạn:
        </p>
        <ol>
          <li>để<strong>
            cho
            phép bạn truy cập và sử dụng</strong>   dịch vụ của chúng tôi;</li>
          <li>để<strong>
            cải
            thiện và tùy chỉnh</strong>   các khía cạnh dịch vụ của chúng tôi;</li>
          <li>để<strong>
            nghiên
            cứu thị trường</strong></li>
          <li>để<strong>
            liên
            hệ với</strong>   bạn, bao gồm bất kỳ hoặc tất cả những điều sau
            đây:
            <ol>
              <li><strong>
                gửi
                cho bạn thông tin về các dịch vụ và sản phẩm bổ
                sung mà chúng tôi cho là bạn có thể quan tâm</strong>.
                Điều này có thể bao gồm việc ra mắt sản phẩm mới,
                giải pháp tài chính thay thế, bản tin và cơ hội tham
                gia nghiên cứu thị trường;
              </li>
              <li><strong>
                gửi
                báo giá khoản vay cho bạn.</strong>       Chúng tôi cũng có thể chia sẻ thông tin bạn cung cấp
                với các đối tác của chúng tôi (chẳng hạn như hội
                đồng các bên cho vay và công ty môi giới tín dụng) để
                họ có thể tính toán báo giá cá nhân cho bạn. Khi thực
                hiện, các đối tác của chúng tôi có thể tiến hành
                kiểm tra tín dụng đối với bạn;
              </li>
              <li><strong>
                gửi
                cho bạn một email xác nhận báo giá</strong>.
                Khi bạn nộp đơn đăng ký vay tiềnc, chúng tôi sẽ tự
                động gửi xác nhận về việc đã nhận được đơn
                đăng ký của bạn qua email hoặc tin nhắn SMS cho hồ sơ
                của bạn.;
              </li>
            </ol>
          </li>
          <li>để<strong>
            xử
            lý đơn đăng ký</strong>   giữa bạn và bên thứ ba;</li>
          <li>để<strong>
            theo
            dõi doanh số bán hàng</strong>.
            Điều này có thể liên quan đến việc chia sẻ dữ liệu
            của bạn với nhà cung cấp sản phẩm liên quan đến
            (các) sản phẩm mà bạn đã mua;
          </li>
          <li>để<strong>
            khớp
            dữ liệu của chúng tôi với dữ liệu từ các nguồn
            khác</strong>.
            Chúng tôi có thể xác thực, phân tích và khớp thông tin
            của bạn với dữ liệu đã được thu thập bởi một
            bên thứ ba để đảm bảo rằng tất cả thông tin chúng
            tôi nắm giữ về bạn đều nhất quán, chính xác và
            được sắp xếp tốt nhất có thể.
          </li>
          <li>
            để
            gửi<strong>
            khảo
            sát phản hồi của khách hàng,</strong>mà
            chúng tôi cung cấp cho tất cả khách hàng đã gửi đơn
            đăng ký;
          </li>
          <li>
            để
            chuyển<strong>
            dữ
            liệu của bạn cho bên thứ ba cho các</strong>   mục đích tiếp thị;
          </li>
        </ol>
        <h3><strong>
          Đảm
          bảo bạn luôn được thông báo về dịch vụ:</strong></h3>
        <p>
          Bằng
          việc sử dụng trang web của chúng tôi, bạn đồng ý rằng
          chúng tôi và (với sự đồng ý của bạn) các bên thứ
          ba khác mà chúng tôi tin tưởng sẽ được phép liên hệ
          với bạn qua email, tin nhắn SMS, điện thoại, bưu điện,
          và/hoặc tin nhắn tự động hoặc bằng các phương tiện
          điện tử khác liên quan đến dịch vụ, sản phẩm và
          các chương trình khuyến mãi khác mà bạn có thể quan
          tâm.
        </p>
        <p>
          Bất
          cứ khi nào khi bạn cung cấp cho chúng tôi dữ liệu/thông
          tin cá nhân, khi cần thiết, chúng tôi sẽ hỏi ý kiến
          của bạn và/hoặc chúng tôi sẽ tạo điều kiện để
          bạn cho chúng tôi biết rằng bạn không đồng ý với
          việc chia sẻ thông tin của bạn cho các mục đích tiếp
          thị và các mục đích sử dụng khác. Chúng tôi mặc định
          bạn đồng ý với việc này trừ khi có ý kiến khác.
        </p>
        <h3><strong>
          Chia
          sẻ thông tin cá nhân</strong></h3>
        <p>
          Bằng
          cách điền và nộp đơn xin vay, bạn đồng ý với việc
          cung cấp thông tin cá nhân của bạn cho các bên sau:
        </p>
        <ol>
          <li>opinomia.com
            và công ty Fininity Ltd: (i) liên hệ với bạn, bao gồm
            việc gửi cho bạn thông tin về các dịch vụ và sản
            phẩm mà bạn có thể quan tâm; (ii) để điền biểu mẫu
            một cách nhanh chóng, hoặc tùy chỉnh, hoặc cải thiện
            trải nghiệm người dùng của bạn trên trang web hoặc
            ứng dụng di động; hoặc (iii) phù hợp với Chính sách
            Bảo mật.
          </li>
          <li>
            Các
            bên cho vay và môi giới tín dụng.
          </li>
        </ol>
        <p>
          Khi
          được luật về quyền riêng tư và bảo vệ dữ liệu
          cho phép, chúng tôi cũng có thể bị buộc phải truy cập
          vào tài khoản của bạn và/hoặc tiết lộ thông tin về
          bạn (bao gồm các mã định danh điện tử như địa chỉ
          IP):
        </p>
        <ol>
          <li>
            nếu
            được pháp luật cho phép hoặc yêu cầu làm như vậy;
          </li>
          <li>
            nếu
            bất kỳ tòa án nào, hoặc bất kỳ cơ quan pháp lý,
            Chính phủ hoặc cơ quan thực thi pháp luật hiện hành
            khác yêu cầu làm như vậy;
          </li>
          <li>
            nếu
            cần thiết kết hợp với thủ tục tố tụng hoặc thủ
            tục tố tụng pháp lý sắp xảy ra; và/hoặc
          </li>
          <li>
            liên
            quan đến việc bán lại hoặc có tiềm năng bán lại tất
            cả hoặc một phần hoạt động kinh doanh của chúng tôi.
          </li>
        </ol>
        <h3><strong>
          Chia
          sẻ thông tin cá nhân của bạn với bên thứ ba</strong></h3>
        <p>
          Một
          số nhà cung cấp trong hội đồng của chúng tôi có thể
          sử dụng thông tin cá nhân để đánh giá tình hình của
          bạn, (bao gồm thông tin về các bên thứ ba có tên trong
          đơn đăng ký của bạn) và xác minh tính chính xác của
          bất kỳ thông tin nào bạn cung cấp trước khi đưa ra báo
          giá cho bạn. Một số nhà cung cấp có thể kết hợp với
          các cơ quan phòng chống gian lận và tham chiếu tín dụng
          để thực hiện kiểm tra. Họ có thể kiểm tra cả dữ
          liệu cá nhân và dữ liệu công cộng của bạn theo cách
          này. Một số nhà cung cấp có thể tiến hành kiểm tra dữ
          liệu mà họ thu được từ bạn, chẳng hạn như dữ liệu
          về các sản phẩm tài chính hiện có, thông tin tài khoản
          hoặc thông tin từ các giao dịch trước đó để quyết
          định số tiền vay của bạn. Nếu các nhà cung cấp thực
          hiện tìm kiếm loại này, chúng tôi sẽ bổ sung một bản
          báo cáo tín dụng vào phần kết quả tìm kiếm. Một số
          nhà cung cấp có thể kiểm tra cả các nguồn tin về giáo
          dục để thu thập lịch sử học tập của bạn.
        </p>
        <p>
          Điều
          này cho phép các nhà cung cấp xác minh bất kỳ thông tin
          nào bạn cung cấp trong quá trình báo giá. Điều này cũng
          hỗ trợ việc phát hiện các khoản vay gian lận. Đây
          cũng là phương pháp phổ biến khi nhận báo giá từ các
          nguồn khác.
        </p>
        <p>
          Nếu
          bạn quyết định vay tiền, dữ liệu bạn đã cung cấp
          cho chúng tôi, ngoài các thông tin bổ sung được gửi cho
          nhà cung cấp thứ ba, sẽ được bên cung cấp lưu trữ
          dựa theo chính sách bảo mật của họ. Chúng tôi khuyên
          bạn nên đọc chính sách bảo mật của nhà cung cấp mà
          bạn đã chọn trước khi quyết định vay tiền. Chúng tôi
          không chịu trách nhiệm về cách nhà cung cấp sử dụng
          thông tin cá nhân của bạn.
        </p>
        <h3><strong>
          Quyền
          và đặc quyền</strong></h3>
        <p>
          Bạn
          có một số quyền theo DPA bao gồm:
        </p>
        <p><strong>
          Quyền
          được thông báo</strong>—
          opinomia.com cung cấp cho tất cả khách hàng tuyên bố về quyền
          riêng tư này để cung cấp thông tin minh bạch và rõ ràng
          về việc thu thập, kiểm soát và xử lý dữ liệu cá
          nhân.
        </p>
        <p><strong>
          Quyền
          truy cập</strong>−
          Bạn có thể yêu cẩu bản sao dữ liệu cá nhân được
          lưu giữ có thể theo yêu cầu truy cập và sẽ được đáp
          ứng một cách hợp pháp trong 30 ngày, thường được miễn
          phí. Bất kỳ việc mở rộng các yêu cầu này sẽ phải
          được tư vấn dựa trên bản chất của yêu cầu nhưng
          sẽ là ngoại lệ và phải chịu sự giám sát của ban
          quản lý cấp cao, vui lòng xem phần Liên hệ với chúng
          tôi để biết thêm chi tiết.
        </p>
        <p><strong>
          Quyền
          chỉnh sửa</strong>—
          Trong trường hợp dữ liệu mà chúng tôi lưu trữ không
          chính xác, vui lòng thông báo cho và cung cấp thông tin
          chính xác cho chúng tôi, hồ sơ sẽ được cập nhật
          trong vòng 72 giờ và xác nhận được cung cấp cho phù hợp
          (Tùy thuộc vào việc xác minh danh tính của bạn).
        </p>
        <p><strong>
          Quyền
          hạn chế xử lý −</strong>Xử
          lý dữ liệu sẽ bị hạn chế nếu có bất kỳ khiếu
          nại nào về tính chính xác từ bạn, hoặc bạn phản đối
          suy đoán hoặc động cơ hợp pháp để xử lý cho một
          mục đích cụ thể và để thiết lập, thực hiện hoặc
          bảo vệ khiếu nại pháp lý theo yêu cầu của bạn.
        </p>
        <p><strong>
          Quyền
          phản đối</strong>— Bạn có thể phản đối việc xử lý opinomia.com trên cơ sở
          điều này là trái với quyền và tự do của bạn, bất
          kỳ phản đối liên quan đến tiếp thị trực tiếp sẽ
          được giải quyết trong vòng 48 giờ, các phản đối khác
          sẽ được coi là khiếu nại và đánh giá dựa trên cơ
          sở tài liệu pháp lý của chúng tôi.
        </p>
        <p><strong>
          Quyền
          xóa bỏ</strong>—
          Thường được gọi là quyền bị lãng quên, opinomia.com sẽ
          tuân thủ yêu cầu của bạn, thường là trong vòng 72 giờ,
          nơi cơ sở pháp lý được thiết lập duy nhất hoặc một
          phần là sự đồng ý, bất kỳ dữ liệu nào cần thiết
          cho lợi ích pháp lý, quy định hoặc hợp pháp có thể
          được duy trì phù hợp với lịch lưu giữ tài liệu. Tuy
          nhiên, chúng tôi sẽ xác nhận và thảo luận về yêu cầu
          của bạn để đảm bảo quyền và tự do của bạn tại
          mọi thời điểm.
        </p>
        <p><strong>
          Quyền
          di chuyển dữ liệu</strong>—
          Bạn có quyền nhận dữ liệu cá nhân mà bạn cung cấp
          cho chúng tôi, theo định dạng có cấu trúc, thường được
          sử dụng và có thể đọc được bằng máy móc, có quyền
          truyền dữ liệu đó đến một bên kiểm soát khác một
          cách kịp thời, thường là trong 72 giờ trừ khi được
          chứng minh đây là quá mức hoặc không hợp lý.
        </p>
        <p><strong>
          Quyền
          liên quan đến việc ra quyết định tự động và lập hồ
          sơ —</strong>Bạn
          có quyền phản đối việc ra quyết định tự động, để
          thể hiện quan điểm của bạn, yêu cầu sự can thiệp từ
          phía nhân viên, và được giải thích về quyết định
          với lựa chọn phản đối suy đoán.
        </p>
        <p>opinomia.com
          đã thiết lập chính sách mạnh mẽ và các yêu cầu thủ
          tục để kích hoạt quyền của bạn như là một đối
          tượng dữ liệu được tôn trọng, tuy nhiên nếu điều
          này là không thể vì những lý do được nêu ra chúng tôi
          xác nhận chi tiết.
        </p>
        <p>
          Liên
          quan đến tất cả các quyền này, vui lòng gửi thư cho
          hoặc liên hệ với chúng tôi theo địa chỉ dưới đây.
          Xin lưu ý rằng chúng tôi sẽ cần phải xác minh danh tính
          của bạn.
        </p>
        <h3><strong>
          Lưu
          giữ dữ liệu cá nhân</strong></h3>
        <p>
          Tùy
          thuộc vào lý do bạn cung cấp cho chúng tôi dữ liệu cá
          nhân của mình, khoảng thời gian chúng tôi giữ lại dữ
          liệu sẽ khác nhau:
        </p>
        <ul>
          <li>
            Nếu
            bạn từ chối truyền thông tiếp thị, chúng tôi sẽ giữ
            lại thông tin của bạn để ghi nhớ lựa chọn ưu tiên
            của bạn.
          </li>
          <li>
            Bất
            kỳ thông tin nào liên quan đến đơn đăng ký bạn gửi
            trên trang web của chúng tôi sẽ được giữ lại tối
            thiểu là 6 năm (từ khi bạn ngừng sử dụng dịch vụ
            của chúng tôi). Chúng tôi lưu giữ thông tin trong khoảng
            thời gian này để hỗ trợ các khiếu nại tiềm ẩn
            trong tương lai.
          </li>
        </ul>
        <h3><strong>
          Từ
          chối hợp đồng</strong></h3>
        <p>
          Bạn
          có thể tự do ngừng mọi liên lạc trong tương lai từ
          opinomia.com bất cứ khi nào bạn muốn. Tất cả các tin nhắn
          quảng cáo của chúng tôi bao gồm một liên kết từ chối
          hoặc hủy đăng ký. Vui lòng cho chúng tôi tối đa 48 giờ
          để xử lý yêu cầu của bạn.
        </p>
        <h3><strong>
          Xóa
          bỏ thông tin cá nhân</strong></h3>
        <p>Nếu bạn muốn chắc chắn rằng các quyền của mình bị lãng quên và yêu cầu tháo gõ hoặc xóa bỏ thông tin cá nhân, gửi email tới&nbsp;<a>support@opinomia.com</a>.   Chúng tôi cũng sẽ đảm bảo rằng các đối tác tiếp thị của chúng tôi được thông báo về yêu cầu của bạn.</p>
        <h3><strong>
          Khiếu
          nại</strong></h3>
        <p>Chúng tôi cố gắng đảm bảo rằng bạn luôn hài lòng với dịch vụ của chúng tôi.  Tuy nhiên, nếu bạn muốn gửi khiếu nại chính thức, hãy gửi&nbsp;<a>thư tới&nbsp; support@opinomia.com</a>.</p>
        <h3><strong>
          Cập
          nhật lần cuối tháng 3 năm 2020</strong></h3>
      </div>
      <div class="container" v-else>
        <h1>Política de privacidad</h1>
        <h3>Introducción</h3>
        <p>En Opinomia nos tomamos muy en serio su privacidad y la seguridad de su información personal. Por ello, mediante este documento hemos tratado de describir del modo más sencillo posible cómo recolectamos y tratamos su información.</p>
        <p>Esta política de privacidad describe como Fininity Ltd. (en adelante, “Fininity Ltd.” o “Nosotros”, N° de registro: 14523902 y con domicilio: calle Tartu 84a, Tallinn, 10112, EE) trata los datos de carácter personal de sus usuarios (en adelante, “Usted”) en relación con el uso de su sitio web opinomia.com (en adelante, el “Sitio Web” o Opinomia) y de los Servicios ofrecidos por Opinomia.</p>
        <p>Este documento está adaptado al Reglamento Europeo de Protección de Datos (RGPD).</p>
        <p>Este documento contiene las menciones o referencias legales que servirán para regular las condiciones en las que el sitio o página web recoge, gestiona y retiene («trata») los datos personales de los usuarios de Internet que acceden, navegan y utilizan el sitio o página web. También comprende los derechos de los usuarios y las obligaciones del titular del sitio web en relación con el tratamiento de los datos personales.</p>
        <h3>El fin de la presente política de privacidad</h3>
        <p>El fin de presente política de privacidad es explicar cómo Opinomia recolecta, usa y comparte sus datos de carácter personal y qué derechos tiene respecto de sus datos.</p>
        <h3>¿Quién es el responsable del tratamiento de sus datos?</h3>
        <p>El responsable es la compañía Fininity Ltd. N° de registro: 14523902 y con domicilio: calle Tartu 84a, Tallinn, 10112, EE.</p>
        <h3>¿Qué datos recopilamos y cuándo?</h3>
        <p>Fuentes de las que proceden los datos personales:</p>
        <ul>
          <li>Cuando Usted nos los proporcione directamente a través de los formularios de nuestro Sitio Web (e.g. al completar una solicitud de préstamo o de cualquier otra manera);</li>
          <li>De páginas web de terceros que se dedican a la generación de oportunidades en relación a la prestación de servicios, entre ellos, los ofrecidos por Opinomia;</li>
          <li>Fuentes accesibles al público (e.g. los perfiles públicos de redes sociales tales como Facebook o LinkedIn);</li>
          <li>De manera automática mediante el uso de herramientas informáticas (e.g. información relativa a su comportamiento en nuestro Sitio Web obtenida a través de la herramienta Yandex Metrika).</li>
        </ul>
        <h3>¿Con qué finalidad tratamos sus datos personales?</h3>
        <p>Las finalidades principales para las que utilizamos sus datos son para ayudarle encontrar un préstamo y administrar su cuenta de cliente. Esto incluye el envío de los datos personales a los proveedores de préstamo para encontrar un Prestamista adecuado, tal y como lo solicite, que se adapte a sus necesidades, así como pre-completar la solicitud de préstamo en caso de que la misma se repitiese, y la mejora de su perfil crediticio y scoring si ya tuviese una Cuenta.</p>
        <p>Tratamos los datos generados en la utilización de nuestro Sitio Web y de los Servicios (e.g. información sobre cómo navega y utiliza nuestro Sitio Web) para mejorar la experiencia de usuario en la utilización del Sitio Web y los Servicios, con el objetivo de hacerlo más intuitivo. La base legal de lo anterior reside en nuestro interés legítimo de negocio para mejorar el Sitio Web y la experiencia del usuario. Teniendo en cuenta la naturaleza de los datos y que los usamos de forma agregada, así como sus intereses o derechos fundamentales y libertades, éstos no anulan nuestros intereses legítimos.</p>
        <p>Asimismo, tratamos los datos recibidos de uso que hace de nuestro Sitio Web y nuestros Servicios (e.g. información sobre cómo navega y utiliza nuestro Sitio Web) con el objetivo de prevenir el fraude y el uso indebido de nuestros Servicios (e.g. evitar la utilización del Sitio Web y los Servicios por robots). La base legal de lo anterior reside en nuestro interés legítimo de negocio para mejorar el Sitio Web y la experiencia del usuario. Teniendo en cuenta la naturaleza de los datos y que los usamos de forma agregada, así como sus intereses o derechos fundamentales y libertades, éstos no anulan nuestros intereses legítimos.</p>
        <p>En la medida en que se exija por la normativa aplicable en materia de protección de datos, Usted tiene derecho a formular objeciones para el tratamiento de sus datos personales sobre la base de un interés legítimo.</p>
        <p>Además, siempre que lo autorice, podremos comunicar sus datos personales a terceros, de modo que podrán enviarle comunicaciones comerciales sobre sus productos o servicios.</p>
        <p>Cuando el procesamiento de datos de carácter personal se base en su consentimiento, éste se podrá retirar en cualquier momento pinchando en el link de “unsubscribe from the list” / “darse de baja” que se encuentra al final de cada uno de los e-mails. Tenga en cuenta que la retirada del consentimiento no afecta a legalidad del procesamiento de datos realizado con anterioridad a dicha retirada.</p>
        <p>También podremos procesar sus datos de carácter personal para dar cumplimiento a nuestras obligaciones legales (e.g. en nuestra obligación de compartir sus datos personales con las autoridades pertinentes y para cumplir con nuestras obligaciones en materia de Prevención de Blanqueo de Capitales y Financiación al Terrorismo). Este tipo de procesamiento se fundamenta en el cumplimiento de nuestras obligaciones legales de información.</p>
        <h3>¿Qué es la toma de decisiones automatizada?</h3>
        <p>La toma de decisiones automatizada como parte de nuestros Servicio: teniendo en cuenta sus preferencias para contratar un préstamo con nosotros y la información personal que Usted nos ha proporcionado completando el formulario de solicitud de préstamo, la información obtenida de las fuentes anteriormente enumeradas, y su historial de uso de nuestros Servicios (e.g. si le han otorgado un préstamo y quién se lo ha otorgado), elaboramos o completamos su perfil crediticio y “scoring” asignando posteriormente los resultados obtenidos a su Cuenta. Comparamos su perfil con las condiciones establecidas por cada uno de los Prestamistas para sus clientes, y teniendo en cuenta los resultados de esta comparación le ofrecemos el Prestamista o la relación de Prestamistas con los que más posibilidades tiene de que efectivamente le otorguen el préstamo, en las condiciones que ha pre-seleccionado. Nuestros resultados se basan en la información que hemos recibido de los Prestamistas en relación con las condiciones que ofrecen a sus clientes, así como en nuestra mejor estimación sobre las conductas previas mantenidas por los Prestamistas. Como resultado de nuestra comparación, la lista de potenciales Prestamistas puede ser muy numerosa o por el contrario contener uno o ningún potencial Prestamista, esto depende de cuantos Prestamistas, de acuerdo con nuestra mejor estimación, estarían dispuestos a otorgar préstamos a personas con perfiles similares al suyo. Para comprender en qué se basan las preferencias de cada uno de los Prestamistas por favor consulten las políticas de privacidad de los Prestamistas.</p>
        <p>No se puede considerar que los resultados de nuestros perfiles le afecten sus posibilidades de obtener un préstamo. Tiene derecho a obtener intervención humana, a expresar su punto de vista y a impugnar la decisión.</p>
        <h3>¿Quién tiene acceso a los datos personales y Cuál es política del intercambio de sus datos personales?</h3>
        <p>Solo compartimos sus datos de carácter personal cuando tenemos una razón válida para ello y cuando dicho intercambio esté legalmente amparado.</p>
        <p>Solo contratamos con proveedores de servicios que brindan garantías suficientes para implementar las medidas de seguridad técnicas y organizativas adecuadas para proteger sus datos personales. </p>
        <p>Opinomia transfiere los datos personales recopilados por él mismo a los destinatarios pertenecientes a las siguientes categorías:</p>
        <ul>
          <li>Proveedores de préstamo.</li>
        </ul>
        <p>La prestación de nuestros Servicios incluye compartir sus datos personales con los Prestamistas para que decidan si pueden otorgarle o no un préstamo y para suscribir el contrato de préstamo. El propósito y la base legal de dicho intercambio de datos personales es poder llevar a cabo el Acuerdo existente entre nosotros y la correcta prestación de nuestros Servicios. El Prestamista usará sus datos personales con el único propósito de decidir si le otorga o no un préstamo. Sin embargo, tenga en cuenta que una vez que hayamos transferido sus datos personales al Prestamista para que pueda evaluar la posibilidad de concederle o no un préstamo, el Prestamista será el único responsable del tratamiento de sus datos personales. Por lo que debería familiarizarse con sus correspondientes políticas de privacidad. En caso de que el Prestamista decide no otorgarle un préstamo, deberá eliminar o anonimizar sus datos personales, a menos que exista una base legal válida para retenerlos.</p>
        <ul>
          <li>Proveedores de correo electrónico;</li>
          <li>Proveedores de servicios SMS;</li>
          <li>Proveedores de análisis;</li>
          <li>IT y marketing que procesan datos personales en los mismos servidores donde Opinomia almacena datos personales pueden tener acceso a estos datos personales;</li>
          <li>Auditores, autoridades (Las bases legales de este intercambio residen en la necesidad de cumplir con nuestras obligaciones legales).</li>
        </ul>
        <p>En caso de que necesite información detallada en relación con los proveedores de servicios relacionados con el tratamiento de sus datos de carácter personal por favor póngase en contacto con nosotros en la información de contacto.</p>
        <h3>¿Сuánto tiempo guardamos sus datos personales?</h3>
        <p>Conservamos sus datos de carácter personal durante el tiempo necesario para poder cumplir con el propósito con el que fueron entregados, así como durante el tiempo necesario para proteger sus derechos tal y como se exige en la normativa aplicable. Podremos conservar sus datos personales durante determinados años tras la finalización de nuestra relación en caso de que sea necesario de cara a proteger sus derechos de conformidad con lo establecido en la normativa aplicable. En el caso de que existan diferentes finalidades que den lugar al tratamiento de sus datos de carácter personal se conservarán conforme al plazo de mayor duración.</p>
        <p>De conformidad con el período máximo de prescripción para reclamaciones derivadas de estas operaciones, en virtud de la legislación aplicable, cuando la persona obligada incumpla intencionalmente sus obligaciones, podremos conservar sus datos personales relacionados con dichas reclamaciones durante un máximo de 10 años a partir de la fecha de vencimiento de la reclamación.</p>
        <h3>Sus derechos</h3>
        <p>Tal y como establece la normativa aplicable, Usted tiene todos los derechos reglamentariamente establecidos respecto de sus datos personales. Dichos derechos incluyen, entre otros, los siguientes:</p>
        <ul>
          <li>Solicitar acceso a sus datos personales;</li>
          <li>Obtener una copia de sus datos personales;</li>
          <li>Rectificar o completar datos incorrectos o imprecisos;</li>
          <li>Suprimir datos personales;</li>
          <li>Restringir el tratamiento de sus datos personales;</li>
          <li>Migración de sus datos personales;</li>
          <li>Oponerse al procesamiento de los datos personales, que se basa en el nuestro interés legítimo y en el tratamiento de datos personales con fines de marketing directo;</li>
          <li>proporcionar a Opinomia datos adicionales para que se completen los datos personales incompletos relacionados con el interesado de datos;</li>
          <li>presentar una queja ante una autoridad supervisora.</li>
        </ul>
        <p>Tenga en cuenta que los derechos relacionados con sus datos personales no son absolutos y que estarán sujetos a las consideraciones permitidas por la ley aplicable.</p>
        <p>Para ejercer sus derechos, por favor póngase en contacto con nosotros en la información de contacto.</p>
        <p>Si considera que los derechos en relación con sus datos personales han sido infringidos, le pedimos que por favor póngase en contacto con nosotros en la información de contacto.</p>
        <p>En caso de que nuestras prácticas de tratamiento de datos de carácter personal cambien o de que sea necesario modificar esta Política de Privacidad según lo establecido en la ley aplicable, la jurisprudencia o las directrices emitidas por las autoridades competentes, tenemos derecho a modificar unilateralmente esta Política de Privacidad en cualquier momento. En caso de que los cambios sean importantes y le repercutan de alguna manera se lo notificaremos por correo electrónico y les saltará un pop-up (ventana emergente) en nuestro Sitio Web con anterioridad a que dichas modificaciones entren en vigor.</p>
        <h3>Contacte con nosotros</h3>
        <p>En caso de que tenga alguna pregunta en relación con el tratamiento de sus datos personales por parte nuestra o si desea ejercer alguno de sus derechos, puede ponerse en contacto con nosotros a través de los siguientes datos de contacto: contacto@opinomia.com</p>
        <p>Opinomia responderá a la solicitud sin demora indebida y, en cualquier caso, en el plazo de un mes a partir de la recepción de la solicitud (ese período puede, teniendo en cuenta las circunstancias, prorrogarse dos meses). El tiempo de respuesta dependerá de la cantidad de solicitudes que reciba Opinomia y del contenido de una solicitud en particular.</p>
        <p>Política de Privacidad válida a partir del 20 de Septiembre de 2018.</p>
        <h3>Darse de baja</h3>
        <p>Si quiere darse de baja de toda nuestra publicidad, por favor escriba a <a href="mailto:darsedebaja@opinomia.com">darsedebaja@opinomia.com</a></p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Privacy',
  // components: {
  //   Isvg
  // },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      countryCodeName: state => state.countryCodeName,
      lang: state => state.lang
    })
  },
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
