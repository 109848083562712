<template>
  <div>
    <!-- main-block -->
    <section id="main-block" class="grey">
      <div class="container">
        <div class="main-block__left">
          <div>
            <h1>{{translation.scam_header[0]}}<br><i>{{translation.scam_header[1]}}</i></h1>
            <p class="main-block__p">{{translation.scam_subheader}}</p>
          </div>
        </div>
        <img src="../../assets/img/scam.jpg" alt="" class="main-block__img">
      </div>
    </section>
    <!-- main-block ends -->

        <!-- about-block -->
    <section id="about-block">
      <div class="container">
        <div class="about-block__first">
          <h4>¿Cómo evitar el fraude en préstamos por internet?</h4>
          <p>Hay empresas fraudulentas que ofrecen préstamos por internet para estafar. Se denuncian al menos 20 casos al día en España. Pero hay pistas para saber de que esta gran oferta que te ofrecen es probablemente una estafa.</p>
        </div>

        <div class="separate"></div>
        <h4>Consejos para evitar un fraude en los préstamos por internet</h4>
        <div class="wrap">
          <!-- 1 -->
          <div class="about-block__item">
            <img src="../../assets/img/i-scam-about-01.svg" alt="">
            <h5>¿Tienes que pagar por adelantado?</h5>
            <p>Es la estafa más común cuando se buscan préstamos online. Sus víctimas más frecuentes son personas con perfiles crediticios complicados que han tenido dificultades para encontrar financiación y que acuden a empresas desconocidas. Una empresa fiable sabe que pides dinero porque no lo tienes, así que evidentemente facilitará las condiciones para que lo obtengas rápido, pero NUNCA te cobrará NADA antes de darte el préstamo.</p>
          </div>
          <!-- 2 -->
          <div class="about-block__item">
            <img src="../../assets/img/i-scam-about-02.svg" alt="">
            <h5>¿Te han mostrado las comisiones de manera transparente?</h5>
            <p>Si una empresa te propone el dinero y no te muestra de manera transparente las comisiones que tienes que pagar, recomendamos preguntar a la atención al cliente  los términos y condiciones del préstamo rápido.</p>
          </div>
          <!-- 3 -->
          <div class="about-block__item">
            <img src="../../assets/img/i-scam-about-03.svg" alt="">
            <h5>¿Es la página fiable?</h5>
            <p>Nosotros recomendamos usar sólo servicios de comparación fiables cómo Opinomia que nunca publica ofertas de estafa.</p>
          </div>
          <!-- 4 -->
          <div class="about-block__item">
            <img src="../../assets/img/i-scam-about-04.svg" alt="">
            <h5>¿Has buscado las opiniones del prestamista?</h5>
            <p>Busca información en redes sociales, Google o Youtube. Lee los comentarios negativos para saber qué esperar.</p>
          </div>
        </div>
      </div>
    </section>
    <!-- about-block ends -->

    <section class="separate"></section>

    <!-- blacklist-block -->
<!--    <section id="blacklist-block" style="display: none">-->
<!--      <div class="container">-->
<!--        <h2>Lista negra de empresas en España</h2>-->
<!--        <p class="fszL">Companies who ask you to pay them for getting access to loan offers and then rebilling you:</p>-->
<!--        <div class="wrap">-->
<!--          &lt;!&ndash; 1 &ndash;&gt;-->
<!--          <div class="blacklist-block__item">-->
<!--            <img src="../../assets/img/logo-blacklist_1.png" alt="">-->
<!--          </div>-->
<!--          &lt;!&ndash; 2 &ndash;&gt;-->
<!--          <div class="blacklist-block__item">-->
<!--            <img src="../../assets/img/logo-blacklist_2.png" alt="">-->
<!--          </div>-->
<!--          &lt;!&ndash; 3 &ndash;&gt;-->
<!--          <div class="blacklist-block__item">-->
<!--            <img src="../../assets/img/logo-blacklist_3.png" alt="">-->
<!--          </div>-->
<!--          &lt;!&ndash; 4 &ndash;&gt;-->
<!--          <div class="blacklist-block__item">-->
<!--            <img src="../../assets/img/logo-blacklist_4.png" alt="">-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="blacklist-block__contacts">-->
<!--          <p>Contactos para reclamaciones y quejas:</p>-->
<!--          <p class="blacklist-block__contacts-p"><b>Teléfono:</b> 012 (91 580 42 60 si llama desde fuera de Madrid)</p>-->
<!--          <p class="blacklist-block__contacts-p"><b>Correo electrónico:</b> <a href="#">consultas.consumo@madrid.org</a></p>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <!-- blacklist-block ends -->

    <!-- form-block -->
    <section id="form-block" class="grey">
      <div class="container">
        <h2>{{translation.scam_problem_header}}</h2>
        <form style="max-width: 420px" @submit="sendFeedback($event)">
          <input v-model="form.email" type="text" placeholder="Email">
          <v-autocomplete
            style="width: 100%; margin-bottom: 0px"
            :items="filtered"
            :min-len="1"
            v-model="form.company"
            :placeholder="translation.scam_company_name"
            :get-label="getLabel"
            :component-item='template'
            @update-items="update"
            @item-selected="lenderSelected"
            @click.native="update(form.company)"
          >
          </v-autocomplete>
          <opi-select style="background: white;text-align: left;margin-top: -20px" v-model="form.problem"
                      :options="translation.scam_problem_type"
                      :text-large="true"
                      :placeholder="translation.scam_problem_placeholder"></opi-select>
          <textarea v-model="form.description" name="" :placeholder="translation.scam_problem" cols="30" rows="10"></textarea>
          <button>{{translation.scam_button}}</button>
        </form>
      </div>
    </section>
    <!-- form-block ends -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import Vue from 'vue'
import OpiSelect from '../assetComponents/OpiSelect'
import AutocompleteItem from '../components/AutocompleteItem.vue'

export default {
  name: 'Scam',
  components: {
    OpiSelect
  },
  data () {
    return {
      form: {
        email: '',
        description: '',
        problem: '',
        company: ''
      },
      filter: '',
      filtered: this.items,
      template: AutocompleteItem,
      lenderNotFromDB: ''
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      lang: state => state.lang,
      nameFromStore: state => state.user_data.name,
      emailFromStore: state => state.user_data.email,
      JWToken: state => state.JWToken,
      offers_info: state => state.offers_info,
      offers_list: state => state.offers_list,
      review_lender: state => state.review_lender
    }),
    items () {
      const temp = []
      for (const i in this.offers_list) {
        temp.push({ ...this.offers_list[i], img: (this.offers_info[this.offers_list[i].code] ? this.offers_info[this.offers_list[i].code].img : '') })
      }
      return temp
    }
  },
  mounted () {
    // this.form.email = this.emailFromStore
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    update (text) {
      this.lenderNotFromDB = text
      if (typeof text === 'object') return
      this.filtered = this.items.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    lenderSelected (val) {
      console.log(val)
      this.$store.dispatch('reviewLender', val)
    },
    sendFeedback (e) {
      e.preventDefault()
      console.log(this.form.company)
      const temp = this.form
      temp.company = temp.company ? temp.company.name : this.lenderNotFromDB
      axios({
        method: 'POST',
        url: '/api/scam_review',
        data: temp,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        console.log('success', response)
        Vue.$toast.success(this.translation.success, { position: 'top' })
        this.form = {
          email: '',
          description: '',
          problem: '',
          company: ''
        }
      }, (response) => {
        console.log('error', response)
        Vue.$toast.error(this.translation.error, { position: 'top' })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/scam.scss";
  @import "../../assets/sass/autocomplete.scss";
</style>
