<template>
  <div>
    <section id="main-block">
      <div class="container">
        <h1 class="tal">{{translation.cards_header}}</h1>
        <p class="subh1 tal">{{translation.cards_subheader}}</p>
      </div>
    </section>
    <!-- cards-block -->
    <section id="cards-block">
      <div class="container">
        <!-- LINK ADS -->
        <div class="feed-ads" style="padding: 0px; overflow: hidden;">
          <!-- Opinomia - offers -->
          <ins class="adsbygoogle feed-ads-ins"
               style="display:block"
               data-ad-client="ca-pub-6373705936907274"
               data-ad-slot="4339222842"
               data-ad-format="link"
               data-full-width-responsive="true">
          </ins>
        </div>
        <!-- 1 -->
        <div class="cards-block__item" v-for="(value, key) in filtered_offers_list" :key="key">
          <div class="cards-block__item-left">
            <img :src="require('../../assets/img/cards/' + value.name + '.png')" alt="">
            <div class="cards-block__item-left__rating">
              <Isvg name="star"/>
              <p class="cards-block__item-left__rating-rate">{{value.average_rating ? value.average_rating.toFixed(1) : '5.0'}}</p>
              <router-link @click.native="lenderSelected(value)" to="/review"
                           class="cards-block__item-left__reviews-link">{{translation.account_write_review}}</router-link>
            </div>
          </div>
          <div class="cards-block__item-content">
            <h3><i>{{cardTypeTranslation[value.card_type]}}</i> {{value.name}}</h3>
            <p class="cards-block__item-content__description">{{value.description}}</p>
            <div class="cards-block__item-content__param">
              <!-- 1 -->
              <div class="cards-block__item-content__param-item">
                <p>Red de cajeros automáticos</p>
                <p class="cards-block__item-content__param-item__value">{{value.atm_network}}</p>
              </div>
              <!-- 2 -->
              <div class="cards-block__item-content__param-item">
                <p>Retiro gratuito</p>
                <p class="cards-block__item-content__param-item__value">{{value.free_withdrawal}}</p>
              </div>
            </div>
            <a @click="offerClick(offers_info[value.code] ? offers_info[value.code].link : '', $event)"
               :href="offers_info[value.code] ? offers_info[value.code].link : ''"
               target="_blank"
               class="button small">Quiero mi tarjeta</a>
          </div>
        </div>
        <!-- AUTO ADS -->
        <div
             class="feed-ads"
             style="padding: 0px; overflow: hidden; order: 3; margin-top: 20px">
          <ins class="adsbygoogle feed-ads-ins"
               style="display:block"
               data-ad-client="ca-pub-6373705936907274"
               data-ad-slot="6773814492"
               data-ad-format="auto"
               data-full-width-responsive="true">
          </ins>
        </div>
      </div>
    </section>
    <!-- cards-block ends -->
    <div class="container">
      <h4 class="subh1 tal" style="text-align: center">Top préstamos rápidos</h4>
      <TopOffers :list-length="6" :show-all="false" :link-to-offers="true"/>
    </div>
    <!-- questions-block -->
    <section v-if="countryCodeName !== 'co'" id="questions-block">
      <div class="container">
        <!-- 1 -->
        <div v-for="(value, i) in translation.cards_questions" :key="i" class="questions-block__item">
          <div class="questions-block__item-header" :class="{active: questionsExpand[i]}" @click="expand(i)">
            <p>{{value[0]}}</p>
          </div>
          <div class="questions-block__item-body" :class="{active: questionsExpand[i]}">
            <p v-html="value[1]"></p>
          </div>
        </div>
      </div>
    </section>
    <!-- questions-block ends -->
    <!-- colombia block -->
    <section v-else class="add-info container">
      <div class="footer-bottom">
        <div class="footer-bottom__item footer-text">
          <!-- 1 -->
          <h4>¿Qué es una tarjeta de crédito?</h4>
          <p>Una tarjeta de crédito te permite hacer tus compras de una forma sencilla y muy rápida si no cuentas con el dinero en el momento. También lo puedes financiar a cuotas.</p>
          <p>La tarjeta de crédito no solo sirve para hacer pagos en tiendas. Puedes hacer compras también por internet.</p>
          <!-- 2 -->
          <h4>¿Qué es una tarjeta de crédito virtual?</h4>
          <p>Una tarjeta virtual es una tarjeta de pago que no tiene las condiciones físicas de las tarjetas de crédito o débito. En algunos casos estas tarjetas pueden tener un cuerpo físico, pero no poseen ni banda magnética, ni poseen ningún otro sistema que permita utilizarlas fuera del ámbito del pago por Internet.</p>
          <p>Vas a recibir el número de tarjeta, una fecha de caducidad y un número de control. Estos datos son necesarios para hacer las compras a través por internet, aunque pueden ser complementados con otras medidas de seguridad como la confirmación a través de un PIN o la confirmación a través de un código SMS.</p>
          <!-- 3 -->
          <h4>¿Hay beneficios que tienen las tarjetas de crédito?</h4>
          <p>Tarjetas de crédito pueden tener varios beneficios, por ejemplo:</p>
          <ul>
            <li>20% de descuento en tasa de interés</li>
            <li>Tarjeta de crédito por internet y sin cuota de manejo</li>
            <li>Devolución del 1% del valor de tus compras diferidas en dinero</li>
            <li>Menor pago por las compras realizadas por internet</li>
            <li>Tener acceso a alguna programa de lealtad</li>
            <li>Acumular puntos por cada compra que hagas en comercios</li>

          <!-- 4 -->
          <h4>¿Qué desventajas tienen las tarjetas virtuales?</h4>
          <p>La principal desventaja de las tarjetas de crédito virtuales es que no se puede utilizarlas para las compras en tiendas físicas ni se puede sacar el dinero de cajeros automáticos.</p>
          <p>También desde el punto de vista de seguridad hay que tener cuidado y asegurarse que se han aplicado las barreras adicionales tales como PIN o el código SMS. En el caso del PIN tienes que memorizarlo y no guardar en tu teléfono móvil o cualquier otro lugar.</p>
          <p>Debes controlar mucho los datos de estas tarjetas, sobre todo cuando existe una tarjeta virtual física que lleva acuñados los datos principales.</p>
          <!-- 5 -->
          <h4>¿Qué es límite de la tarjeta de crédito?</h4>
          <p>Es la máxima cantidad de dinero que el banco te permite gastar a través de tu tarjeta. No hay que buscar la tarjeta con mayor límite, mejor buscar la tarjeta que se adapte mejor a tu situación económica.</p>
          <!-- 6 -->
          <h4></h4>
          <p>Puedes completar la solicitud en nuestra página y recibir una selección personalizada de tarjetas de crédito con requisitos mínimos por parte del banco. Las entidades financieras presentan requisitos simples a los tomadores de préstamos sin estar comprobando de más:</p>
          <ul>
            <li>Edad a partir de 18</li>
            <li>Nacionalidad de Colombia</li>

        </ul></ul></div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'
import axios from 'axios'
import Vue from 'vue'
import TopOffers from '../components/TopOffers'

export default {
  name: 'Cards',
  components: {
    TopOffers,
    Isvg
  },
  data () {
    return {
      filtered_offers_list: [],
      questionsExpand: [false, false, false, false],
      registered: false,
      cardTypeTranslation: {
        debit: 'Tarjeta de Débito',
        credit: 'Tarjeta de Crédito'
      },
      hardcoreColombiaList: [
        {
          atm_network: 'todos',
          average_rating: 5,
          card_type: 'credit',
          code: 1,
          free_withdrawal: 'tarjeta digital',
          name: 'BBVA'
        },
        {
          atm_network: 'todos',
          average_rating: 5,
          card_type: 'credit',
          code: 2,
          free_withdrawal: 'Cuota de manejo: desde $0',
          name: 'Bancolombia'
        },
        {
          atm_network: 'todos',
          average_rating: 5,
          card_type: 'credit',
          code: 3,
          free_withdrawal: 'hasta $10.000.000',
          name: 'Bancodebogota'
        },
        {
          atm_network: 'todos',
          average_rating: 5,
          card_type: 'credit',
          code: 4,
          free_withdrawal: 'digital en linea',
          name: 'Finandina'
        }
      ],
      hardcoreColombiaInfo: {
        1: {
          link: 'https://www.bbva.com.co/personas/productos/tarjetas/credito.html'
        },
        2: {
          link: 'https://www.grupobancolombia.com/personas/tarjetas-de-credito'
        },
        3: {
          link: 'https://digital.bancodebogota.com/tarjeta-credito/index.html'
        },
        4: {
          link: 'https://www.bancofinandina.com/productos/tarjetas-credito'
        }
      },
      offers_info: {}
    }
  },
  computed: {
    ...mapState({
      showLogin: state => state.showLogin,
      showRegister: state => state.showRegister,
      translation: state => state.translation,
      lang: state => state.lang,
      JWToken: state => state.JWToken,
      offers_info_store: state => state.offers_info,
      compare_list: state => state.compare_list,
      compare_codes: state => state.compare_codes,
      countryCodeName: state => state.countryCodeName,
      leadId: state => state.leadId
    })
  },
  mounted () {
    this.$store.commit('SHOW_LOADER', true)
    const leadFromURL = this.$store.getters.getParameterByName('lead_id')
    if (leadFromURL) {
      this.$store.dispatch('productLinking', {
        event_type: 'product',
        external_uuid: leadFromURL,
        product_type_id: 3
      })
    }
    this.showAds()
    setTimeout(this.pushChooseCardsEvent, 1000)
    if (Object.keys(this.offers_info_store)) this.initCards()
  },
  methods: {
    initCards () {
      if (this.countryCodeName !== 'co') {
        this.getOffers()
        this.offers_info = this.offers_info_store
      } else {
        this.filtered_offers_list = this.hardcoreColombiaList
        this.offers_info = this.hardcoreColombiaInfo
        this.$store.commit('SHOW_LOADER', false)
      }
    },
    getOffers () {
      axios({
        method: 'GET',
        url: `/api/offers?filter=product_type_id;eq;3&country_code=${this.countryCodeName}`,
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + this.JWToken }
      }).then((response) => {
        this.$store.commit('SHOW_LOADER', false)
        // this.filtered_offers_list = response.data.data
        const temp = []
        for (const i in response.data.data) {
          // if (response.data.data[i].code === '254') {
          temp.push(response.data.data[i])
          // }
        }
        this.filtered_offers_list = temp
      }, (response) => {
        console.log('error login', response)
        this.$store.commit('SHOW_LOADER', false)
        this.$router.push('/')
      })
    },
    expand (i) {
      Vue.set(this.questionsExpand, i, !this.questionsExpand[i])
    },
    lenderSelected (val) {
      this.$store.dispatch('reviewLender', val)
    },
    offerClick (link, e) {
      if (!this.leadId) {
        e.preventDefault()
        this.$store.commit('SHOW_CARDS_FORM', true)
        this.$store.commit('UPDATE_CARDS_LINK', link)
      }
      this.$store.dispatch('pushGTMEvent', 'offerClick')
      this.$store.dispatch('pushFBEvent', {
        track: 'trackCustom',
        event: 'offerClick',
        data: { product: 3 }
      })
    },
    showAds () {
      const pushAds = () => {
        try {
          // eslint-disable-next-line no-undef
          (adsbygoogle = window.adsbygoogle || []).push({})
        } catch (e) {
          console.log(e)
        }
      }
      setTimeout(x => {
        pushAds()
        pushAds()
      }, 1000)
    },
    pushChooseCardsEvent () {
      const cmp = this.$store.getters.getParameterByName('utm_campaign')
      if (cmp && cmp.indexOf('es-mail-promo') > -1) {
        this.$store.dispatch('pushGTMEventWithParams', {
          event: 'chose-cards',
          lead_id: this.$store.getters.getParameterByName('lead_id')
        })
      }
    }
  },
  watch: {
    offers_info_store: function (val) {
      this.initCards()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/cards.scss";
  #main-block {
    margin-top: 80px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  .offers-block__item-stats__item {
    width: 100%;
    place-items: flex-start;
    span {
      text-align: right;
      &:first-of-type {
        text-align: left;
      }
    }
  }
  .add-info {
    text-align: left;
    margin: 40px 0px;
  }

  h4 {
      margin: 20px 0 10px!important;
      color: #333
  }

  .footer-bottom__item h4 {
      position: relative;
      padding-left: 0px;
      margin-top: 40px !important;
  }

  .footer-bottom__item p {
      font-size: 12px
  }

  .footer-bottom__item h4:before {
      display: none;
      width: 6px;
      height: 6px;
      position: absolute;
      left: 0;
      margin-top: 8px;
      content: "";
      background: #dedede;
      border-radius: 50%
  }

  .footer-bottom__item ol,.footer-bottom__item ul {
      margin-top: 10px;
      float: none
  }

  .footer-bottom__item ol li,.footer-bottom__item ul li {
      width: 100%!important;
      font-size: 12px;
      margin-top: 10px;
      display: list-item!important;
      margin-left: 20px!important
  }

  .footer-bottom__item ul li {
      list-style-type: disc
  }

  .footer-text {
      border: none
  }

</style>
