<template>
  <div style="padding-top: 70px">
    <!-- card-block -->
    <section id="card-block" class="first-section">
      <div class="container">
        <div class="breadcrumbs">
          <router-link to="/offers">{{translation.prestamos_link}}</router-link>
          <span> / </span>
          <span>{{review_lender ? review_lender.name : ''}}</span>
        </div>
        <!-- top -->
        <div class="card-block__top">
          <!-- logo -->
          <div class="card-block__top-logo">
            <img :src="offers_info[review_lender.code] ? offers_info[review_lender.code].img : ''" alt="">
          </div>
          <!-- right -->
          <div class="card-block__top-right">
            <Isvg name="filled-star"/>
            <p class="card-block__top-right__rating-rate">{{review_lender.average_rating ? review_lender.average_rating.toFixed(1) : '5.0'}}</p>
            <p class="card-block__top-right__rating-reviews">({{review_lender.comments}})</p>
          </div>
        </div>
        <!-- body -->
        <div class="card-block__body">
          <!-- conditions -->
          <div class="card-block__body-item">
            <h4>{{translation.condition}}:</h4>
            <div class="card-block__body-item__stats">
              <!-- 1 -->
              <!-- 1 -->
                <div class="card-block__body-item__stats-item">
                  <span>{{translation.offers_amount_range}}</span>
                  <span>{{beautify(review_lender.min_loan_sum)}} - {{beautify(review_lender.max_loan_sum)}} {{currency}}</span>
                </div>
                <!-- 1 -->
                <div class="card-block__body-item__stats-item">
                  <span>{{translation.offers_timing_range}}</span>
                  <span>{{beautifyDays(review_lender.min_loan_period)}} - {{beautifyDays(review_lender.max_loan_period)}}</span>
                </div>
                <!-- 1 -->
                <div class="card-block__body-item__stats-item">
                  <span>{{translation.offers_min_interest}}</span>
                  <span
                    v-if="review_lender.min_interest_rate === review_lender.max_interest_rate">{{review_lender.min_interest_rate}} {{translation.offers_percent}}</span>
                  <span v-else>{{review_lender.min_interest_rate}} - {{review_lender.max_interest_rate}} {{translation.offers_percent}}</span>
                </div>
                <!-- 1 -->
                <div class="card-block__body-item__stats-item">
                  <span>{{translation.offers_age_range}}</span>
                  <span v-if="review_lender.max_age !== 0">{{review_lender.min_age}} - {{review_lender.max_age}}
                    {{translation.offers_age}}</span>
                  <span v-else>-</span>
                </div>
                <!-- 1 -->
                <div class="card-block__body-item__stats-item">
                  <span>{{translation.offers_guarantee}}</span>
                  <span>{{review_lender.guarantee ? translation.yes : translation.no}}</span>
                </div>
              <!-- 1 -->
                <div class="card-block__body-item__stats-item">
                  <span>{{translation.asnef}}</span>
                  <span>{{review_lender.asnef ? translation.yes : translation.no}}</span>
                </div>
            </div>
          </div>
          <!-- description -->
          <div class="card-block__body-item">
            <h4>{{translation.description}}:</h4>
            <p>{{review_lender.description}}</p>
          </div>
          <a @click="$store.dispatch('pushGTMEvent', 'offerClick')"
             :href="offers_info[review_lender.code] ? offers_info[review_lender.code].link : ''"
             target="_blank"
             class="button">{{translation.offers_get_loan}}</a>
        </div>
      </div>
    </section>
    <!-- card-block ends -->

    <!-- separate -->
    <section id="separate">
      <div class="container">
        <div class="separate-line"></div>
      </div>
    </section>

    <!-- reviews-block -->
    <section id="reviews-block">
      <div class="container">
        <!-- rate -->
        <div class="reviews-block__rate">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18.7px" height="17.8px" viewBox="0 0 18.7 17.8" xml:space="preserve">
            <path class="star__svg" d="M10,0.5l2,5c0.1,0.3,0.4,0.4,0.6,0.5L18,6.2c0.7,0,0.9,0.9,0.4,1.3L14.3,11c-0.2,0.2-0.3,0.5-0.2,0.7l1.3,5.2
            c0.2,0.6-0.5,1.2-1.1,0.8l-4.5-2.9c-0.2-0.2-0.5-0.2-0.8,0l-4.5,2.9c-0.6,0.4-1.3-0.2-1.1-0.8l1.3-5.2c0.1-0.3,0-0.6-0.2-0.7
            L0.3,7.5C-0.2,7.1,0,6.3,0.7,6.2L6,5.9c0.3,0,0.5-0.2,0.6-0.5l2-5C8.9-0.2,9.8-0.2,10,0.5z"/>
          </svg>
          <p>{{review_lender.average_rating ? review_lender.average_rating.toFixed(1) : '5.0'}} ({{review_lender.comments}})</p>
        </div>

        <!-- data -->
        <div class="reviews-block__data">
          <!-- 1 -->
          <div v-for="(valueQ, indexQ) in ['difficulty', 'interest_rate', 'speed', 'support', 'satisfaction']"
            :key="indexQ" class="reviews-block__data-item">
            <span>{{translation['review_q' + (indexQ + 1)]}}</span>
            <div class="reviews-block__data-item__stat">
              <div class="reviews-block__data-item__stat-line">
                <div class="reviews-block__data-item__stat-line-green" :style="`width: ${review_lender[valueQ] * 20}%`"></div>
              </div>
              <span class="reviews-block__data-item__stat-number">{{review_lender[valueQ]}}</span>
            </div>
          </div>
        </div>

        <!-- filter -->
<!--        <div class="reviews-block__filter">-->
<!--          <span>Показывать:</span>-->
<!--          <div class="reviews-block__filter-dropdown">-->
<!--            <p>все отзывы</p>-->
<!--            <div class="reviews-block__filter-dropdown__arrow"></div>-->
<!--            <ul class="reviews-block__filter-dropdown__menu">-->
<!--              &lt;!&ndash; <li>все отзывы</li> &ndash;&gt;-->
<!--              <li>-->
<!--                <div class="reviews-block__filter-dropdown__stars stars5"></div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="reviews-block__filter-dropdown__stars stars4"></div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="reviews-block__filter-dropdown__stars stars3"></div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="reviews-block__filter-dropdown__stars stars2"></div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="reviews-block__filter-dropdown__stars stars1"></div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

        <!-- reviews -->
        <div class="wrap" id="reviews-start">
          <!-- 1 -->
          <div v-for="(value, index) in reviews_list"
               :key="index"
               class="reviews-block__item">
            <!-- top -->
            <div class="reviews-block__item-top">
              <!-- left -->
              <div class="reviews-block__item-top__left">
                <span class="reviews-block__item-top__left-name">{{value.name}}</span>
                <span class="reviews-block__item-top__left-date">{{value.created_at}}</span>
              </div>
              <!-- rate -->
<!--              <div class="reviews-block__item-top__rate"></div>-->
              <div class="reviews-block__item-top__rate">
                <div class="reviews-block__item-top__rate-green" :style="`width: ${value.average_rating * 20}%`"></div>
              </div>
            </div>
            <!-- body -->
            <div class="reviews-block__item-body">
<!--              <h5>{{value.title}}</h5>-->
              <p>{{value.comment}}</p>
            </div>
          </div>
<!--          <div class="reviews-block__button-wrap">-->
<!--            <button class="reviews-block__button transparent small">Показать все отзывы (93)</button>-->
<!--          </div>-->
        </div>
        <div style="width: 100%; text-align: left">
          <router-link to="/review" class="button small">{{translation.account_write_review}}</router-link>
        </div>
      </div>
    </section>
    <!-- reviews-block ends -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Isvg from '../assetComponents/Isvg'

export default {
  name: 'OfferCard',
  components: {
    Isvg
  },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      translation: state => state.translation,
      review_lender: state => state.review_lender,
      offers_info: state => state.offers_info,
      reviews_list: state => state.reviews_list,
      currency: state => state.currency
    })
  },
  mounted () {
    if (!this.review_lender.id) this.$router.push('/offers')
    setTimeout(x => this.$store.dispatch('getReviews').then((response) => {
    }, (response) => {
      this.$router.push('/offers')
    }), 100)
  },
  methods: {
    beautify (num) {
      return num ? num.toLocaleString() : ''
    },
    beautifyDays (days) {
      const months = Math.floor(days / 30)
      const years = Math.floor(months / 12)

      let result = days + ' ' + this.translation.offers_days
      if (months > 0) result = months + ' ' + this.translation.offers_months
      if (years > 0) result = years + ' ' + this.translation.offers_years
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/sass/offer-card.scss";
  .breadcrumbs {
    text-align: left;
    margin-bottom: 30px;
    a, span {
      font-size: 12px;
    }
    span {
      font-weight: 300;
      color: #9e9e9e;
    }
  }
  @media (min-width: 720px) {
    .breadcrumbs {
      a, span {font-size: 13px;}
    }
  }
  @media (min-width: 920px) {
    .breadcrumbs {
      a, span {font-size: 14px;}
    }
  }
  .compare-block__table-item {
    min-width: 120px !important;
  }
</style>
