<template>
  <div>
    <b style="line-height: 30px">{{item.name}}</b>
    <span style="position: absolute; right: 10px"><img style="max-width: 60px; height: 30px" :src="item.img"></span>
<!--    <abbr>{{item.description}}</abbr>-->
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script>
