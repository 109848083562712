<template>
  <div class="cookies-info" v-if="showCookie">
    <p>{{translation.cookiesText}}
      <router-link to="/cookies" target="_blank">{{translation.cookiesMoreText}}</router-link>.
    </p>
    <button @click="acceptCookies">{{translation.cookiesAcceptText}}</button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CookieBar',
  computed: {
    ...mapState({
      lang: state => state.lang,
      translation: state => state.translation
    })
  },
  data () {
    return {
      showCookie: false
    }
  },
  mounted () {
    if (this.getCookie('cookiePolicyPolitics') !== '1') this.showCookie = true
    setTimeout(x => {
      this.acceptCookies()
    }, 10000)
  },
  methods: {
    acceptCookies () {
      this.showCookie = false
      this.setCookie('cookiePolicyPolitics', 1, 365)
    },
    getCookie (cname) {
      var name = cname + '='
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    },
    setCookie (cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      var expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }
  }
}
</script>

<style scoped>
.cookies-info {
    position: fixed;
    bottom: 0;
    left: 50%;
    z-index: 11;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 5px 10px;
    font-family: Segoe UI,Open Sans,Arial,Helvetica Neue,sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.2;
    text-align: center;
    color: #999;
    background: hsla(0,0%,100%,.9);
    border-radius: 4px;
    -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
}

.cookies-info p,.home .cookies-info p {
    display: inline-block;
    margin-bottom: 0;
    padding: 6px 0 0;
    font-size: 11px
}

.cookies-info a {
    text-decoration: underline;
    color: #999
}

.cookies-info button {
    height: auto;
    visibility: hidden;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    margin-left: -33px;
    line-height: inherit;
    color: #fff;
    padding: 0;
    font-size: 100%;
    background: #cacaca;
    border: 1px solid #cacaca;
    border-radius: 7px;
    cursor: pointer;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}

.cookies-info button:hover {
    background: #cacaca;
    color: #fff;
    opacity: 1;
    border: 1px solid #cacaca
}

.cookies-info button:after {
    visibility: visible;
    content: "X";
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: auto;
    line-height: inherit;
    color: #fff;
    padding: 6px 5px;
    font-size: 100%;
    background: #5BDAA4;
    border: 1px solid #5BDAA4;
    border-radius: 50%;
    cursor: pointer;
    -webkit-transform: rotateX(45deg);
    transform: rotateX(45deg);
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease
}
</style>
