<template>
  <main class='container'>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <span class='particle'>4</span>
    <span class='particle'>0</span>
    <article class='content'>
      <p>Dear user,</p>
      <p>This page not found</p>
      <p>
        <router-link to="/">Main page</router-link>
      </p>
    </article>
  </main>
</template>

<script>
export default {
  name: 'Page404'
}
</script>

<style scoped>
body {
  margin: 0;
  font-size: 20px;
}

* {
  box-sizing: border-box;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: white;
  color: black;
  font-family: arial, sans-serif;
  overflow: hidden;
}

.content {
  position: relative;
  width: 600px;
  max-width: 100%;
  margin: 20px;
  background: white;
  padding: 60px 40px;
  text-align: center;
  box-shadow: -10px 10px 67px -12px rgba(0, 0, 0, 0.2);
  opacity: 0;
  animation: apparition 0.8s 1.2s cubic-bezier(0.39, 0.575, 0.28, 0.995) forwards;
}
.content p {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 0.6rem;
  letter-spacing: 0.1rem;
  color: #595959;
}
.content p:last-child {
  margin-bottom: 0;
}
.content button {
  display: inline-block;
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  border: 3px solid #595959;
  background: transparent;
  font-size: 1rem;
  color: #595959;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.particle {
  position: absolute;
  display: block;
  pointer-events: none;
}
.particle:nth-child(1) {
  top: 36.6706875754%;
  left: 13.6054421769%;
  font-size: 29px;
  filter: blur(0.02px);
  animation: 26s floatReverse2 infinite;
}
.particle:nth-child(2) {
  top: 4.9321824908%;
  left: 85.0642927794%;
  font-size: 11px;
  filter: blur(0.04px);
  animation: 35s floatReverse2 infinite;
}
.particle:nth-child(3) {
  top: 83.5965978129%;
  left: 86.0215053763%;
  font-size: 23px;
  filter: blur(0.06px);
  animation: 21s floatReverse infinite;
}
.particle:nth-child(4) {
  top: 91.7073170732%;
  left: 70.5882352941%;
  font-size: 20px;
  filter: blur(0.08px);
  animation: 37s floatReverse infinite;
}
.particle:nth-child(5) {
  top: 48.6618004866%;
  left: 16.6340508806%;
  font-size: 22px;
  filter: blur(0.1px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(6) {
  top: 73.5294117647%;
  left: 3.937007874%;
  font-size: 16px;
  filter: blur(0.12px);
  animation: 38s float2 infinite;
}
.particle:nth-child(7) {
  top: 42.9268292683%;
  left: 69.6078431373%;
  font-size: 20px;
  filter: blur(0.14px);
  animation: 33s floatReverse2 infinite;
}
.particle:nth-child(8) {
  top: 82.4242424242%;
  left: 3.9024390244%;
  font-size: 25px;
  filter: blur(0.16px);
  animation: 23s float2 infinite;
}
.particle:nth-child(9) {
  top: 87.4848116646%;
  left: 67.4486803519%;
  font-size: 23px;
  filter: blur(0.18px);
  animation: 30s float infinite;
}
.particle:nth-child(10) {
  top: 58.0411124547%;
  left: 55.5014605648%;
  font-size: 27px;
  filter: blur(0.2px);
  animation: 32s float2 infinite;
}
.particle:nth-child(11) {
  top: 82.8915662651%;
  left: 68.932038835%;
  font-size: 30px;
  filter: blur(0.22px);
  animation: 34s floatReverse infinite;
}
.particle:nth-child(12) {
  top: 10.6537530266%;
  left: 68.2261208577%;
  font-size: 26px;
  filter: blur(0.24px);
  animation: 33s float2 infinite;
}
.particle:nth-child(13) {
  top: 63.6911942099%;
  left: 75.8017492711%;
  font-size: 29px;
  filter: blur(0.26px);
  animation: 23s floatReverse2 infinite;
}
.particle:nth-child(14) {
  top: 42.3645320197%;
  left: 47.4308300395%;
  font-size: 12px;
  filter: blur(0.28px);
  animation: 39s float infinite;
}
.particle:nth-child(15) {
  top: 40.097799511%;
  left: 48.1335952849%;
  font-size: 18px;
  filter: blur(0.3px);
  animation: 31s floatReverse infinite;
}
.particle:nth-child(16) {
  top: 57.2121212121%;
  left: 35.1219512195%;
  font-size: 25px;
  filter: blur(0.32px);
  animation: 28s floatReverse infinite;
}
.particle:nth-child(17) {
  top: 70.7021791768%;
  left: 34.1130604288%;
  font-size: 26px;
  filter: blur(0.34px);
  animation: 29s floatReverse2 infinite;
}
.particle:nth-child(18) {
  top: 14.5808019441%;
  left: 10.752688172%;
  font-size: 23px;
  filter: blur(0.36px);
  animation: 34s float2 infinite;
}
.particle:nth-child(19) {
  top: 36.4532019704%;
  left: 47.4308300395%;
  font-size: 12px;
  filter: blur(0.38px);
  animation: 39s floatReverse2 infinite;
}
.particle:nth-child(20) {
  top: 73.0816077954%;
  left: 74.4368266405%;
  font-size: 21px;
  filter: blur(0.4px);
  animation: 32s float infinite;
}

@keyframes apparition {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes float {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(180px);
  }
}
@keyframes floatReverse {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-180px);
  }
}
@keyframes float2 {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(28px);
  }
}
@keyframes floatReverse2 {
  0%,100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-28px);
  }
}
</style>
