<template>
  <div style="padding-top: 70px">
    <section id="text-block">
      <div class="container" v-if="countryCodeName === 'ph'">
        <h1 class="breadcrumbs__title">Terms &amp; Conditions</h1>
        <h3><strong>Our Terms</strong></h3>
        <p>These Terms &amp; Conditions of Use (the Terms) set out the Terms by which you may make use of our Website <a href="https://opinomia.com">opinomia.com</a> (the Website). Use of the Website includes accessing, browsing, or registering to use the Website.</p>
        <p><strong>It is important that you carefully read these Terms before you use the Website, as they apply to your use of the Website. Please feel free to download or print a copy of these Terms for your own future reference. It is your responsibility to ensure you are aware of the nature of these Terms and how they may affect your use of the Website.</strong></p>
        <p>By using the Website, you confirm that you accept these Terms and that you agree to comply with them. If you do not agree to these Terms, you should exit the Website immediately and we request that you do not use our service.</p>
        <p>For information on how we use personal data that we may collect through the Website and/or in relation to the services that we provide via the Website please see our Privacy Policy. For details on our use of Cookies please see our Cookies Statement contained within our Cookies Policy. Both our Privacy Policy and our Cookies Statement are incorporated by reference into this document.</p>
        <h3><strong>About us</strong></h3>
        <p>Opinomia is a Trading Name of Fininity Ltd which is registered in Estonia (Company number 14523902), Registered Office: Tartu road 84a – M302, 10112 Tallinn, Estonia.</p>
        <h3><strong>Our service</strong></h3>
        <p>opinomia.com is owned and operated by Fininity Ltd.</p>
        <p>We are not a Lender or Provider and therefore we will not provide finance directly to you; we provide an online loan matching service, we will introduce you to Lenders or Providers of financial services. By submitting an application through our Iframe form you will be giving your consent for Fininity Ltd to match your application with one of the Lenders or Providers on our panel.</p>
        <p>There is no obligation for you to take up the loan or service with them. You should read their conditions carefully and make a responsible decision on whether you believe their service is right for you. If you have any questions or are unsure you should contact the Lender or Provider before taking out any service or loan with them.</p>
        <p>Any examples of amounts available to borrow, terms (repayment periods) or rates stated on the Lender or Provider Websites are purely given as a representative example relating to the product. These representative examples are stated to give you an understanding of the products available. Representative examples are not given as a figure of what you will actually be offered by the Lender/Finance Provider therefore cannot be given as a guarantee nor can they be used as any kind of warranty. As we do not provide the loan or financial service directly to you we are unable to control the representative rate examples contained on the Website. Please also be aware that the decision to offer any loan or financial service is made by the Lender or Finance Provider and we have no control over the decision making.</p>
        <p>The Lender/Finance Provider will conduct an assessment on you before any decision or offer is made; this may include a full assessment of your credit history both past and present.</p>
        <h3><strong>Accessing the Website</strong></h3>
        <ul>
          <li>Access to the Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the service which we provide on the Website without notice (see below). We will not be liable if for any reason the Website is unavailable at any time or for any period.</li>
          <li>From time to time, we may restrict access to some parts of the Website, or the entire Website, to users who have registered with us.</li>
        </ul>
        <h3>&nbsp;<strong>Intellectual property rights</strong></h3>
        <ul>
          <li>All Intellectual Property Rights (including, without limitation, all database rights, rights in designs, rights in know-how, patents and rights in inventions (in all cases whether registered or unregistered and including all rights to apply for registration) and all other intellectual or industrial property rights in any jurisdiction) in any information, content, materials, data or processes contained in or to this Website belong to Fininity Ltd or its licensed source. All rights of Fininity Ltd in such Intellectual Property Rights are hereby reserved.</li>
          <li>You may print off a copy, and may download extracts, of any page(s) from the Website for your personal use.</li>
          <li>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</li>
        </ul>
        <h3>&nbsp;<strong>Accuracy Of Information</strong></h3>
        <ul>
          <li>Fininity Ltd does not represent that the information contained in this Website is accurate, comprehensive, verified or complete, and shall accept no liability for the accuracy or completeness of the information contained in this Website or for any reliance placed by any person on the information.</li>
          <li>We aim to update our site regularly and may change the content at any time. If the need arises, we may suspend access to our site, or close it indefinitely.</li>
          <li>We reserve the right to change these terms and conditions at any time by posting changes on the Website. It is your responsibility to review the Website terms and conditions regularly to ensure you are aware of the latest terms and conditions. Your use of this Website after a change has been posted will be deemed to signify your acceptance of the modified terms and conditions.</li>
        </ul>
        <h3><strong>Customer checks &amp; referencing</strong></h3>
        <p>Upon application the Lender or Finance Provider may use both fraud prevention and credit referencing agencies to help make relevant decisions. Credit checks and credit reference agencies can also help prevent fraudulent applications so we ask you to agree to using data provided for the purpose of preventing fraud and ensuring that your identity is protected.</p>
        <p>Information provided by you must always be true and accurate, as misleading information will be regarded as a breach of our terms and is likely to breach any agreement you have entered into with a Lender or Finance Provider. Misleading information may also result in the instigation of criminal procedures against you.</p>
        <p>If you have authorised any third parties to act on your behalf any information given by the third party will be contractually binding on you. Should you choose to make a joint application for a loan or finance via the Website each party will be dealt with as a client acting with the consent of the other and if correspondence is entered into with one party of the joint application it shall be assumed that the communication is with both parties.</p>
        <p>Information about you (including your visits to the Website) we will only process information about you in accordance with the privacy policy. When you use our Website and form you consent to us processing your personal information and therefore warrant that all information/data provided by you is accurate and correct.</p>
        <h3><strong>Communication</strong></h3>
        <p>Unless stated otherwise we may contact you via means of telephone, SMS, post or email to provide information and alerts relating to your application and or services We think may be of interest to You.</p>
        <h3><strong>Acceptable Use</strong></h3>
        <p>You may only use our site &amp; iframe form for lawful purposes. You may not use our site/form:</p>
        <ul>
          <li>In any way that breaches any applicable local, national, or international law or regulation.</li>
          <li>In any way that is unlawful or fraudulent or has any unlawful or fraudulent purpose or effect.</li>
          <li>to upload or transmit through the form: any computer viruses or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer</li>
          <li>to upload or transmit through the form: any material which is defamatory, offensive or of an obscene character.</li>
        </ul>
        <h3><strong>Links From The Website</strong></h3>
        <ul>
          <li>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them</li>
        </ul>
        <h3><strong>Jurisdiction</strong></h3>
        <p>The Website &amp; form is controlled and operated in the Philippines. These Terms and any non-contractual obligations arising out of or in connection with these Terms will be governed by the laws of the Philippines and you irrevocably agree to submit to the exclusive jurisdiction of the Philippine courts.</p>
        <h3><strong>Our marketing partners will also be informed of your request</strong></h3>
        <h3><strong>Complaints</strong></h3>
        <p>We endeavour to make every effort to ensure our service to you is excellent; however, if you wish to make a formal complaint, please send your complaint via email to <a href="mailto:complaints@opinomia.com">complaints@opinomia.com</a>.</p>
        <h3><strong>Last Updated September 2019</strong></h3>
      </div>
      <div class="container" v-else-if="countryCodeName === 'vn'">
        <h1>Điều khoản và điều kiện</h1>
        <h3><strong>
          Các
          điều khoản của chúng tôi</strong></h3>
        <p>
          Những
          điều khoản và điều kiện sử dụng (Điều khoản) tạo
          thành các điều khoản mà theo đó bạn (Người dùng) có
          thể sử dụng trang web của chúng tôi, opinomia.com (Trang web).
          Việc sử dụng trang web bao gồm nhưng không giới hạn ở
          việc truy cập, duyệt web hoặc cung cấp thông tin cá nhân
          để đăng ký sử dụng trang web.
        </p>
        <p><strong>
          Chúng
          tôi khuyên bạn nên đọc kỹ các Điều khoản này trước
          khi sử dụng trang web.  Chúng được áp dụng trực tiếp
          khi bạn truy cập trang web. Bạn dược phép tải xuống
          hoặc in ra một bản sao chép các Điều khoản này để
          tham khảo trong tương lai. Bạn có trách nhiệm đảm bảo
          rằng bạn hiểu nội dung của các Điều khoản này và
          cách chúng có thể ảnh hưởng đến việc bạn sử dụng
          trang web.</strong></p>
        <p>
          Bằng
          cách sử dụng trang web, bạn xác nhận rằng bạn chấp
          nhận các Điều khoản này và đồng ý tuân thủ. Nếu
          bạn không đồng ý với các Điều khoản này, chúng tôi
          yêu cầu bạn thoát khỏi trang web và ngừng sử dụng các
          dịch vụ của chúng tôi ngay lập tức.
        </p>
        <p>
          Vui
          lòng xem Chính sách quyền riêng tư của chúng tôi để
          hiểu rõ hơn cách chúng tôi sử dụng dữ liệu cá nhân
          được thu thập thông qua trang web. Vui lòng xem Tuyên bố
          về cookie có trong Chính sách cookie của chúng tôi để
          biết chi tiết về việc sử dụng Cookies. Chính sách bảo
          mật và Tuyên bố cookie của chúng tôi đều được đưa
          vào tài liệu này. bằng phương pháp tham chiếu.
        </p>
        <h3><strong>
          Công
          ty</strong></h3>
        <p>opinomia.com
          là tên thương mại của Công ty Fininity Ltd, đươc đăng
          ký tại Việt nam (mã số công ty: 14523902), văn phòng đăng
          ký: Tartu mnt 52-166, 10115 Tallinn, Estonia.
        </p>
        <h3><strong>
          Dịch
          vụ</strong></h3>
        <p> opinomia.com do Công ty Fininity Ltd sở hữu và vận hành.</p>
        <p>
          Chúng
          tôi không phải Bên cho vay hay Nhà cung cấp. Vì vậy, chúng
          tôi không bao giờ cung cấp tài chính trực tiếp cho bạn.
          Chúng tôi cung cấp dịch vụ kết nối cho vay trực tuyến.
          Chúng tôi sẽ giới thiệu các Bên cho vay hoặc Nhà cung
          cấp dịch vụ tài chính cho bạn. Bằng cách nộp đơn
          đăng ký trên trang web của chúng tôi, bạn đồng ý cho
          Công ty Fininity Ltd kết nối đơn đăng ký của bạn với
          một trong các Bên cho vay hoặc Nhà cung cấp trong mạng
          lưới của chúng tôi. Thực tế, chúng tôi sẽ gửi đơn
          của bạn cho một hoặc nhiều Bên cho vay hoặc Nhà cung
          cấp, tùy thuộc vào tiêu chí cho vay của họ, cho đến
          khi một trong các Bên cho vay hoặc Nhà cung cấp chấp nhận
          đơn đăng ký của bạn hoặc tất cả Bên cho vay và Nhà
          cung cấp từ chối đơn đăng ký của bạn. Nếu một Bên
          cho vay hoặc Nhà cung cấp phê duyệt đơn đăng ký của
          bạn, bạn sẽ dược chuyển đến trang web của Bên cho
          vay hoặc Nhà cung cấp đó. (Nếu Bên cho vay hoặc Nhà cung
          cấp không trực tuyến, chúng tôi có thể hiển thị các
          thông tin và các thức liên lạc của Bên cho vay hoặc Nhà
          cung cấp đã phê duyệt đơn của bạn.)
        </p>
        <p>
          Trong
          mọi trường hợp, chúng tôi chỉ chuyển bạn đến Bên
          cho vay hoặc Nhà cung cấp đã xem xét và chấp thuật đơn
          đăng ký, trên cơ sở họ có thể cho bạn vay. Chúng tôi
          có thể được Bên cho vay hoặc Nhà cung cấp trả phí khi
          kết nối bạn với họ.
        </p>
        <p>
          Bạn
          không có nghĩa vụ phải vay tiền của họ. Chúng tôi
          khuyên bạn nên đọc kỹ Điều khoản và điều kiện của
          từng Bên cho vay hoặc Nhà cung cấp để đưa ra quyết
          định sáng suốt về việc đề nghị của họ có phải
          là đề nghị phù hợp nhất với bạn hay không. Nếu có
          bất kỳ nghi ngờ nào, chúng tôi khuyên bạn nên liên hệ
          với Bên cho vay hoặc Nhà cung cấp trước khi đồng ý với
          bất kỳ khoản vay nào từ phía họ.
        </p>
        <p>
          Bất
          kỳ điều gì như số tiền mặt có sẵn để vay, thời
          gian trả nợ hoặc lãi suất được đưa ra trên trang web
          của Bên cho vay hoặc Nhà cung cấp chỉ là ví dụ đại
          diện liên quan đến sản phẩm. Những ví dụ đại diện
          này không phải là những điều mà Bên cho vay hoặc Nhà
          cung cấp sẽ thực sự cung cấp cho bạn. Do đó, chúng
          không được bảo đảm và không thể được sử dụng
          làm điều kiện đảm bảo.
        </p>
        <p>
          Thực
          tế là chúng tôi không bao giờ cung cấp bất kỳ khoản
          vay hoặc dịch vụ tài chính nào trực tiếp cho bạn, bất
          kỳ ví dụ về lãi suất nào trên các trang web của Bên
          cho vay hoặc Nhà cung cấp hoàn toàn nằm ngoài tầm kiểm
          soát của chúng tôi. Ngoài ra, quyết định cung cấp bất
          kỳ khoản vay nào tùy thuộc vào Bên cho vay hoặc Nhà cung
          cấp; chúng tôi không kiểm soát hay chịu trách nhiệm đối
          với quyết định gia hạn hoặc từ chối khoản vay.
        </p>
        <p>
          Bên
          cho vay hoặc Nhà cung cấp sẽ đánh giá lý lịch của bạn
          trước khi đưa ra quyết định hoặc gia hạn đề xuất.
          Điều này có hoặc không bao gồm đánh giá đầy đủ về
          lịch sử tín dụng trong quá khứ và hiện tại của bạn.
        </p>
        <h3><strong>
          Truy
          cập trang web</strong></h3>
        <ul>
          <li>
            Chúng
            tôi cho phép truy cập vào trang web một cách tạm thời.
            Chúng tôi có quyền sửa đổi, hủy bỏ hoặc trì hoãn
            dịch vụ được cung cấp trên trang web mà không cần
            thông báo trước. Chúng tôi không chịu trách nhiệm nếu
            trang web không khả dụng trong một thời điểm, trong bất
            kỳ khoảng thời gian hoặc vì bất kỳ lý do nào.
          </li>
          <li>
            Đôi
            khi, chúng tôi có thể hạn chế quyền truy cập vào một
            vài phần hoặc cả trang web đối với người dùng đã
            đăng ký.
          </li>
        </ul>
        <h3><strong>
          Độ
          chính xác thông tin</strong></h3>
        <ul>
          <li>
            Công
            ty Fininity Ltd không đòi hỏi thông tin trên trang web này,
            bằng bất cứ cách nào, phải được xác minh, toàn diện,
            chính xác hoặc đầy đủ. Công ty sẽ không chịu trách
            nhiệm về tính chính xác hoặc đầy đủ của thông tin
            trong trang web này hoặc cho bất kỳ sự nhờ cậy nào tác
            động đến thông tin do bất kỳ người nào thực hiện.
          </li>
          <li>
            Chúng
            tôi dự định cập nhật trang web thường xuyên. Chúng
            tôi có thể thêmthay đổi hoặc xóa nội dung của trang
            web bất cứ lúc nào. Nếu cần thiết, chúng tôi có thể
            tạm ngừng truy cập hoặc vô hiệu hóa trang web vô thời
            hạn.
          </li>
          <li>
            Chúng
            tôi có quyền đăng các thay đổi trên trang web, sửa đổi
            các điều khoản và điều kiện này bất cứ lúc nào.
            Bạn có trách nhiệm thường xuyên xem xét các điều
            khoản và điều kiện trên Website của chúng tôi. Sử
            dụng trang web này sau khi thay đổi đã được đăng nghĩa
            là bạn chấp nhận các điều khoản và điều kiện được
            sửa đổi
          </li>
        </ul>
        <h3><strong>
          Liên
          kết ngoài</strong></h3>
        <ul>
          <li>
            Mọi
            liên kết được cung cấp cho các trang web khác hoặc tài
            nguyên của bên thứ ba chỉ được cung cấp làm thông
            tin tham khảo cho bạn. Chúng tôi không kiểm soát với nội
            dung các tài nguyên hoặc trang web này. Chúng tôi không
            chịu trách nhiệm cũng như nghĩa vụ pháp lý nào với họ
            hoặc đối với bất kỳ thiệt hại hoặc mất mát nào
            mà bạn có thể phải chịu khi bạn sử dụng chúng.
          </li>
        </ul>
        <h3><strong>
          Chấp
          thuận sử dụng</strong></h3>
        <p>
          Bạn
          chỉ được phép sử dụng mẫu iframe và trang web của
          chúng tôi cho các mục đích hợp pháp. Bạn không được
          phép sử dụng trang web/mẫu của chúng tôi, nếu:
        </p>
        <ul>
          <li>
            Vi
            phạm bất kỳ quy định luật pháp hoặc quy định địa
            phương, quốc gia hoặc quốc tế bằng bất kỳ cách nào.
          </li>
          <li>
            Gian
            lận hoặc bất hợp pháp, hoặc có bất kỳ hiệu ứng
            gian lận hoặc bất hợp pháp bằng bất kỳ cách nào.
          </li>
          <li>
            để
            truyền qua biểu mẫu hoặc tải lên bất kỳ tài liệu
            phỉ báng, xúc phạm hoặc tục tĩu nào.
          </li>
          <li>
            để
            truyền qua biểu mẫu hoặc tải lên bất kỳ vi-rút máy
            tính, phần mềm độc hại hoặc bất kỳ thứ gì khác
            được thiết kế để can thiệp, làm gián đoạn hoặc
            phá vỡ các quy trình hoạt động bình thường của máy
            tính
          </li>
        </ul>
        <h3><strong>
          Trách
          nhiệm pháp lý</strong></h3>
        <ul>
          <li>
            Trong
            chừng mực được pháp luật cho phép, chúng tôi loại
            trừ tất cả các bảo đảm, đại diện, điều kiện
            hoặc các Điều khoản khác có thể áp dụng cho trang web
            này hoặc bất kỳ nội dung nào trên đó, cho dù rõ ràng
            hay ngầm định.
          </li>
          <li>
            Chúng
            tôi không thể và sẽ không đảm bảo rằng bất kỳ Bên
            cho vay hoặc Nhà cung cấp nào sẽ phê duyệt đơn xin vay
            cá nhân nào. Đây có thể là kết quả trực tiếp hoặc
            gián tiếp của các yếu tố khác nhau, bao gồm lỗi hệ
            thống, các sự cố không lường trước được hoặc nằm
            ngoài tầm kiểm soát của chúng tôi hoặc của Bên cho
            vay hoặc Nhà cung cấp hoặc thông tin được cung cấp
            không chính xác.
          </li>
          <li>
            Trừ
            trường hợp do chúng tôi sơ suất, không tuân thủ các
            Điều khoản và điều kiện này, hoặc các trường hơp
            khác theo luật pháp Việt Nam, chúng tôi sẽ không chịu
            trách nhiệm cho bất kỳ tổn thất nào bạn có thể phải
            chịu. Nếu bạn không sử dụng biểu mẫu này một cách
            cẩn thận và tuân theo các điều khoản và điều kiện
            này, hoặc nếu chúng tôi phát hiện/được thông báo
            rằng bạn đã gian lận khi sử dụng biểu mẫu này,
            chúng tôi, cùng với Bên cho vay hoặc Nhà cung cấp, có
            quyền tính phí cho bạn mọi chi phí hợp lý mà chúng tôi
            có thể phải chịu khi chúng tôi trả lại bất kỳ khoản
            tiền nào bạn còn nợ và khiến bạn không sử dụng
            biểu mẫu.
          </li>
        </ul>
        <h3><strong>
          Quyền
          sở hữu trí tuệ</strong></h3>
        <ul>
          <li>
            Tất
            cả các quyền sở hữu trí tuệ (bao gồm nhưng không
            giới hạn là tất cả các quyền cơ sở dữ liệu, quyền
            về bí quyết, quyền và bằng sáng chế trong các sáng
            chế (trong mọi trường hợp, dù đã đăng ký hay chưa
            đăng ký, và bao gồm tất cả các quyền đăng ký), quyền
            trong thiết kế và tất cả các quyền sở hữu trí tuệ
            hoặc quyền sở hữu công nghiệp khác trong bất kỳ khu
            vực tài phán nào) đối với mọi nội dung, thông tin
            bằng văn bản, tài liệu, quy trình hoặc dữ liệu có
            trong hoặc trên trang web này thuộc về công ty TNHH
            Fininity Ltd hoặc nguồn được cấp phép của công ty này.
            Như vậy, tất cả các quyền của công ty TNHH Finality
            trong Quyền sở hữu trí tuệ đều được bảo lưu.
          </li>
          <li>
            Bạn
            được phép in ra một bản sao và tải xuống các trích
            đoạn của bất kỳ trang nào từ trang web phục vụ cho
            mục đích sử dụng cá nhân.
          </li>
          <li>
            Bạn
            không được phép sửa đổi bản sao bản cứng hoặc bản
            mềm của bất kỳ thông tin nào trên trang web dưới bất
            kỳ hình thức nào. Bạn không được phép sử dụng bất
            kỳ hình ảnh minh họa, hình ảnh, file âm thanh hoặc
            video hoặc bất kỳ đồ họa riêng biệt với văn bản.
          </li>
        </ul>
        <h3><strong>
          Kiểm
          tra khách hàng</strong></h3>
        <p>
          Khi
          bạn gửi đơn đăng ký, Bên cho vay hoặc Nhà cung cấp có
          thể thuê các cơ quan phòng chống gian lận và giới thiệu
          tín dụng để đưa ra các quyết định liên quan. Do các
          cơ quan kiểm tra tín dụng và tham chiếu tín dụng hỗ trợ
          ngăn chặn các ứng dụng lừa đảo, chúng tôi yêu cầu
          bạn đồng ý với việc sử dụng dữ liệu này của bạn
          để đảm bảo bảo vệ danh tính của bạn.
        </p>
        <p>
          Bạn
          phải luôn cung cấp thông tin chính xác và đúng sự thật.
          Thông tin sai lệch sẽ được coi là vi phạm các điều
          khoản của chúng tôi. Điều này cũng tương tự với việc
          vi phạm bất kỳ thỏa thuận Bên cho vay hoặc Nhà cung cấp
          nào. Cố ý cung cấp thông tin sai lệch có thể dẫn đến
          các cáo buộc hình sự.
        </p>
        <p>
          Nếu
          bạn ủy quyền cho bất kỳ bên thứ ba nào thay mặt bạn,
          mọi thông tin mà bên thứ ba cung cấp sẽ được ràng
          buộc theo hợp đồng đối với bạn. Nếu bạn chọn gửi
          đơn đăng ký chung để được hỗ trợ tài chính hoặc
          vay tiền qua trang web, mỗi bên sẽ được xử lý với tư
          cách là khách hàng, hành động được sự đồng ý của
          bên còn lại. Nếu bất kỳ bên nào trong đơn đăng ký
          chung trao đổi liên lạc thư từ, thì điều này sẽ được
          coi là bất kỳ và tất cả việc liên hệ này được
          thực hiện với cả hai bên.
        </p>
        <p>Chúng tôi sẽ chỉ xử lý thông tin về bạn (bao gồm nhưng không giới hạn số lượt truy cập của bạn vào trang web), theo Chính sách bảo mật của chúng tôi. Bằng cách sử dụng trang web và biểu mẫu, bạn đồng ý cho chúng tôi xử lý thông tin cá nhân của bạn. Do đó, bạn cũng đảm bảo rằng tất cả dữ liệu và thông tin do bạn cung cấp là đúng và chính xác.</p>
        <h3><strong>
          Trao
          đổi/liên hệ</strong></h3>
        <p>
          Chúng
          tôi có thể trao đổi với bạn qua điện thoại, tin nhắn
          SMS, bài đăng, email và/hoặc tin nhắn tự động để cung
          cấp các cảnh báo liên quan đến ứng dụng, thông tin
          và/hoặc dịch vụ của có thể bạn quan tâm.
        </p>
        <h3><strong>
          Quyền
          hạn</strong></h3>
        <p>
          Trang
          web & biểu mẫu được vận hành và kiểm soát tại
          Việt Nam. Các Điều khoản này cùng với mọi nghĩa vụ
          ngoài hợp đồng phát sinh từ hoặc liên quan đến các
          Điều khoản này sẽ chịu sự kiểm soát của pháp luật
          Việt Nam. Bạn bắt buộc phải đồng ý nhượng bộ quyền
          tài phán độc quyền của tòa án Việt Nam bằng cách sử
          dụng trang web.
        </p>
        <h3><strong>
          Xóa
          bỏ thông tin cá nhân</strong></h3>
        <p>Nếu bạn muốn chắc chắn rằng các quyền của mình bị lãng quên và yêu cầu tháo gõ hoặc xóa bỏ thông tin cá nhân, gửi email tới&nbsp;<a>support@opinomia.com</a>.</p>
        <h3><strong>
          Khiếu
          nại</strong></h3>
        <p>Chúng tôi cố gắng đảm bảo rằng bạn luôn hài lòng với dịch vụ của chúng tôi. Tuy nhiên, nếu bạn muốn gửi khiếu nại chính thức, hãy gửi thư tới&nbsp;<a>support@opinomia.com</a>.</p>
        <h3><strong>
          Cập
          nhật lần cuối tháng 3 năm 2020</strong></h3>
      </div>
      <div class="container" v-else>
        <h1>Términos &amp; Condiciones</h1>
        <h3>Introducción</h3>
        <p>Esta página web (en adelante «Web») es propiedad de Fininity Ltd. (N° de registro: 14523902, con domicilio: calle Tartu 84a, Tallinn, 10112, EE).</p>
        <p>Si usted utiliza nuestros servicios y proporciona su información personal, usted acepta y se compromete a cumplir todas las Condiciones mencionadas adelante. Por favor, lea nuestros Términos y Condiciones.</p>
        <p>Nos reservamos el derecho a cambiar estos Términos y Condiciones en cualquier momento sin previo aviso. Usted es el único responsable y se compromete a revisar periódicamente los Términos y Сondiciones de esta Web.</p>
        <p>Todos los servicios de Fininity Ltd. están sujetos a las presentes Terminos y Condiciones del uso de la Web.</p>
        <h3>El fin de nuestra web</h3>
        <p>El fin de esta Web es recopilar las ofertas de préstamos online, dar información sobre las ofertas del mercado y elegir la mejor oferta para Usted. El uso de esta Web (opinomia.com) es gratuito. Los proveedores de préstamos pagan a Fininity Ltd. por el servicio de envío los solicitudes y datos de clientes.
        </p><p>La Web no proporciona préstamos sino recopila y compara las ofertas de terceros.</p>
        <p>La Web muestra los enlaces desde esta Web a sitios web de terceros únicamente con fines informativos. No nos hacemos responsables de su contenido, ya que no comprobamos estos sitios web y ellos no pertenecen a nosotros.</p>
        <p>Fininity Ltd. también recopila todos los datos relativos de usted y los proporciona a los proveedores de préstamos de Opinomia via API para encontrar mejor oferta para usted.  Fininity Ltd. no es capaz de reenviar información crediticia sobre usted y sobre el préstamo que desea recibir, a menos que rellene todas las casillas del formulario de la solicitud y clique el botón.</p>
        <h3>Condiciones de uso</h3>
        <p>Los usuarios de la Web sólo pueden ser personas mayores de 18 años. Los menores de 18 años no pueden usar nuestros servicios y por lo tanto no están autorizados a enviarnos sus datos personales.
        </p><p>Usted es el único responsable del uso de la Web. Proporcionando su información personal en esta Web usted acepta que esta información no será confidencial. Sus datos y datos relativos al crédito que desea recibir pueden ser compartidos con las empresas financieras de terceros. La entrega de tal información facilitará a los proveedores de préstamos tomar la decisión adecuada sobre concederlo, y en tal caso, los términos y condiciones.</p>
        <h3>Legislación aplicable</h3>
        <p>Los presentes Términos y Condiciones se someterá a las leyes en vigor en el Reino de España.</p>
        <h3>Limitación de la responsabilidad</h3>
        <p>La Web puede contener los enlaces de terceros, pero no garantiza ni asume responsabilidad en cuanto a la exactitud y la actualidad del contenido de la información de terceros en la  Web y no se responsabiliza de los daños derivados del uso de su contenido. En cualquier momento, sin notificación previa y sin justificación,  Fininity Ltd. puede dar por terminado la prestación de sus servicios.</p>
        <p>Nosotros no garantizamos que esta Web funcione ininterrumpidamente o esté libre de errores.</p>
        <p>Fininity Ltd. no asume ninguna responsabilidad acerca de las decisiones del usuario, tomadas basándose en la información presentada en la Web. No nos responsabilizamos de cualquier daño que puede causar el uso de la información exhibida en la Web.</p>
        <p>Fininity Ltd. no asume ninguna responsabilidad por las decisiones tomadas por un proveedor de préstamo a la base de la información enviada por Opinomia a los proveedores de préstamos.</p>
        <h3>Protección de datos</h3>
        <p> Fininity Ltd. se compromete a tratar con la máxima privacidad, seguridad y confidencialidad toda aquella información facilitada libremente por el usuario a través de los formularios existentes en la página web.</p>
        <p>La base del procesamiento de los datos personales queda previsto en el artículo 6 (1) (b) del Reglamento General de la Protección de Datos.</p>
        <p>El usuario es el único responsable de la veracidad de los datos facilitados a la Web. El usuario se compromete a no introducir datos falsos. En caso de que un usuario introduzca datos falsos o relativos a otra persona sin su consentimiento la Web no se hace responsable del tratamiento de los mismos.</p>
        <p>Al aceptar las condiciones legales de la Web, usted queda informado y autoriza que los datos completados en los formularios de la Web puedan ser cedidos a empresas que ofrecen micropréstamos y por tanto tratar su datos con la finalidad de poder tramitar el préstamo solicitado por usted.</p>
        <p>Usted puede retirar en todo momento el consentimiento prestado para el envío de comunicaciones comerciales y/o publicidad.</p>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import Isvg from '../assetComponents/Isvg'

export default {
  name: 'Terms',
  // components: {
  //   Isvg
  // },
  data () {
    return {
      filter: '',
      locker: false
    }
  },
  computed: {
    ...mapState({
      translation: state => state.translation,
      countryCodeName: state => state.countryCodeName,
      lang: state => state.lang
    })
  },
  mounted () {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event('render-event'))
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>

</style>
